import React, { useContext } from "react";
import team1 from "../../../../Images/team-logo-1.png";
import team2 from "../../../../Images/team-logo-2.png";
import { formateDate } from "../../../../customFunctions/formateDate";
import { DataContext } from "../../../../Context/DataProvider";

const ContentMiddle = ({ item }) => {
  const { base_url } = useContext(DataContext);
  return (
    <>
      <div className="content-middle hidden md:block">
        <div className="flex justify-between items-center px-5">
          <div className="flex justify-between items-center">
            <div className="">
              <h4 className="title-top text-sm">{item?.league}</h4>
              <p className="date text-xs">
                {formateDate(item?.date)} - {item?.time}
              </p>
            </div>
          </div>

          <div className="flex justify-between items-center ">
            <span className="b-rate b-rate-1 flex justify-between items-center">
              {isNaN(Math.round(item?.home_win_prob * 100)) ? (
                <div className="ml-2">--</div>
              ) : (
                Math.round(item?.home_win_prob * 100) + "%"
              )}
            </span>
            <span className="b-rate flex justify-between items-center">
              {isNaN(Math.round(item?.tie_prob * 100)) ? (
                <div className="ml-2">--</div>
              ) : (
                Math.round(item?.tie_prob * 100) + "%"
              )}
            </span>
            <span className="b-rate flex justify-between items-center">
              {isNaN(Math.round(item?.away_win_prob * 100)) ? (
                <div className="ml-2">--</div>
              ) : (
                Math.round(item?.away_win_prob * 100) + "%"
              )}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center px-5 mt-6">
          <div className="flex justify-between items-center">
            <img
              className="mr-2 w-9"
              src={`${base_url}/api/v1/team/image/${item?.home_team}`}
              alt=""
            />
            <p className="team-title">{item?.home_team_short}</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="score bg-[#fff]">
              {!item.home_goals ? "0" : item?.home_goals} {" - "}
              {!item.away_goals ? "0" : item?.away_goals}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <img
              className="mr-2 w-9"
              src={`${base_url}/api/v1/team/image/${item?.away_team}`}
              alt=""
            />
            <p className="team-title">{item?.away_team_short}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-3 mt-5">
          <div className="box px-4 py-2 flex justify-between items-center">
            <p className="text1 text-sm">Shot-Based xG</p>
            <p className="text2 text-sm">
              {isNaN(item?.home_xg) ? "0" : Number(item?.home_xg).toFixed(1)}
            </p>
          </div>
          <div className="box px-4 py-2 flex justify-between items-center">
            <p className="text1 text-sm">Shot-Based xG</p>
            <p className="text2 text-sm">
              {isNaN(item?.away_xg) ? "0" : Number(item?.away_xg).toFixed(1)}
            </p>
          </div>
          <div className="box px-4 py-2 flex justify-between items-center">
            <p className="text1 text-sm">Non-Shot xG</p>
            <p className="text2 text-sm">
              {isNaN(item?.home_xG_BP)
                ? "0"
                : Number(item?.home_xG_BP).toFixed(1)}
            </p>
          </div>
          <div className="box px-4 py-2 flex justify-between items-center">
            <p className="text1 text-sm">Non-Shot xG</p>
            <p className="text2 text-sm">
              {isNaN(item?.away_xG_BP)
                ? "0"
                : Number(item?.away_xG_BP).toFixed(1)}
            </p>
          </div>
        </div>
      </div>

      {/* for mobile */}
      <div className="content-middle completed-matches-table-mobile block md:hidden">
      <div className="flex justify-between items-center px-5">
        <div className="flex justify-between items-center">
          <div className="">
            <h4 className="title-top text-xs">{item?.league}</h4>
            <p className="date text-xs">{formateDate(item?.date)} - {item?.time}</p>
          </div>
        </div>

        <div className="flex justify-between items-center ">
          <span className="b-rate b-rate-1 flex justify-between items-center">
            {isNaN(Math.round(item?.home_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.home_win_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.tie_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.tie_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.away_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.away_win_prob * 100) + "%"
            )}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center px-5 mt-6">
        <div className="flex justify-between items-center">
          <img
            className="mr-1 w-5"
            src={`${base_url}/api/v1/team/image/${item?.home_team}`}
            alt=""
          />
          <p className="team-title">{item?.home_team_short}</p>
        </div>
        <div className="flex justify-between items-center">
          <div className="score bg-[#fff]">
            {!item.home_goals ? "0" : item?.home_goals} {" - "}
            {!item.away_goals ? "0" : item?.away_goals}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <img
            className="mr-1 w-5"
            src={`${base_url}/api/v1/team/image/${item?.away_team}`}
            alt=""
          />
          <p className="team-title">{item?.away_team_short}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 px-2 mt-5">
        <div className="box px-2 py-2 flex justify-between items-center">
          <p className="text1 text-xs">Shot-Based xG</p>
          <p className="text2 text-xs">
            {isNaN(item?.home_xg)
              ? "0"
              : Number(item?.home_xg).toFixed(1)}
          </p>
        </div>
        <div className="box px-2 py-2 flex justify-between items-center">
          <p className="text1 text-xs">Shot-Based xG</p>
          <p className="text2 text-xs">
          {isNaN(item?.away_xg)
              ? "0"
              : Number(item?.away_xg).toFixed(1)}
          </p>
        </div>
        <div className="box px-2 py-2 flex justify-between items-center">
          <p className="text1 text-xs">Non-Shot xG</p>
          <p className="text2 text-xs">{isNaN(item?.home_xG_BP)
              ? "0"
              : Number(item?.home_xG_BP).toFixed(1)}</p>
        </div>
        <div className="box p-2 flex justify-between items-center">
          <p className="text1 text-xs">Non-Shot xG</p>
          <p className="text2 text-xs">
          {isNaN(item?.away_xG_BP)
              ? "0"
              : Number(item?.away_xG_BP).toFixed(1)}
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContentMiddle;
