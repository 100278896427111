import React, { useEffect, useState } from "react";

const allLeagues = [
  "ARG1",
  "AUS1",
  "AUT1",
  "BEL1",
  "BOL1",
  "BRA1",
  "BRA2",
  "CAN1",
  "CHI1",
  "COL1",
  "COR1",
  "CRO1",
  "CZE1",
  "DEN1",
  "ECU1",
  "ENG1",
  "ENG2",
  "ENG3",
  "ENG4",
  "FRA1",
  "FRA2",
  "GER1",
  "GER2",
  "GER3",
  "GRE1",
  "IND1",
  "ITA1",
  "ITA2",
  "JAP1",
  "KOR1",
  "MEX1",
  "MEX2",
  "NED1",
  "NOR1",
  "PAR1",
  "PER1",
  "POL1",
  "POR1",
  "RUS1",
  "SCO1",
  "SER1",
  "SLN1",
  "SPA1",
  "SPA2",
  "SWE1",
  "SWI1",
  "TUR1",
  "UCL",
  "UEL",
  "UKR1",
  "URU1",
  "USA1",
  "USA2",
  "VEN1",
];

const Leagues = ({
  leagueSelected,
  setLeagueSelected,
  setSeasonSelected,
  setTeamSelected,
  setPage,
  selectedLeagues,
  setSelectedLeagues,
  setTeams,
  setSelectedTeams,
  setSelectedLeague,
  setLeagueApiCall
}) => {
  const [leagueToggle, setLeagueToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".league-btn")) {
        setLeagueToggle(false);
      }
    });
  }, []);

  const handleClick = () => {
    setLeagueToggle(!leagueToggle);
  };

  const handleLeagueSelected = (league) => {
    setLoading(false);
    const leagues = [...selectedLeagues, league];
    setSelectedLeagues([...new Set(leagues)]);
    setLeagueSelected(league);
    setSeasonSelected("select season");
    setTeamSelected("select team");
    setIsLoad(false);
    setPage(1);
  };

  useEffect(()=>{
    if(selectedLeagues.length===0){
      setSelectedTeams([]);
    }
  },[selectedLeagues])

  const handleRemoveLeague = (league) => {
    const filteredLeagues = selectedLeagues.filter((item) => item !== league);
    setSelectedLeagues(filteredLeagues);
    setLeagueSelected("select league");
    setSelectedLeague(filteredLeagues.join(","));
    setLeagueApiCall(true);
  };

  useEffect(()=>{
    if(selectedLeagues.length===0){
      setTeams([]);
    }
  },[selectedLeagues])

  return (
    <div className="mt-3 ">
      <h6 className="text-sm" >League</h6>
      <div className=" flex flex-wrap gap-1 ">
        {selectedLeagues.length > 0 &&
          selectedLeagues.map((league, index) => (
            <div
              key={index}
              style={{ fontSize: "11px" }}
              className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
            >
              <div>{league}</div>
              <div
                onClick={() => handleRemoveLeague(league)}
                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
              >
                x
              </div>
            </div>
          ))}
      </div>
      <div className="relative mt-2">
        <button
          onClick={handleClick}
          className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none league-btn flex  bg-base-100"
        >
          {loading && isLoad ? (
            <svg
              className="animate-spin mx-auto h-5 w-5 text-slate-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            leagueSelected
          )}
        </button>

        {leagueToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {allLeagues.map((item, index) => (
              <p
                key={index}
                onClick={() => handleLeagueSelected(item)}
                className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Leagues;
