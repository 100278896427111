import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataProvider";

const Teams = ({
  teamSelected,
  setTeamSelected,
  leagueSelected,
  selectedLeague,
  setPage,
  selectedTeams,
  setSelectedTeams,
  teams,
  setTeams,
  leagueApiCall
}) => {
  const {base_url, token} = useContext(DataContext);
  const [teamToggle, setTeamToggle] = useState(false);
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (leagueSelected !== "select league" || leagueApiCall) {
      fetch(`${base_url}/api/v1/coach/team?league=${selectedLeague}`,{
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.length > 0) {
            setTeams(data);
            setLoading(false);
          }
        })
        .catch(error=>{
          if(error){
            setLoading(false);
            console.log('league error');
          }
        })
    }
  }, [selectedLeague, leagueSelected, leagueApiCall]);

  const handleSelectTeam = (team) => {
    setPage(1);
    setTeamSelected(team);
    const teams = [...selectedTeams, team];
    setSelectedTeams([...new Set(teams)]);
    setTeamSelected("select team");
  };

  const handleRemoveTeam = (team) => {
    const filteredLeagues = selectedTeams.filter((item) => item !== team);
    setSelectedTeams(filteredLeagues);
    setTeamSelected("select team");
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".team-btn")) {
        setTeamToggle(false);
      }
    });
  }, []);

  return (
    <div className="mt-3 ">
      <h6 className="text-sm" >Team</h6>
      <div className=" flex flex-wrap gap-1 ">
        {selectedTeams.length > 0 &&
          selectedTeams.map((team, index) => (
            <div
              key={index}
              style={{ fontSize: "11px" }}
              className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
            >
              <div>{team}</div>
              <div
                onClick={() => handleRemoveTeam(team)}
                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
              >
                x
              </div>
            </div>
          ))}
      </div>
      <div className="relative mt-2">
        <button
          onClick={() => setTeamToggle(!teamToggle)}
          className="w-full xl:w-full input-border capitalize text-sm p-2 rounded-lg outline-none team-btn flex justify-between items-center bg-base-100"
        >
          {loading && leagueSelected !== "" ? (
            <svg
              class="animate-spin mx-auto h-5 w-5 text-slate-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div>{teamSelected.length>0 ? teamSelected : 'Select league'}</div>
          )}
        </button>

        {teamToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll season-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {teams?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleSelectTeam(item?.team)}
                className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
              >
                {item?.team}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Teams;
