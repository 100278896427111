import React from 'react';
import prev from '../../Images/polygon.png';
import next from '../../Images/polygon-2.png';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const MAX_VISIBLE_PAGES = 7; // Number of pages visible in the middle

  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= MAX_VISIBLE_PAGES) {
      // If total pages are less than or equal to the maximum visible pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages are more than the maximum visible pages
      if (currentPage <= 3) {
        // Displaying pages at the start
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages - 1);
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Displaying pages at the end
        pageNumbers.push(1);
        pageNumbers.push(2);
        pageNumbers.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Displaying pages in the middle
        pageNumbers.push(1);
        pageNumbers.push(2);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages - 1);
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination flex gap-6 mx-auto ">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className='polygon-box'
      >
        <img src={prev} alt="icon" />
      </button>
      {getPageNumbers().map((pageNumber, index) => (
        <React.Fragment key={index}>
          {pageNumber === '...' ? (
            <span className="dot">...</span>
          ) : (
            <button
              onClick={() => onPageChange(pageNumber)}
              className={`w-8 mx-1 h-8 text-neutral-content  border rounded-md ${
                pageNumber === currentPage && "active-page"
              } `}
            >
              {pageNumber}
            </button>
          )}
        </React.Fragment>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className='polygon-box'
      >
        <img src={next} alt="icon" />
      </button>
    </div>
  );
};

export default Pagination;
