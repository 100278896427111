import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataProvider";
import contentLoader from "../../../Images/loader.gif";

const WorldRankingModal = () => {
    const { base_url, token } = useContext(DataContext);
    const [allData, setAllData] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);
    useEffect(() => {
        fetch(`${base_url}/api/v1/rankings`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.length) {
                    setAllData(data);
                    setContentLoading(false);
                }
            })
            .catch((error) => {
                if (error) {
                    alert("server error happened");
                }
            });
    }, []);

    if (contentLoading) {
        return (
            <div id="preloader" className="flex items-center justify-center">
                <div className="flex loader-box justify-center items-center ">
                    <span className="text-center">
                        <img
                            className="mx-auto d-block"
                            alt="loader"
                            width="150"
                            src={contentLoader}
                        />
                    </span>
                </div>
            </div>
        );
    } else {
        return (
            <div className="modal-box players-modal p-0">
                <div className="players-modal-header flex justify-between items-center border-b p-5 pb-4">
                    <h3 className="font-bold text-lg">World Club Ranking</h3>
                    <label
                        htmlFor="worldrank"
                        className="h-[25px] w-[25px] mt-[-4px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
                    >
                        <div className="ml-px ">x</div>
                    </label>
                </div>
                <p className="font-medium p-5 pb-0">
                    Our proprietary World Club Ranking uses an ELO algorithm
                    based on bookmaker odds to generate a rating for any given
                    team in world football at any given time. The factors are
                    evaluated in such a way that the ratings are designed to
                    accurately forecast future matches.
                </p>
                <div className="px-2 py-5 md:px-5">
                    <table className="players-modal-table w-full">
                        <tbody>
                            {allData
                                ?.sort((a, b) => a.rank - b.rank)
                                ?.slice(0, 10)
                                ?.map((team, index) => (
                                    <tr
                                        className={`px-3 ${
                                            team.rank % 2 !== 0
                                                ? "bg-[#f2f3f6]"
                                                : ""
                                        }  `}
                                        key={index}
                                    >
                                        <td
                                            align="center"
                                            className="bg-transparent rounded-l-lg team-position"
                                        >
                                            {team?.rank}.
                                        </td>
                                        <td className="bg-transparent team-name">
                                            <div className="whitespace-normal font-semibold">
                                                {team?.team}
                                            </div>
                                        </td>
                                        <td className="bg-transparent league-name">
                                            <div className="whitespace-normal">
                                                {team?.league}
                                            </div>
                                        </td>
                                        <td className="bg-transparent team-rating rounded-r-lg text-center font-semibold">
                                            {parseFloat(team?.spi).toFixed(1)}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default WorldRankingModal;
