import React, { useContext, useEffect, useState } from "react";
import "./Metric.css";
import Positions from "./Dropdown/Positions";
import Leagues from "./Dropdown/Leagues";
import Seasons from "./Dropdown/Seasons";
import Teams from "./Dropdown/Teams";
import HistoryTable from "./HistoryTable";
import loader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";
import FutureLevel from "./Dropdown/FutureLevel";

// const traits = [
//     "High Performer",
//     "Low Performer",
//     "First Season",
//     "Underused",
//     "Young Talent",
// ];

const traits = [
    "Elite",
    "Premier",
    "Strong",
    "Competitive",
    "Lower-Tier",
];

const Metric2 = () => {
    const { base_url, token } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [playerHistory, setPlayerHistory] = useState([]);
    const [positionSelected, setPositionSelected] = useState("");
    const [teamSelected, setTeamSelected] = useState("select team");
    const [leagueSelected, setLeagueSelected] = useState("select league");
    const [seasonSelected, setSeasonSelected] = useState("select season");
    const [selectedTrait, setSelectedTrait] = useState("");
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedSeason, setSelectedSeason] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");
    const [age1, setAge1] = useState("");
    const [age2, setAge2] = useState("");
    const [min1, setMin1] = useState("");
    const [min2, setMin2] = useState("");
    const [aerial1, setAerial1] = useState("");
    const [aerial2, setAerial2] = useState("");
    const [carry1, setCarry1] = useState("");
    const [carry2, setCarry2] = useState("");
    const [tackle1, setTackle1] = useState("");
    const [tackle2, setTackle2] = useState("");
    const [open_foot1, setOpen_foot1] = useState("");
    const [open_foot2, setOpen_foot2] = useState("");
    const [totalPage, setTotalPage] = useState(null);
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [notFound, setNotFound] = useState(false);
    const [activeTraits, setActiveTraits] = useState([]);
    const [callApi, setCallApi] = useState(false);
    const [activePositions, setActivePositions] = useState([]);
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedSeasons, setSelectedSeasons] = useState([]);
    const [lastPage, setLastPage] = useState(null);
    const [bpRangeValue, setBpRangeValue] = useState(null);
    const [scRangeValue, setScRangeValue] = useState(null);
    const [ballRetRangeValue, setBallRetRangeValue] = useState(null);
    const [defFreRangeValue, setDefFreRangeValue] = useState(null);
    const [defImpRangeValue, setDefImpRangeValue] = useState(null);
    const [bpRangeSlide, setBpRangeSlide] = useState(false);
    const [scRangeSlide, setScRangeSlide] = useState(false);
    const [ballRetRangeSlide, setBallRetRangeSlide] = useState(false);
    const [defFreRangeSlide, setDefFreRangeSlide] = useState(false);
    const [defImpRangeSlide, setDefImpRangeSlide] = useState(false);
    const [teams, setTeams] = useState([]);
    const [leagueApiCall, setLeagueApiCall] = useState(false);

    const handleBpRangeChange = (event) => {
        setBpRangeValue(parseFloat(event.target.value));
        setBpRangeSlide(true);
    };
    const handleScRangeChange = (event) => {
        setScRangeValue(parseFloat(event.target.value));
        setScRangeSlide(true);
    };
    const handleBallRetRangeChange = (event) => {
        setBallRetRangeValue(parseFloat(event.target.value));
        setBallRetRangeSlide(true);
    };
    const handleDefFreRangeChange = (event) => {
        setDefFreRangeValue(parseFloat(event.target.value));
        setDefFreRangeSlide(true);
    };
    const handleDefImpRangeChange = (event) => {
        setDefImpRangeValue(parseFloat(event.target.value));
        setDefImpRangeSlide(true);
    };

    function getBpDotColor(step) {
        if (bpRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === bpRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getScDotColor(step) {
        if (scRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === scRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getBallRetDotColor(step) {
        if (ballRetRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === ballRetRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getDefFreDotColor(step) {
        if (defFreRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === defFreRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getDefImpDotColor(step) {
        if (defImpRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === defImpRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }

    useEffect(() => {
        if (activePositions.length > 0) {
            setPositionSelected(activePositions.join(","));
        }
    }, [activePositions]);
    useEffect(() => {
        if (
            leagueSelected === "select league" &&
            selectedLeagues.length === 0
        ) {
            setSelectedLeague("");
        } else {
            setSelectedLeague(selectedLeagues.join(","));
        }
    }, [leagueSelected, selectedLeagues]);

    useEffect(() => {
        if (teamSelected === "select team" && selectedTeams.length === 0) {
            setSelectedTeam("");
        } else {
            setSelectedTeam(selectedTeams.join(","));
        }
    }, [teamSelected, selectedTeams]);

    useEffect(() => {
        if (
            seasonSelected === "select season" &&
            selectedSeasons.length === 0
        ) {
            setSelectedSeason("");
        } else {
            setSelectedSeason(selectedSeasons.join(","));
        }
    }, [seasonSelected, selectedSeasons]);

    useEffect(() => {
        if (positionSelected !== "" && callApi) {
            setLoading(true);
            fetch(
                `${base_url}/api/v1/player/result?position=${positionSelected}&league=${selectedLeague}&season=${selectedSeason}&team_short_name=${selectedTeam}&trait=${selectedTrait}&age1=${age1}&age2=${age2}&min1=${min1}&min2=${min2}&aerial1=${aerial1}&aerial2=${aerial2}&carry1=${carry1}&carry2=${carry2}&tackle1=${tackle1}&tackle2=${tackle2}&open_foot1=${open_foot1}&open_foot2=${open_foot2}&attack_bp=${bpRangeValue}&attack_sc=${scRangeValue}&ball_ret=${ballRetRangeValue}&def_qual=${defFreRangeValue}&def_quant=${defImpRangeValue}`,
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data) {
                        setPlayerHistory((prev) => {
                            return prev.concat(data);
                        });
                        setLinks(data?.links);
                        setCurrentPage(data?.currentPage);
                        setTotalPage(data?.total);
                        setLastPage(data?.last_page);
                        setLoading(false);
                        setCallApi(false);
                    }

                    if (data?.length === 0) {
                        setLoading(false);
                        setNotFound(true);
                    }
                })
                .catch((error) => {
                    console.error('There was a problem with the fetch operation:', error);
                    setLoading(false);
                    alert('Server is busy or something wrong with server.')
                });
        }
    }, [
        page,
        positionSelected,
        selectedLeague,
        selectedSeason,
        selectedTeam,
        callApi,
        selectedTrait,
        age1,
        age2,
        min1,
        min2,
        bpRangeValue,
        scRangeValue,
        ballRetRangeValue,
        defFreRangeValue,
        defImpRangeValue,
    ]);

    const handleTraits = (item) => {
        if (activeTraits.includes(item)) {
            const items = activeTraits.filter((i) => i !== item);
            setActiveTraits(items);
        }
        if (!activeTraits.includes(item)) {
            setActiveTraits([...activeTraits, item]);
        }
    };

    useEffect(() => {
        const arr = [];
        activeTraits.forEach((value) => {
            if (value === "High Performer") {
                value = "hot";
            }
            if (value === "Low Performer") {
                value = "cold";
            }
            if (value === "First Season") {
                value = "breakout";
            }
            if (value === "Underused") {
                value = "underused";
            }
            if (value === "Young Talent") {
                value = "prospect";
            }
            arr.push(value);
        });
        setSelectedTrait(arr.join(",").toLocaleLowerCase());
    }, [activeTraits]);

    const handleFilterApply = () => {
        setPlayerHistory([]);
        setCallApi(true);
    };

    const handleResetFilter = (e) => {
        setActivePositions([]);
        setPlayerHistory([]);
        setActiveTraits([]);
        setLeagueSelected("select league");
        setSelectedLeagues([]);
        setSelectedSeasons([]);
        setSelectedTeams([]);
        setNotFound(false);
        setAge1("");
        setAge2("");
        setMin1("");
        setMin2("");
        setBpRangeValue(null);
        setScRangeValue(null);
        setBallRetRangeValue(null);
        setDefFreRangeValue(null);
        setDefImpRangeValue(null);
    };

    return (
        <div className="pt-5 md:pt-10 min-h-[90vh] text-neutral">
            <div className="px-[18px] md:hidden mb-4">
                <label
                    htmlFor="apply-filter"
                    className="capitalize rounded-[8px] py-[10px] px-[24px] block text-[#22272F] w-full text-center font-semibold border-[1.5px] border-[#7C86A129] "
                >
                    Open Filters
                </label>
            </div>

            {/* mobile sidebar */}
            <div className="">
                <input
                    type="checkbox"
                    id="apply-filter"
                    className="modal-toggle"
                />
                <div className="modal">
                    <div className="modal-box w-full rounded-none max-h-[100vh] apply-filter p-0">
                        <div className="apply-filter-header flex justify-between p-5">
                            <h3 className="font-bold text-base md:text-lg">
                                Apply Filter
                            </h3>
                            <label htmlFor="apply-filter">
                                <div className="font-semibold rounded-full w-[25px] shadow-lg flex justify-center items-center h-[25px] bg-[#f8f8f8] 2xl:text-base">
                                    x
                                </div>
                            </label>
                        </div>
                        <div>
                            <div className=" bg-base-100 rounded-lg">
                                {/*  Header */}
                                <div className="px-6 flex justify-between min-[1920px]:w-[250px] items-center py-4 border-b">
                                    <h6 className="font-semibold text-sm xl:text-base">
                                        Filters
                                    </h6>
                                    <div className="flex gap-2 ">
                                        <button
                                            disabled={
                                                activePositions.length === 0
                                            }
                                            onClick={() => handleResetFilter()}
                                            className={`px-2 py-1 flex ${
                                                activePositions.length === 0
                                                    ? "bg-transparent border"
                                                    : "border font-[600]"
                                            } justify-center items-center  text-black rounded  text-xs`}
                                        >
                                            {activePositions.length === 0 ? (
                                                <label className="" htmlFor="">
                                                    <span className="text-xs">
                                                        Reset
                                                    </span>
                                                </label>
                                            ) : (
                                                <label
                                                    className=""
                                                    htmlFor="apply-filter"
                                                >
                                                    <span className="text-xs">
                                                        Reset
                                                    </span>
                                                </label>
                                            )}
                                        </button>
                                        <button
                                            disabled={
                                                activePositions.length === 0
                                            }
                                            onClick={() => handleFilterApply()}
                                            className={`px-2 py-1 flex ${
                                                activePositions.length === 0
                                                    ? "bg-transparent text-black border"
                                                    : "bg-primary text-white"
                                            } justify-center items-center rounded   text-xs`}
                                        >
                                            {activePositions.length === 0 ? (
                                                "Apply"
                                            ) : (
                                                <label htmlFor="apply-filter">
                                                    Apply
                                                </label>
                                            )}
                                        </button>
                                    </div>
                                </div>

                                <div className="p-2.5 2xl:p-4 border-b">
                                    {/* PLAYER DETAILS LEFT SIDEBAR TOP */}
                                    <div className="mt-4">
                                        <h6 className="text-neutral-content font-semibold text-xs">
                                            PLAYER DETAILS
                                        </h6>

                                        <div className="mt-3">
                                            <h6 className="text-sm">
                                                Position
                                            </h6>
                                            <Positions
                                                positionSelected={
                                                    positionSelected
                                                }
                                                setPositionSelected={
                                                    setPositionSelected
                                                }
                                                setSeasonSelected={
                                                    setSeasonSelected
                                                }
                                                setLeagueSelected={
                                                    setLeagueSelected
                                                }
                                                setTeamSelected={
                                                    setTeamSelected
                                                }
                                                setPage={setPage}
                                                activePositions={
                                                    activePositions
                                                }
                                                setActivePositions={
                                                    setActivePositions
                                                }
                                                // setPlayerHistory={setPlayerHistory}
                                            />
                                        </div>

                                        <Leagues
                                            leagueSelected={leagueSelected}
                                            setLeagueSelected={
                                                setLeagueSelected
                                            }
                                            setSeasonSelected={
                                                setSeasonSelected
                                            }
                                            setTeamSelected={setTeamSelected}
                                            setPage={setPage}
                                            selectedLeagues={selectedLeagues}
                                            setSelectedLeagues={
                                                setSelectedLeagues
                                            }
                                            setTeams={setTeams}
                                            setSelectedTeams={setSelectedTeams}
                                            selectedLeague={selectedLeague}
                                            setSelectedLeague={
                                                setSelectedLeague
                                            }
                                            setLeagueApiCall={setLeagueApiCall}
                                        />
                                        <Seasons
                                            seasonSelected={seasonSelected}
                                            setSeasonSelected={
                                                setSeasonSelected
                                            }
                                            leagueSelected={leagueSelected}
                                            selectedSeasons={selectedSeasons}
                                            setSelectedSeasons={
                                                setSelectedSeasons
                                            }
                                            setPage={setPage}
                                        />

                                        <Teams
                                            teamSelected={teamSelected}
                                            setTeamSelected={setTeamSelected}
                                            leagueSelected={leagueSelected}
                                            selectedLeague={selectedLeague}
                                            setPage={setPage}
                                            selectedTeams={selectedTeams}
                                            setSelectedTeams={setSelectedTeams}
                                            teams={teams}
                                            setTeams={setTeams}
                                            leagueApiCall={leagueApiCall}
                                        />

                                        <div className="mt-3">
                                            <h6 className="text-sm  mb-1">
                                                Age
                                            </h6>
                                            <div className="flex gap-4 justify-between mt-1.5">
                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setAge1(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={age1}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>

                                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                                </div>

                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setAge2(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={age2}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <h6 className="text-sm mb-1">
                                                Minutes
                                            </h6>

                                            <div className="flex gap-4 justify-between mt-1.5">
                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setMin1(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={min1}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>

                                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                                </div>

                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setMin2(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={min2}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* PERFORMANCE LEFT SIDEBAR BOTTOM */}
                                <div className="p-4">
                                    <h6 className="text-neutral-content font-semibold text-xs">
                                        PERFORMANCE
                                    </h6>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Ball Progression
                                        </h6>
                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    bpRangeValue === null
                                                        ? ""
                                                        : bpRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={handleBpRangeChange}
                                                onClick={(e) => {
                                                    if (bpRangeValue === null) {
                                                        setBpRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getBpDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {bpRangeValue === null &&
                                            !bpRangeSlide
                                                ? "Not selected"
                                                : bpRangeValue === 0 &&
                                                  bpRangeSlide
                                                ? "Not Important"
                                                : bpRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Shot Creation
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    scRangeValue === null
                                                        ? ""
                                                        : scRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={handleScRangeChange}
                                                onClick={(e) => {
                                                    if (scRangeValue === null) {
                                                        setScRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getScDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {scRangeValue === null &&
                                            !scRangeSlide
                                                ? "Not selected"
                                                : scRangeValue === 0 &&
                                                  scRangeSlide
                                                ? "Not Important"
                                                : scRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Possession Retention
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    ballRetRangeValue === null
                                                        ? ""
                                                        : ballRetRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleBallRetRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        ballRetRangeValue ===
                                                        null
                                                    ) {
                                                        setBallRetRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getBallRetDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {ballRetRangeValue === null &&
                                            !ballRetRangeSlide
                                                ? "Not selected"
                                                : ballRetRangeValue === 0 &&
                                                  ballRetRangeSlide
                                                ? "Not Important"
                                                : ballRetRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Defending Frequency
                                        </h6>
                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    defFreRangeValue === null
                                                        ? ""
                                                        : defFreRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleDefFreRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        defFreRangeValue ===
                                                        null
                                                    ) {
                                                        setDefFreRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getDefFreDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {defFreRangeValue === null &&
                                            !defFreRangeSlide
                                                ? "Not selected"
                                                : defFreRangeValue === 0 &&
                                                  defFreRangeSlide
                                                ? "Not Important"
                                                : defFreRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>
                                    <div className="mt-4 mb-5">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Defending Impact
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    defImpRangeValue === null
                                                        ? ""
                                                        : defImpRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleDefImpRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        defImpRangeValue ===
                                                        null
                                                    ) {
                                                        setDefImpRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getDefImpDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {defImpRangeValue === null &&
                                            !defImpRangeSlide
                                                ? "Not selected"
                                                : defImpRangeValue === 0 &&
                                                  defImpRangeSlide
                                                ? "Not Important"
                                                : defImpRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="metric flex">
                {/* Sidebar */}
                <div className="metric_sidebar bg-base-100 rounded-lg">
                    {/*  Header */}
                    <div className="px-6 flex justify-between min-[1920px]:w-[250px] items-center py-4 border-b">
                        <h6 className="font-semibold 2xl:text-base">Filters</h6>
                        <div className="flex gap-2 ">
                            <button
                                disabled={activePositions.length === 0}
                                onClick={() => handleResetFilter()}
                                className={`px-2 py-1 flex ${
                                    activePositions.length === 0
                                        ? "bg-transparent border"
                                        : "border font-[600]"
                                } justify-center items-center  text-black rounded  text-xs`}
                            >
                                Reset
                            </button>
                            <button
                                disabled={activePositions.length === 0}
                                onClick={() => handleFilterApply()}
                                className={`px-2 py-1 flex ${
                                    activePositions.length === 0
                                        ? "bg-transparent text-black border"
                                        : "bg-primary text-white"
                                } justify-center items-center rounded   text-xs`}
                            >
                                Apply
                            </button>
                        </div>
                    </div>

                    <div className="p-2.5 2xl:p-4 border-b">
                        {/* PLAYER DETAILS LEFT SIDEBAR TOP */}
                        <div className="mt-4">
                            <h6 className="text-neutral-content font-semibold text-xs">
                                PLAYER DETAILS
                            </h6>

                            <div className="mt-3">
                                <h6 className="text-sm ">Position</h6>
                                <Positions
                                    positionSelected={positionSelected}
                                    setPositionSelected={setPositionSelected}
                                    setSeasonSelected={setSeasonSelected}
                                    setLeagueSelected={setLeagueSelected}
                                    setTeamSelected={setTeamSelected}
                                    setPage={setPage}
                                    activePositions={activePositions}
                                    setActivePositions={setActivePositions}
                                    // setPlayerHistory={setPlayerHistory}
                                />
                            </div>

                            <Leagues
                                leagueSelected={leagueSelected}
                                setLeagueSelected={setLeagueSelected}
                                setSeasonSelected={setSeasonSelected}
                                setTeamSelected={setTeamSelected}
                                setPage={setPage}
                                selectedLeagues={selectedLeagues}
                                setSelectedLeagues={setSelectedLeagues}
                                setTeams={setTeams}
                                setSelectedTeams={setSelectedTeams}
                                selectedLeague={selectedLeague}
                                setSelectedLeague={setSelectedLeague}
                                setLeagueApiCall={setLeagueApiCall}
                            />
                            <Seasons
                                seasonSelected={seasonSelected}
                                setSeasonSelected={setSeasonSelected}
                                leagueSelected={leagueSelected}
                                selectedSeasons={selectedSeasons}
                                setSelectedSeasons={setSelectedSeasons}
                                setPage={setPage}
                            />

                            <Teams
                                teamSelected={teamSelected}
                                setTeamSelected={setTeamSelected}
                                leagueSelected={leagueSelected}
                                selectedLeague={selectedLeague}
                                setPage={setPage}
                                selectedTeams={selectedTeams}
                                setSelectedTeams={setSelectedTeams}
                                teams={teams}
                                setTeams={setTeams}
                                leagueApiCall={leagueApiCall}
                            />

                            <div className="mt-3">
                                <h6 className="text-sm mb-1">Age</h6>
                                <div className="flex gap-4 justify-between mt-1.5">
                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setAge1(e.target.value)
                                            }
                                            value={age1}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>

                                    <div className="flex justify-center items-center text-neutral-content font-medium">
                                        <div className="w-2 h-[2px] bg-neutral-content"></div>
                                    </div>

                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setAge2(e.target.value)
                                            }
                                            value={age2}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <h6 className="text-sm mb-1">Minutes</h6>

                                <div className="flex gap-4 justify-between mt-1.5">
                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setMin1(e.target.value)
                                            }
                                            value={min1}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>

                                    <div className="flex justify-center items-center text-neutral-content font-medium">
                                        <div className="w-2 h-[2px] bg-neutral-content"></div>
                                    </div>

                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setMin2(e.target.value)
                                            }
                                            value={min2}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* new section */}
                    <div className="p-4">
                        <h6 className="text-neutral-content font-semibold text-xs">
                            Skill
                        </h6>
                        <div className="mt-3">
                            <h6 className="text-sm mb-1">Pace</h6>

                            <div className="flex gap-4 justify-between mt-1.5">
                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setAerial1(e.target.value)
                                        }
                                        value={aerial1}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>

                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                </div>

                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setAerial2(e.target.value)
                                        }
                                        value={aerial2}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h6 className="text-sm mb-1">High Intensity</h6>

                            <div className="flex gap-4 justify-between mt-1.5">
                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setCarry1(e.target.value)
                                        }
                                        value={carry1}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>

                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                </div>

                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setCarry2(e.target.value)
                                        }
                                        value={carry2}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h6 className="text-sm mb-1">Runs Impact</h6>

                            <div className="flex gap-4 justify-between mt-1.5">
                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setTackle1(e.target.value)
                                        }
                                        value={tackle1}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>

                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                </div>

                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setTackle2(e.target.value)
                                        }
                                        value={tackle2}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h6 className="text-sm mb-1">Passing</h6>

                            <div className="flex gap-4 justify-between mt-1.5">
                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setOpen_foot1(e.target.value)
                                        }
                                        value={open_foot1}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>

                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                </div>

                                <div className="w-1/2">
                                    <input
                                        onChange={(e) =>
                                            setOpen_foot2(e.target.value)
                                        }
                                        value={open_foot2}
                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PERFORMANCE LEFT SIDEBAR BOTTOM */}
                    <div className="p-4">
                        <h6 className="text-neutral-content font-semibold text-xs">
                            PERFORMANCE
                        </h6>

                        <div className="mt-4">
                            <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                Current Level
                            </h6>
                            <div className="relative h-2 mt-4 mb-4">
                                <input
                                    id="steps-range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    value={
                                        bpRangeValue === null
                                            ? ""
                                            : bpRangeValue
                                    }
                                    step="1"
                                    className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    onChange={handleBpRangeChange}
                                    onClick={(e) => {
                                        if (bpRangeValue === null) {
                                            setBpRangeValue(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                                <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                    {[0, 1, 2].map((step) => (
                                        <div
                                            key={step}
                                            className={`w-4 h-4 rounded-full ${getBpDotColor(
                                                step
                                            )}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                {bpRangeValue === null && !bpRangeSlide
                                    ? "Not selected"
                                    : bpRangeValue === 0 && bpRangeSlide
                                    ? "Not Important"
                                    : bpRangeValue === 1
                                    ? "Somewhat Important"
                                    : "Very Important"}
                            </p>
                        </div>

                        <div className="mt-4">
                            <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                Future Level
                            </h6>

                            <div className="relative h-2 mt-4 mb-4">
                                <input
                                    id="steps-range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    value={
                                        scRangeValue === null
                                            ? ""
                                            : scRangeValue
                                    }
                                    step="1"
                                    className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    onChange={handleScRangeChange}
                                    onClick={(e) => {
                                        if (scRangeValue === null) {
                                            setScRangeValue(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                                <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                    {[0, 1, 2].map((step) => (
                                        <div
                                            key={step}
                                            className={`w-4 h-4 rounded-full ${getScDotColor(
                                                step
                                            )}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                {scRangeValue === null && !scRangeSlide
                                    ? "Not selected"
                                    : scRangeValue === 0 && scRangeSlide
                                    ? "Not Important"
                                    : scRangeValue === 1
                                    ? "Somewhat Important"
                                    : "Very Important"}
                            </p>
                        </div>

                        <div className="mt-4">
                            <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                Attacking
                            </h6>

                            <div className="relative h-2 mt-4 mb-4">
                                <input
                                    id="steps-range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    value={
                                        ballRetRangeValue === null
                                            ? ""
                                            : ballRetRangeValue
                                    }
                                    step="1"
                                    className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    onChange={handleBallRetRangeChange}
                                    onClick={(e) => {
                                        if (ballRetRangeValue === null) {
                                            setBallRetRangeValue(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                                <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                    {[0, 1, 2].map((step) => (
                                        <div
                                            key={step}
                                            className={`w-4 h-4 rounded-full ${getBallRetDotColor(
                                                step
                                            )}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                {ballRetRangeValue === null &&
                                !ballRetRangeSlide
                                    ? "Not selected"
                                    : ballRetRangeValue === 0 &&
                                      ballRetRangeSlide
                                    ? "Not Important"
                                    : ballRetRangeValue === 1
                                    ? "Somewhat Important"
                                    : "Very Important"}
                            </p>
                        </div>

                        <div className="mt-4">
                            <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                Defending
                            </h6>
                            <div className="relative h-2 mt-4 mb-4">
                                <input
                                    id="steps-range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    value={
                                        defFreRangeValue === null
                                            ? ""
                                            : defFreRangeValue
                                    }
                                    step="1"
                                    className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    onChange={handleDefFreRangeChange}
                                    onClick={(e) => {
                                        if (defFreRangeValue === null) {
                                            setDefFreRangeValue(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                                <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                    {[0, 1, 2].map((step) => (
                                        <div
                                            key={step}
                                            className={`w-4 h-4 rounded-full ${getDefFreDotColor(
                                                step
                                            )}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                {defFreRangeValue === null && !defFreRangeSlide
                                    ? "Not selected"
                                    : defFreRangeValue === 0 && defFreRangeSlide
                                    ? "Not Important"
                                    : defFreRangeValue === 1
                                    ? "Somewhat Important"
                                    : "Very Important"}
                            </p>
                        </div>
                        {/* <div className="mt-4 mb-5">
                            <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                Defending Impact
                            </h6>

                            <div className="relative h-2 mt-4 mb-4">
                                <input
                                    id="steps-range"
                                    type="range"
                                    min="0"
                                    max="2"
                                    value={
                                        defImpRangeValue === null
                                            ? ""
                                            : defImpRangeValue
                                    }
                                    step="1"
                                    className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    onChange={handleDefImpRangeChange}
                                    onClick={(e) => {
                                        if (defImpRangeValue === null) {
                                            setDefImpRangeValue(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                                <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                    {[0, 1, 2].map((step) => (
                                        <div
                                            key={step}
                                            className={`w-4 h-4 rounded-full ${getDefImpDotColor(
                                                step
                                            )}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                            <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                {defImpRangeValue === null && !defImpRangeSlide
                                    ? "Not selected"
                                    : defImpRangeValue === 0 && defImpRangeSlide
                                    ? "Not Important"
                                    : defImpRangeValue === 1
                                    ? "Somewhat Important"
                                    : "Very Important"}
                            </p>
                        </div> */}
                    </div>
                </div>

                <div className="metric_main_content">
                    <div className="flex gap-64">
                        <div>
                            <h6 className="font-semibold">Current Level</h6>
                            <div className="mt-2 flex flex-wrap gap-2 text-xs md:text-sm font-semibold md:font-medium">
                                {traits.map((item, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleTraits(item)}
                                        className={`${
                                            activeTraits.includes(item) &&
                                            "active-position"
                                        } px-3 md:px-4 py-1.5 md:py-2.5 rounded bg-base-100 border-[1.5px]`}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h6 className="font-semibold">Future Level</h6>
                            <div className="mt-2 flex flex-wrap gap-2 text-xs md:text-sm font-semibold md:font-medium">
                                {/* <button   
                                    className={`${
                                        activeTraits.includes(3) &&
                                        "active-position"
                                    } px-3 md:px-4 py-1.5 md:py-2.5 rounded bg-base-100 border-[1.5px]`}
                                >
                                    Dropdown
                                </button> */}
                                <FutureLevel
                                    leagueSelected={leagueSelected}
                                    setLeagueSelected={
                                        setLeagueSelected
                                    }
                                    setSeasonSelected={
                                        setSeasonSelected
                                    }
                                    setTeamSelected={setTeamSelected}
                                    setPage={setPage}
                                    selectedLeagues={selectedLeagues}
                                    setSelectedLeagues={
                                        setSelectedLeagues
                                    }
                                    setTeams={setTeams}
                                    setSelectedTeams={setSelectedTeams}
                                    selectedLeague={selectedLeague}
                                    setSelectedLeague={
                                        setSelectedLeague
                                    }
                                    setLeagueApiCall={setLeagueApiCall}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Table */}
                    {playerHistory.length > 0 && (
                        <HistoryTable
                            history={playerHistory}
                            setPlayerHistory={setPlayerHistory}
                        />
                    )}
                    {loading && page === 1 && (
                        <div
                            className={` ${"mt-[20%]"} flex justify-center items-center`}
                        >
                            <img width={150} src={loader} alt="loading..." />
                        </div>
                    )}

                    {!loading &&
                        notFound &&
                        activePositions.length !== 0 &&
                        playerHistory.length === 0 && (
                            <div className="mt-[10px] ml-3 font-bold text-red-600 ">
                                Result not found!
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Metric2;
