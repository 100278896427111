import React, { useContext, useEffect, useState } from "react";
import './Rankings.css';
import TeamsRankings from "./TeamsRankings";
import LeaguesRanking from "./LeaguesRanking";
import PremierLeagueRanking from "./PremierLeagueRanking";
import contentLoader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";

const Rankings = () => {
  const [allData, setAllData] = useState(null);
  const [contentLoading, setContentLoading] = useState(true);
  const {base_url, token} = useContext(DataContext);

  useEffect(()=>{
    fetch(`${base_url}/api/v1/rankings`,{
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res=>res.json())
    .then(data=>{
      if(data.length){
        setAllData(data)
        setContentLoading(false);
      }
    })
    .catch(error=>{
      if(error){
        alert("server error happened")
      }
    })
  },[])

  if (contentLoading) {
    return (
      <div id="preloader" className="flex items-center justify-center">
        <div className="flex loader-box justify-center items-center ">
          <span className="text-center">
            <img
              className="mx-auto d-block"
              alt="loader"
              width="150"
              src={contentLoader}
            />
          </span>
        </div>
      </div>
    );
  } else {

    return (
        <div className="p-10 min-h-screen flex rankings">
        {/* <div className="rankings-filter w-1/6 ">
            <div>
            <label>Date from</label>
            <select className="mt-2 px-3 select select-bordered w-full max-w-xs">
                <option selected>Today</option>
                <option>Yesterday</option>
            </select>
            </div>
            <div className="mt-3" >
            <label>Season</label>
            <select className="mt-2 px-3 select select-bordered w-full max-w-xs">
                <option selected>2022-23</option>
                <option>2021-22</option>
            </select>
            </div>
            <div></div>
        </div> */}
        <div className="w-full rankings-table flex justify-between gap-5 ">
            <TeamsRankings data={allData} />
            <LeaguesRanking data={allData} />
            <PremierLeagueRanking data={allData} />
        </div>
        </div>
    );
  }
};

export default Rankings;
