import React, { useContext, useState } from "react";
import searchFilter from "../../Images/search-filter.png";
import searchBlue from "../../Images/search-blue.png";
import sortDown from "../../Images/sorting-down.png";
import sortUp from "../../Images/sorting-up.png";
import { sortBasedOnKey } from "../../customFunctions/sortingFunction";
import arrow from "../../Images/arrow.png";
import Pagination from "../../customFunctions/pagination";
import { DataContext } from "../../Context/DataProvider";
import { IoStar } from "react-icons/io5";

const HistoryTable = ({ history, setPlayerHistory }) => {
  const [togglePlayerSort, setTogglePlayerSort] = useState(true);
  const [toggleTeamSort, setToggleTeamSort] = useState(true);
  const [toggleAgeSort, setToggleAgeSort] = useState(true);
  const [togglePositionSort, setTogglePositionSort] = useState(true);
  const [toggleMinutesSort, setToggleMinutesSort] = useState(true);
  const [toggleLeagueSort, setToggleLeagueSort] = useState(true);
  const [toggleSeasonSort, setToggleSeasonSort] = useState(true);
  const [toggleGSort, setToggleGSort] = useState(true);
  const [toggleGASort, setToggleGASort] = useState(true);
  const [toggleGDSort, setToggleGDSort] = useState(true);
  const [toggleBPRSort, setToggleBPRSort] = useState(true);
  const [toggleSCRSort, setToggleSCRSort] = useState(true);
  const [toggleBRTSort, setToggleBRTSort] = useState(true);
  const [toggleDIMSort, setToggleDIMSort] = useState(true);
  const [toggleDFRSort, setToggleDFRSort] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [savedPlayer, setSavedPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [tableBlur, setTableBlur] = useState(false);

  const { base_url, token, user, playerShortListData, setPlayerShortListData } =
    useContext(DataContext);

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

  // Get the current page's data
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddOrRemove = (player) => {
    if (!checkSavePlayer(playerShortListData, player?.name_dob)) {
      fetch(`${base_url}/api/v1/player/shortlist`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user?.id,
          player_id: player?.id,
          name_dob: player?.name_dob,
          name: player?.name,
          team: player?.team,
          position: player?.position,
          x_gdar: player?.x_gdar ? player.x_gdar : "0",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            setPlayerShortListData([...playerShortListData, json]);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      const targetedPlayer = checkSavePlayer(
        playerShortListData,
        player?.name_dob
      );
      fetch(`${base_url}/api/v1/player/shortlist/${targetedPlayer?.id}`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: user?.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const remainingPlayers = playerShortListData?.filter(
              (obj) => obj?.id !== targetedPlayer?.id
            );
            // const targetedPlayer = data?.find((obj) => obj?.id === id);
            // const filteredPlayer = playerShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
            // setData(remainingPlayers);
            setPlayerShortListData(remainingPlayers);
          }
        })

        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };

  function checkSavePlayer(arr, searchName) {
    const foundObject = arr.find((item) => item?.name_dob === searchName);
    return foundObject;
  }

  return (
    <div className="mt-6 bg-base-100 pb-2 rounded-lg">
      {/*  Header */}
      <div className="px-3 md:px-6 py-3.5 border-b flex justify-between items-center">
        <h6 className="font-semibold 2xl:text-base">Results</h6>
        <div className="relative">
          <button className="w-full input-border px-3 py-2 rounded-lg outline-none flex gap-[9px] items-center bg-base-100">
            <p className="text-[13px] 2xl:text-sm font-bold ">Performance</p>
            <img src={arrow} alt="" />
          </button>
        </div>
      </div>

      <div className="overflow-x-auto pt-4 font-semibold metric-table">
        <table className="table hidden md:block w-full">
          {/* head */}
          <thead className="text-neutral-content">
            <tr>
              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "name",
                      togglePlayerSort,
                      history,
                      setPlayerHistory
                    );
                    setTogglePlayerSort(!togglePlayerSort);
                  }}
                  className="flex  justify-between cursor-pointer items-center "
                >
                  <p className="pl-6 w-[140px] min-[1920px]:w-[150px] ">
                    PLAYER
                  </p>
                </div>
              </th>

              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "team",
                      toggleTeamSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleTeamSort(!toggleTeamSort);
                  }}
                  className="flex cursor-pointer justify-between items-center"
                >
                  <p className="w-[100px]">Team</p>
                </div>
              </th>

              <th>
                <p className="w-[auto] ml-4 mr-4 text-center">TRAITS</p>
              </th>

              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "age",
                      toggleAgeSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleAgeSort(!toggleAgeSort);
                  }}
                  className="cursor-pointer w-[40px] min-[1920px]:w-[20px] text-center"
                >
                  AGE
                </div>
              </th>
              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "position",
                      togglePositionSort,
                      history,
                      setPlayerHistory
                    );
                    setTogglePositionSort(!togglePositionSort);
                  }}
                  className="cursor-pointer w-[45px] min-[1920px]:w-[20px] text-center"
                >
                  POS
                </div>
              </th>

              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "minutes",
                      toggleMinutesSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleMinutesSort(!toggleMinutesSort);
                  }}
                  className="cursor-pointer w-[65px] min-[1920px]:w-[40px] min-[1920px]:mr-1 text-center"
                >
                  MINUTES
                </div>
              </th>

              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "season",
                      toggleSeasonSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleSeasonSort(!toggleSeasonSort);
                  }}
                  className="cursor-pointer w-[60px] min-[1920px]:w-[44px] min-[1920px]:mr-1 text-center"
                >
                  SEASON
                </div>
              </th>
              <th>
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "league_code",
                      toggleLeagueSort,
                      history,
                      setPlayerHistory
                    );
                    setToggleLeagueSort(!toggleLeagueSort);
                  }}
                  className="cursor-pointer w-[60px] min-[1920px]:w-[30px] min-[1920px]:mr-4 text-center"
                >
                  LEAGUE
                </div>
              </th>

              <th>
                <div className="px-2 items-center text-center">
                  Current Level
                </div>
              </th>
              <th>
                <div className="px-2 items-center text-center">
                  Future Level
                </div>
              </th>

              <th>
                <div className="pr-3 2xl:pr-0 grid grid-cols-5 gap-2 items-center w-[240px] 2xl:w-[272px] text-center">
                  {/* <div
                    onClick={() => {
                      sortBasedOnKey(
                        "attack_bp",
                        toggleBPRSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleBPRSort(!toggleBPRSort);
                    }}
                    className="flex cursor-pointer ml-[-4px] w-[60px] justify-between items-center"
                  >
                    <h6>BPR</h6>
                  </div> */}
                  <div
                    onClick={() => {
                      sortBasedOnKey(
                        "attack_sc",
                        toggleSCRSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleSCRSort(!toggleSCRSort);
                    }}
                    className="flex cursor-pointer ml-[-4px] justify-between items-center"
                  >
                    <h6>PSV</h6>
                  </div>
                  <div
                    onClick={() => {
                      sortBasedOnKey(
                        "ball_ret",
                        toggleBRTSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleBRTSort(!toggleBRTSort);
                    }}
                    className="flex cursor-pointer ml-[-4px] justify-between items-center"
                  >
                    <h6>HIR</h6>
                  </div>
                  <div
                    onClick={() => {
                      sortBasedOnKey(
                        "def_qual",
                        toggleDIMSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleDIMSort(!toggleDIMSort);
                    }}
                    className="flex cursor-pointer ml-[-4px] justify-between items-center"
                  >
                    <h6>RXT</h6>
                  </div>
                  <div
                    onClick={() => {
                      sortBasedOnKey(
                        "def_quant",
                        toggleDFRSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleDFRSort(!toggleDFRSort);
                    }}
                    className="flex cursor-pointer ml-[-4px] justify-between items-center"
                  >
                    <h6>PXT</h6>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((player, index) => (
              <tr className="border-b" key={index}>
                <td>
                     
                  <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                    {/* <img
                        width={30}
                        height={30}
                        className="size-10 border rounded-full bg-slate-500 object-cover"
                        src={player?.image_url ? player.image_url : `${base_url}/api/v1/player/image/${player?.name_dob}`}
                        alt="player"
                    /> */}
                    <div className="avatar">
                        <div className="w-10 rounded-full border">
                            <img  src={player?.image_url ? player.image_url : `${base_url}/api/v1/player/image/${player?.name_dob}`} alt="player" />
                        </div>
                    </div>
                    {checkSavePlayer(playerShortListData, player?.name_dob) ? (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchBlue}
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="cursor-pointer"
                        onClick={() => handleAddOrRemove(player)}
                        src={searchFilter}
                        alt="icon"
                      />
                    )}
                    <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                      {player?.name}
                    </h4>
                  </div>
                </td>
                <td>
                  <p className="font-medium text-neutral-content">
                    {player?.team}
                  </p>
                </td>

                <td>
                  <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                    {player.hot > 0 && (
                      <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                        HPF
                      </div>
                    )}
                    {player.cold > 0 && (
                      <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                        LPF
                      </div>
                    )}

                    {player.prospect > 0 && (
                      <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                        YNG
                      </div>
                    )}
                    {player.underused > 0 && (
                      <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                        UU
                      </div>
                    )}
                    {player.breakout > 0 && (
                      <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                        FSE
                      </div>
                    )}
                  </div>
                </td>

                <td className="">
                  <div className="text-center">
                    {player?.age > 0 ? player?.age : "-"}
                  </div>
                </td>
                <td className="">
                  <div className="text-center">{player?.position}</div>
                </td>
                <td className=" text-neutral-content">
                  <div className="text-center">{player?.minutes}</div>
                </td>
                <td className="font-medium text-center text-neutral-content">
                  {player?.season}
                </td>
                <td className="font-medium text-center text-neutral-content">
                  <div className="text-center">{player?.league_code}</div>
                </td>

                <td>
                    {/* <div className="flex justify-center space-x-1">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <svg key={star} className='w-4 cursor-pointer' viewBox="0 0 24 24" fill="#94a3b8">
                                <path 
                                    d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z" 
                                    fill={ star <= 5 ? "#f2b00a" :"#94a3b8" } />
                            </svg>
                        ))}
                    </div> */}
                    <div className="flex justify-center space-x-1">
                        
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-gray-300 text-lg" />
                        
                    </div>
                </td>
                <td>
                    <div className="flex justify-center space-x-1">
                        
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-green-500 text-lg" />
                        <IoStar className="text-gray-300 text-lg" />
                        
                    </div>
                </td>

                <td>
                  <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                    {/* <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                      <p>{player?.attack_bp || 0 }</p>
                    </div> */}

                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                      <p>{player?.attack_sc || 0 }</p>
                    </div>

                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                      <p>{player?.ball_ret || 0 }</p>
                    </div>

                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                      <p>{player?.def_qual || 0 }</p>
                    </div>

                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                      <p>{player?.def_quant || 0 }</p>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* table for mobile device */}
        <table
          className={`table py-0 ${tableBlur && "blur-[1.2px]"} md:hidden w-full`}
        >
          {/* head */}
          <thead className="text-neutral-content mt-[-100px] ">
            <tr className="">
              <th className="px-2">
                <div
                  onClick={() => {
                    sortBasedOnKey(
                      "name",
                      togglePlayerSort,
                      history,
                      setPlayerHistory
                    );
                    setTogglePlayerSort(!togglePlayerSort);
                  }}
                  className="text-xs text-[#7C86A1] font-semibold"
                >
                  PLAYER
                </div>
              </th>

              <th className="px-2">
                <div className="cursor-pointer text-[#7C86A1] ml-[-8px] text-xs font-semibold w-[100px]">
                  DETAILS
                </div>
              </th>

              <th className="px-2">
                <div className="grid grid-cols-2 w-[90px] gap-1 items-center text-center">
                  <h6
                    onClick={() => {
                      sortBasedOnKey(
                        "x_gfar",
                        toggleGASort,
                        history,
                        setPlayerHistory
                      );
                      setToggleGASort(!toggleGASort);
                    }}
                    className="cursor-pointer font-semibold text-xs text-[#7C86A1]"
                  >
                    G
                  </h6>
                  <h6
                    onClick={() => {
                      sortBasedOnKey(
                        "x_gaar",
                        toggleGDSort,
                        history,
                        setPlayerHistory
                      );
                      setToggleGDSort(!toggleGDSort);
                    }}
                    className="cursor-pointer font-semibold text-xs text-[#7C86A1]"
                  >
                    GA
                  </h6>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((player, index) => (
              <tr
                style={{ padding: "1px 2px !important" }}
                className="border-b"
                key={index}
              >
                <td className="px-1">
                  <div className="font-semibold flex gap-2 items-center">
                    <img
                      className="w-[24px] h-[24px] border rounded-full"
                      src={`${base_url}/api/v1/player/image/${player?.name_dob}`}
                      alt="team"
                    />

                    <div
                      onClick={() => {
                        setSelectedPlayer(player);
                        setTableBlur(true);
                      }}
                    >
                      <label
                        htmlFor="player-action"
                        className="whitespace-pre-wrap font-medium text-xs "
                      >
                        {player?.name.split(" ")[0]}{" "}
                        {
                          player?.name.split(" ")[
                            player?.name.split(" ").length - 1
                          ]
                        }
                      </label>
                    </div>
                  </div>
                </td>

                <td className="px-1">
                  <div className="text-center flex justify-between gap-1 font-medium text-xs ">
                    <div>{player?.age > 0 ? player?.age : "-"}</div>
                    <div>{player?.age > 0 ? player?.position : "-"}</div>
                    <div className="text-[#9299AA]">
                      {player?.season
                        ? player?.season.slice(2).replace("-", "/")
                        : "-"}
                    </div>
                  </div>
                </td>

                <td className="px-1">
                  <div className="flex items-center justify-center gap-1 font-semibold text-xs">
                    <div className="w-11 h-7 border rounded flex justify-center items-center">
                      {player?.x_gfar ? player.x_gfar : "0.00"}
                    </div>
                    <div className="w-11 h-7 border rounded flex justify-center items-center">
                      {player?.x_gaar ? player.x_gaar : "0.00"}
                    </div>
                    
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* add or remove player from shortlist modal */}
      <div className="">
        <input type="checkbox" id="player-action" className="modal-toggle" />
        <div className="modal bg-[#22272F29]">
          <div className="w-full p-5 fixed bottom-0">
            <div className="text-center w-full block mb-3 rounded-[8px] bg-white">
              <div className="text-center text-[#9299AA] font-medium text-lg w-full block py-4 px-3">
                Visit Profile
              </div>
              <hr />
              <div
                onClick={() => {handleAddOrRemove(selectedPlayer); setTableBlur(false)}}
                className="text-center text-[#9299AA] font-medium text-lg w-full block py-4 px-3"
              >
                <label htmlFor="player-action">
                  {checkSavePlayer(
                    playerShortListData,
                    selectedPlayer?.name_dob
                  )
                    ? "Remove from shortlist"
                    : "Add to shortlist"}
                </label>
              </div>
            </div>
            <div onClick={() => setTableBlur(false)}>
              <label
                htmlFor="player-action"
                className="text-center w-full block py-2.5 px-3 text-[#22272F] font-semibold text-lg rounded-[8px] bg-white"
              >
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-4 mb-2 text-center">
        <div className="">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              className={`w-8 h-8 mx-1 mb-2 md:mb-0 text-neutral-content  border rounded-md ${
                currentPage === index + 1 && "active-page"
              } `}
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {/* <Pagination data={history} /> */}
      {/* {links?.length > 3 && (
        <div className="px-6 py-4 border-t  text-sm">
          <div>
            <div className="flex justify-center text-neutral-content">
              {links?.map((link, index) => (
                <>
                  {link?.label === "&laquo; Previous" ? (
                    <div>
                      <button
                        disabled={Number(page) === 1}
                        onClick={() => {
                          setPage(page > 1 && Number(page) - 1);
                          setPageLoad(true);
                        }}
                        className="w-8  mx-1 h-8 flex justify-center items-center border rounded-md"
                      >
                        <img src={arrow} alt="" className="w-2 rotate-90" />
                      </button>
                    </div>
                  ) : link?.label === "Next &raquo;" ? (
                    <div>
                      <button
                        // disabled={page > page - 1}
                        onClick={() => {
                          setPage(page < totalPage && Number(page) + 1);
                          setPageLoad(true);
                        }}
                        className="w-8 h-8  mx-1 ml-4 flex justify-center items-center border rounded-md"
                      >
                        <img src={arrow} alt="" className="w-2 -rotate-90" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handlePageClick(link?.label)}
                      className={`w-8 h-8  mx-4 flex justify-center items-center rounded-md ${
                        page === Number(link?.label) && "active-page"
                      } `}
                    >
                      {link?.label}
                    </button>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default HistoryTable;
