import React, { useEffect, useState } from "react";

const Seasons = ({
  selectedSeason,
  setSelectedSeason,
  playerName,
  setPage,
  allSeasons,
}) => {
  const [seasonToggle, setSeasonToggle] = useState(false);
  const [seasonFirstClick, setSeasonFirstClick] = useState(false);

  const handleSeasonSelected = (season) => {
    setSeasonFirstClick(true);
    setSelectedSeason(season);
    // setPage(1);
  };


  let content = selectedSeason!==undefined?selectedSeason:"Select Season";
  if(!selectedSeason && playerName!=="" ){
    content = 
    <svg
    class="animate-spin mx-auto h-5 w-5 text-slate-400"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
  }
  
  if((allSeasons?.length>0) && (selectedSeason!==undefined || selectedSeason==="Select Season") && !seasonFirstClick ){
    setSelectedSeason(allSeasons?.[0]);
    content = selectedSeason;
  }

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        // !e.target.closest(".season-dropdown") &&
        !e.target.closest(".season-btn")
      ) {
        setSeasonToggle(false);
      }
    });
  }, []);

  return (
    <div className="mt-3 ">
      <h6>Season</h6>

      <div className="relative mt-2">
        <button
          onClick={() => setSeasonToggle(!seasonToggle)}
          className="w-full xl:w-full capitalize input-border p-2 rounded-lg outline-none season-btn flex justify-between items-center bg-base-100"
        >
          
         {content}
          
        </button>

        {seasonToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll season-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {allSeasons?.map((item, index) => (
              <p
                key={index}
                onClick={() => handleSeasonSelected(item)}
                className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] duration-300"
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Seasons;
