import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Team.css";
import playersData from "../../players.json";
import { BiSearch } from "react-icons/bi";
import { DataContext } from "../../Context/DataProvider";
import { useParams } from "react-router-dom";
import League from "./dropdown/League";
import Team from "./dropdown/Team";
import Season from "./dropdown/Season";
import Position from "./dropdown/Position";

const CreatePlayerModal = ({ id, benchId, selectedPlayer, playerType }) => {
  const [searchedText, setSearchedText] = useState("");
  const [players, setPlayers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [mainContent, setMainContent] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({});
  const [playerName, setPlayerName] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [playerAllDetails, setPlayerAllDetails] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [positions, setPositions] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [leagueSelected, setLeagueSelected] = useState("");
  const [seasonSelected, setSeasonSelected] = useState("");
  const [teamSelected, setTeamSelected] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [latestSeason, setLatestSeason] = useState("");
  const [playerUpdate, setPlayerUpdate] = useState(1);

  const { base_url, token, user, assignedPlayers, setAssignedPlayers } =
    useContext(DataContext);

  const { team } = useParams();
  // getting all data when a player is searched and clicked
  useEffect(() => {
    if (playerName !== "") {
      setContentLoading(true);
      
      fetch(`${base_url}/api/v1/player/details?name_dob=${playerName}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("name",playerName);
          setPlayerAllDetails(data);
          setLoading(false);
          setContentLoading(false);
        });
    }
  }, [playerName]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handlePlayerDetail = (name) => {
    
    setFirstClick(true);
    setPlayerName(name);
    setSearchedText(name);
    setSearchDropdown(false);
    setNotFound(false);
  };

  // making sorted api data to an array
  useEffect(() => {
    if (playerAllDetails !== undefined && playerName !== "") {
      const result = Object.values(playerAllDetails);

      const newData = [];
      result.length &&
        result.map((data) => data.map((item) => newData.push(item)));
      if (newData.length > 0) {
        setFinalData(newData);
      }
    }
  }, [playerAllDetails, playerName]);

  useEffect(() => {
    // setInfoLoading(true);

    if (finalData.length && firstClick) {
      if (finalData.length) {
        const newestSeasonObj = finalData?.find((data) =>
          Math.max.apply(data.season)
        );
        setLatestSeason(newestSeasonObj?.season);
        if (latestSeason !== "") {
          const arrByLatestSeason = finalData?.filter(
            (data) => data?.season === latestSeason
          );

          let maxObjectByMInutes = null;
          let maxValue = Number.NEGATIVE_INFINITY;

          for (const obj of arrByLatestSeason) {
            if (Number(obj.minutes) > maxValue) {
              maxObjectByMInutes = obj;
              maxValue = Number(obj.minutes);
            }
          }
          const player = maxObjectByMInutes;
         
          setPlayerInfo(player);
          setPositionSelected(player?.position);
          setSeasonSelected(player?.season);
          setLeagueSelected(player?.league);
          setTeamSelected(player?.team_short_name);
        }
      }
    }
    // setInfoLoading(false);
  }, [finalData, latestSeason, playerAllDetails, playerName, firstClick]);

  const cancelCreatePlayer = () => {
    setFinalData([]);
    setPlayerAllDetails([]);
    setPlayerInfo({});
    setPlayerName("");
    setSelectedSeason("");
    setSelectedPosition("");
    setSelectedLeague("");
    setSelectedTeam("");
    setSeasons([]);
    setSelectedSeason("");
    setLeagues([]);
    setSelectedLeague("");
    setTeams([]);
    setSelectedTeam("");
    setPositions([]);
    setSelectedPosition("");
    setSearchedText("");
  };

  useEffect(() => {
    const teamRes = [];
    const seasonRes = [];
    const posRes = [];
    const leagueRes = [];
    if (finalData?.length > 0) {
      finalData.forEach((player) => {
        teamRes.push(player?.team);
        seasonRes.push(player?.season);
        posRes.push(player?.position);
        leagueRes.push(player?.league_code);
      });
    }
    setTeams([...new Set(teamRes)]);
    setSeasons([...new Set(seasonRes)]);
    setPositions([...new Set(posRes)]);
    setLeagues([...new Set(leagueRes)]);
  }, [finalData]);

  useEffect(() => {
    setSelectedLeague(leagues[0]);
    setSelectedPosition(positions[0]);
    setSelectedSeason(seasons[0]);
    setSelectedTeam(teams[0]);
  }, [seasons, leagues, positions, teams]);

  
  const handleAddPlayer = () => {
    const newPlayer = {
      id: playerType === "normalPlayer" ? id : benchId,
      playerName: playerName,
      createdTeam: team,
      season: selectedSeason,
      position: selectedPosition,
      team: selectedTeam,
      league: selectedLeague,
      attack_bp: playerInfo?.attack_bp,
      attack_sc: playerInfo?.attack_sc,
      ball_ret: playerInfo?.ball_ret,
      def_qual: playerInfo?.def_qual,
      def_quant: playerInfo?.def_quant,
      x_gaar: playerInfo?.x_gaar,
      x_gfar: playerInfo?.x_gfar,
      x_gdar: playerInfo?.x_gdar,
    };
    const teamExist = assignedPlayers?.find(
      (teamData) => teamData.team === team
    );

    if (teamExist) {
      if (playerType === "normalPlayer") {
        if (teamExist?.players.length > 0) {
          const isPlayerAdded = teamExist?.players?.find(
            (player) => player.id === id
          );
          if (isPlayerAdded) {
            fetch(`${base_url}/api/v1/team_players/1`, {
              method: "PATCH",
              body: JSON.stringify({
                user_id: user?.id,
                players_id: newPlayer.id,
                team: team,
                what_inserting: "players",
                players: newPlayer,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.success === "updated successfully") {
                  console.log(json);
                }
              })
              .catch((error) => {
                if (error) {
                  console.log(error);
                }
              });

            isPlayerAdded.playerName = playerName;
            isPlayerAdded.season = selectedSeason;
            isPlayerAdded.position = selectedPosition;
            isPlayerAdded.team = selectedTeam;
            isPlayerAdded.league = selectedLeague;
            isPlayerAdded.attack_bp = playerInfo?.attack_bp;
            isPlayerAdded.attack_sc = playerInfo?.attack_sc;
            isPlayerAdded.ball_ret = playerInfo?.ball_ret;
            isPlayerAdded.def_qual = playerInfo?.def_qual;
            isPlayerAdded.def_quant = playerInfo?.def_quant;
            isPlayerAdded.x_gaar = playerInfo?.x_gaar;
            isPlayerAdded.x_gfar = playerInfo?.x_gfar;
            isPlayerAdded.x_gdar = playerInfo?.x_gdar;
          } else {
            fetch(`${base_url}/api/v1/team_players`, {
              method: "POST",
              body: JSON.stringify({
                user_id: user?.id,
                players_id: newPlayer.id,
                team: team,
                what_inserting: "players",
                players: newPlayer,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => response.json())
              .then((json) => {
                if (json) {
                console.log(json);
                }
              })
              .catch((error) => {
                if (error) {
                  console.log(error);
                }
              });
            // end add player to db
            teamExist?.players.push(newPlayer);
            
          }
        } else {
          // add player to db
          fetch(`${base_url}/api/v1/team_players`, {
            method: "POST",
            body: JSON.stringify({
              user_id: user?.id,
              players_id: newPlayer.id,
              team: team,
              what_inserting: "players",
              players: newPlayer,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((json) => {
              if (json) {
                console.log(json);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
          // end add player to db
          teamExist?.players.push(newPlayer);
        }
      } else {
        if (teamExist?.benches.length > 0) {
          const isBenchPlayerAdded = teamExist?.benches?.find(
            (player) => player.id === benchId
          );
          if (isBenchPlayerAdded) {
            fetch(`${base_url}/api/v1/team_players/1`, {
              method: "PATCH",
              body: JSON.stringify({
                user_id: user?.id,
                benches_id: newPlayer.id,
                team: team,
                what_inserting: "benches",
                benches: newPlayer,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => response.json())
              .then((json) => {
                if (json) {
                  console.log(json);
                }
              })
              .catch((error) => {
                if (error) {
                  console.log(error);
                }
              });

            isBenchPlayerAdded.playerName = playerName;
            isBenchPlayerAdded.season = selectedSeason;
            isBenchPlayerAdded.position = selectedPosition;
            isBenchPlayerAdded.team = selectedTeam;
            isBenchPlayerAdded.league = selectedLeague;
          } else {
            fetch(`${base_url}/api/v1/team_players`, {
              method: "POST",
              body: JSON.stringify({
                user_id: user?.id,
                benches_id: newPlayer.id,
                team: team,
                what_inserting: "benches",
                benches: newPlayer,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => response.json())
              .then((json) => {
                if (json) {
                  console.log(json);
                }
              })
              .catch((error) => {
                if (error) {
                  console.log(error);
                }
              });
            // end add player to db
            teamExist?.benches.push(newPlayer);
          }
        } else {
          fetch(`${base_url}/api/v1/team_players`, {
            method: "POST",
            body: JSON.stringify({
              user_id: user?.id,
              benches_id: newPlayer.id,
              team: team,
              what_inserting: "benches",
              benches: newPlayer,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((json) => {
              if (json) {
                console.log(json);
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
          teamExist?.benches.push(newPlayer);
        }
      }
      setAssignedPlayers([...assignedPlayers]);
    } else {
      if (playerType === "normalPlayer") {
        const item = {
          team: team,
          benches: [],
          players: [
            {
              id: id,
              playerName: playerName,
              createdTeam: team,
              season: selectedSeason,
              position: selectedPosition,
              team: selectedTeam,
              league: selectedLeague,
              attack_bp: playerInfo?.attack_bp,
              attack_sc: playerInfo?.attack_sc,
              ball_ret: playerInfo?.ball_ret,
              def_qual: playerInfo?.def_qual,
              def_quant: playerInfo?.def_quant,
              x_gaar: playerInfo?.x_gaar,
              x_gfar: playerInfo?.x_gfar,
              x_gdar: playerInfo?.x_gdar,
            },
          ],
        };
        // add player to db when players is empty
        fetch(`${base_url}/api/v1/team_players`, {
          method: "POST",
          body: JSON.stringify({
            user_id: user?.id,
            players_id: id,
            team: team,
            what_inserting: "players",
            players: {
              id: id,
              playerName: playerName,
              createdTeam: team,
              season: selectedSeason,
              position: selectedPosition,
              team: selectedTeam,
              league: selectedLeague,
              attack_bp: playerInfo?.attack_bp,
              attack_sc: playerInfo?.attack_sc,
              ball_ret: playerInfo?.ball_ret,
              def_qual: playerInfo?.def_qual,
              def_quant: playerInfo?.def_quant,
              x_gaar: playerInfo?.x_gaar,
              x_gfar: playerInfo?.x_gfar,
              x_gdar: playerInfo?.x_gdar,
            },
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json) {
              console.log(json);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
        // end add player to db
        setAssignedPlayers([...assignedPlayers, item]);
      } else {
        const item = {
          team: team,
          players: [],
          benches: [
            {
              id: benchId,
              playerName: playerName,
              createdTeam: team,
              season: selectedSeason,
              position: selectedPosition,
              team: selectedTeam,
              league: selectedLeague,
              attack_bp: playerInfo?.attack_bp,
              attack_sc: playerInfo?.attack_sc,
              ball_ret: playerInfo?.ball_ret,
              def_qual: playerInfo?.def_qual,
              def_quant: playerInfo?.def_quant,
              x_gaar: playerInfo?.x_gaar,
              x_gfar: playerInfo?.x_gfar,
              x_gdar: playerInfo?.x_gdar,
            },
          ],
        };
        // add bench to db when bench is empty
        fetch(`${base_url}/api/v1/team_players`, {
          method: "POST",
          body: JSON.stringify({
            user_id: user?.id,
            benches_id: benchId,
            team: team,
            what_inserting: "benches",
            benches: {
              id: benchId,
              playerName: playerName,
              createdTeam: team,
              season: selectedSeason,
              position: selectedPosition,
              team: selectedTeam,
              league: selectedLeague,
              attack_bp: playerInfo?.attack_bp,
              attack_sc: playerInfo?.attack_sc,
              ball_ret: playerInfo?.ball_ret,
              def_qual: playerInfo?.def_qual,
              def_quant: playerInfo?.def_quant,
              x_gaar: playerInfo?.x_gaar,
              x_gfar: playerInfo?.x_gfar,
              x_gdar: playerInfo?.x_gdar,
            },
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json) {
              console.log(json);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
        // end add bench to db
        setAssignedPlayers([...assignedPlayers, item]);
      }
    }

    setFinalData([]);
    setPlayerAllDetails([]);
    setPlayerName("");
    setSeasons([]);
    setSelectedSeason("");
    setLeagues([]);
    setSelectedLeague("");
    setTeams([]);
    setSelectedTeam("");
    setPositions([]);
    setSelectedPosition("");
    setSearchedText("");
  };

  useEffect(() => {
    if (searchedText === "") {
      setPlayers(null);
    }
  }, [searchedText]);

  const handleSearch = useCallback(() => {
    if (searchedText !== "") {
      setLoading(true);

      const searchWords = searchedText.trim().split(/\s+/);
      const filteredResults = playersData.filter((player) => {
        const { name_team } = player;
        const lowerCaseName = name_team.toLocaleLowerCase();

        return searchWords.every((word) =>
          lowerCaseName.includes(word.toLowerCase())
        );
      });

      setPlayers(filteredResults.slice(0, 10));
      setLoading(false);
      setNotFound(false);
    } else {
      setLoading(false);
      // setNotFound(true);
    }
  }, [searchedText]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchedText);
    }, 400);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchedText, handleSearch]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchedText(value);
    setPlayerName("");
    setSearchDropdown(true);
  };

  let content;
  if (loading && players === null && searchedText.length > 0) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (players?.length === 0 && playerName === "" && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }

  if (
    players?.length > 0 &&
    !loading &&
    players !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <ul className=" max-h-[300px] mt-3 overflow-y-scroll b z-50 shadow-lg bg-base-100 p-2 rounded-box">
        <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">Players</h6>
        {players?.map((player, index) => (
          <li key={index} onClick={() => handlePlayerDetail(player?.name_dob)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{player?.name_dob}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div>
      <input
        type="checkbox"
        id="create-new-player-modal"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box create-new-player-modal p-0">
          <div className="create-new-player-modal-header p-5">
            <h3 className="font-bold text-lg">Create new player {benchId} </h3>
          </div>
          <div className="px-5 pt-5 pb-0">
            <div>
              <div className="mb-2">
                <label htmlFor="">Player Name</label>
              </div>
              <div className="w-full search-box relative text-sm mb-4 lg:mb-0">
                <input
                  type="text"
                  name=""
                  value={searchedText}
                  onChange={handleSearchChange}
                  className="w-full input-border p-3 rounded-lg outline-none"
                  placeholder="Search for a player"
                />

                {/* <div className="absolute top-4 left-4 text-lg">
                <BiSearch />
              </div> */}
              </div>
              {content}
            </div>
            {contentLoading ? (
              <div className="p-5">loading.....</div>
            ) : (
              <>
                {finalData?.length > 0 && (
                  <>
                    <Season
                      position={positionSelected}
                      seasonSelected={seasonSelected}
                      setSeasonSelected={setSeasonSelected}
                      // new
                      setPositionSelected={setPositionSelected}
                      setLeagueSelected={setLeagueSelected}
                      setTeamSelected={setTeamSelected}
                      finalData={finalData}
                      setFirstClick={setFirstClick}
                      setPlayerInfo={setPlayerInfo}
                    />
                    <Position
                      positionSelected={positionSelected}
                      setPositionSelected={setPositionSelected}
                      playerInfo={playerInfo}
                      // new
                      season={seasonSelected}
                      seasonSelected={seasonSelected}
                      setLeagueSelected={setLeagueSelected}
                      setTeamSelected={setTeamSelected}
                      finalData={finalData}
                      setFirstClick={setFirstClick}
                      setPlayerInfo={setPlayerInfo}
                    />
                    <League
                      // playerName={playerName}
                      leagueSelected={leagueSelected}
                      setLeagueSelected={setLeagueSelected}
                      position={positionSelected}
                      season={seasonSelected}
                      // new
                      setTeamSelected={setTeamSelected}
                      finalData={finalData}
                      setFirstClick={setFirstClick}
                      setPlayerInfo={setPlayerInfo}
                    />
                    <Team
                      teamSelected={teamSelected}
                      setTeamSelected={setTeamSelected}
                      position={positionSelected}
                      season={seasonSelected}
                      league={leagueSelected}
                      finalData={finalData}
                      setFirstClick={setFirstClick}
                      setPlayerInfo={setPlayerInfo}
                    />
                    {playerInfo && (
                      <div className=" mt-5 flex items-center gap-3 ">
                        <div>
                          <img
                            className="w-[45px] border rounded-full"
                            src={`${base_url}/api/v1/player/image/${playerInfo?.name_dob}`}
                            alt="player"
                          />
                        </div>
                        <div>
                          <h4 className="text-[#22272F] text-sm font-semibold ">
                            {playerInfo?.name}
                          </h4>
                          <h4 className="text-[#9299AA] text-sm font-medium ">
                            {playerInfo?.minutes} Min • Goals : {playerInfo?.x_gfar} •{" "}
                            Goals Against : {playerInfo?.x_gaar} <br /> • Goal Difference : {playerInfo?.x_gdar}
                          </h4>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="modal-action modal-footer flex justify-between px-5 pb-5 ">
            <label
              onClick={cancelCreatePlayer}
              htmlFor="create-new-player-modal"
              className="cancel-btn"
            >
              Cancel
            </label>

            <label htmlFor="create-new-player-modal">
              {!contentLoading && playerName !== "" && (
                <button
                  onClick={() => handleAddPlayer()}
                  className="confirm-btn"
                >
                  Confirm
                </button>
              )}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePlayerModal;
