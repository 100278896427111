import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';


const FinanceUploadFile = () => {
  const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);
    const [excelData, setExcelData] = useState(null);

  const handleFileChange = (e) => {
    let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        let selectedFile = e.target.files[0];
        if(selectedFile){
          if(selectedFile&&fileTypes.includes(selectedFile.type)){
            setTypeError(null);
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload=(e)=>{
              setExcelFile(e.target.result);
            }
          }
        }else{
          setTypeError('Please select only excel files');
          setExcelFile(null);
        }
        
  };

  useEffect(()=>{
    if(excelFile){
      const workbook = XLSX.read(excelFile,{type:'buffer'});
      const workSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data.slice(0,10));
    }
  },[excelFile])

  console.log(excelData);

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };
  return (
    <div className="your-teams pt-10 min-h-screen px-10 py-12">
      <div className="bg-base-100 rounded-lg">
        <div className="px-6 py-3.5 border-b flex justify-between items-center">
          <h6 className="font-semibold 2xl:text-base">Fenida FC finances</h6>
          <div className="relative">
            <button className="w-full input-border px-3 py-2 rounded-lg outline-none flex gap-[9px] items-center bg-base-100">
              <p className="text-[13px] text-[#22272F] 2xl:text-sm font-semibold ">
                See squad breakdown
              </p>
            </button>
          </div>
        </div>
        <div className="px-6 py-8">
          <h3 className="text-[#22272F] text-[21px] font-semibold ">
            How to manage team finances? {excelData?.length}
          </h3>
          <p className="text-[#9299AA] w-[40%] mt-1 text-base font-medium">
            We provide you well prepared excel file template, fill the columns
            with your team data and import the spreadsheet back to see your team
            finances
          </p>
          <div className="flex mt-[34px] gap-4">
            <button className=" input-border text-base text-[#22272F] 2xl:text-sm font-semibold px-3 py-2 rounded-lg outline-none bg-base-100">
              Download .xlsx template
            </button>
            <button
              onClick={handleButtonClick}
              className=" input-border text-base text-[#fff] 2xl:text-sm font-semibold px-3 py-2 rounded-lg outline-none bg-[#2C56EB]"
            >
              Import ready spreadsheet
            </button>
            <input
              type="file"
              id="fileInput"
              accept=".xls, .xlsx"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceUploadFile;
