import React, { useContext, useState } from "react";
import "./YourTeams.css";
import CreateTeamModal from "./CreateTeamModal";
import sidebar from "../../Images/sidebar.png";
import { DataContext } from "../../Context/DataProvider";
import UpdateTeamModal from "./UpdateTeamModal";
import DeleteTeamModal from "./DeleteTeamModal";
import { Link } from "react-router-dom";

const YourTeams = () => {
  const { teams, setTeams, formationData } = useContext(DataContext);

  // console.log(selectedTeam);

  return (
    <div className="your-teams pt-10 min-h-screen px-4 xl:px-10 w-full xl:w-9/12 ">
      <div className="flex justify-between items-center">
        <h2 className="page-title">Your Teams</h2>
        <label htmlFor="create-team-modal" className="btn-create-team">
          Create new team
        </label>
      </div>
      <div className="teams grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-10">
        {teams?.length > 0 &&
          teams.map((team, index) => (
            <div key={index} className="your-team px-3 pt-3 pb-5">
              <div className="dropdown dropdown-bottom flex justify-center items-center flex-col float-right">
                <label tabIndex={0} className=" cursor-pointer ">
                  <img src={sidebar} alt="" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content mt-2 menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <label htmlFor={`update-team-modal${index}`}>
                      Edit team detail
                    </label>
                  </li>
                  <li>
                    <label htmlFor={`delete-team-modal${index}`}>
                      Remove Team
                    </label>
                  </li>
                </ul>
              </div>

              <div
                
                className="flex flex-col justify-center items-center py-5"
              >
                <Link to={`/team/${team?.name}`} className="team-score text-center">
                  <p>{team?.formation}</p>
                </Link>
                <Link to={`/team/${team?.name}`} className="mt-8 text-center team-title">
                  <h4 className="mb-3 capitalize">{team?.name}</h4>

                  {/* <p className="time">18 mins ago</p> */}
                </Link>
              </div>
              <UpdateTeamModal index={index} team={team} />
              <DeleteTeamModal index={index} team={team} />
            </div>
          ))}
      </div>
      <CreateTeamModal />
    </div>
  );
};

export default YourTeams;
