import React from "react";
import arrow from "../../Images/arrow.png";
import { makePosition } from "../../customFunctions/makePosition";

const HistoryTable = ({ stats }) => {
  // console.log(playerDetails);
  return (
    <>
      <div className="mt-3">
        <div>
          <div className="pt-4 hidden md:block coach-profile font-semibold">
            <table className="table w-full">
              {/* head */}
              <thead className="text-neutral-content">
                <tr>
                  <th>SEASON</th>
                  <th>
                    <div className="">TEAM</div>
                  </th>
                  <th className="text-center">LEAGUE</th>
                  <th className="text-center">GAMES</th>
                  <th className="text-center">START</th>
                  <th className="text-center">END</th>
                  <th className="text-center">
                    <div className="">IMP</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}

                {stats?.map((info, index) => (
                  <tr
                    key={index}
                    className={` ${
                      index !== stats?.length - 1
                        ? "border-b-[1.5px] border-[#F2F3F6] "
                        : ""
                    } text-base text-[#9299AA] font-medium border-[#F2F3F6]`}
                  >
                    <td className="">
                      <div className="ml-0.5">{info?.season}</div>
                    </td>
                    <td>
                      <div className="text-[#22272F] font-semibold ">
                        {info?.team}
                      </div>
                    </td>
                    <td className="text-center">
                      <div>{info?.league}</div>
                    </td>
                    <td className="text-center">
                      <div className="">{info?.games}</div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] mx-auto font-semibold rounded w-[64px] px-2 py-1 bg-[#F7F7F8] ">
                        {makePosition(info?.rJ)}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] mx-auto font-semibold rounded w-[64px] px-2 py-1 bg-[#F7F7F8] ">
                        {makePosition(info?.rN)}
                      </div>
                    </td>
                    <td className="text-center">
                      <div
                        className={` ${
                          info?.delta > 0
                            ? "text-[#02B059] bg-[#02B05923]"
                            : info?.delta < 0
                            ? "text-[#FA4265] bg-[#FA426523] "
                            : "text-[#22272F]"
                        } ml-auto px-2 py-1 rounded `}
                      >
                        {info?.delta
                          ? info?.delta > 0
                            ? "+" + Math.round(info.delta)+"%"
                            : Math.round(info.delta)+"%"
                          : "0%"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* for mobile */}
          <div className=" mt-4 block w-full md:hidden profile-table-mobile  font-semibold">
            <table className="table w-full">
              {/* head */}
              <thead className="text-neutral-content">
                <tr>
                  <th className="text-center w-[10%] ">
                    <div className="">S</div>
                  </th>
                  <th className="w-[35%]">
                    <div className="">TEAM</div>
                  </th>
                  <th className="text-center"> </th>
                  <th className="text-center">P</th>
                  <th className="text-center">STA</th>
                  <th className="text-center">END</th>
                  <th className="text-center">
                    <div className="ml-2">
                    IMP
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}

                {stats?.map((info, index) => (
                  <tr
                    className={` ${
                      index !== stats?.length - 1
                        ? "border-b-[1.5px] border-[#F2F3F6] "
                        : ""
                    } text-xs text-[#9299AA] font-medium `}
                  >
                    <td className="">
                      <div className="ml-0.5">{info?.season}</div>
                    </td>
                    <td>
                      <div className="text-[#22272F]  whitespace-normal leading-[16px] font-semibold ">
                        {info?.team}
                      </div>
                    </td>
                    <td className="text-center">
                      <div>{info?.league}</div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272f] font-semibold ">
                        {info?.pts}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] mx-auto font-semibold rounded px-1.5 py-0.5 w-[32px] bg-[#F7F7F8] ">
                        {makePosition(info?.rJ)}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] font-semibold mx-auto rounded px-1.5 py-0.5 w-[32px] bg-[#F7F7F8] ">
                        {makePosition(info?.rN)}
                      </div>
                    </td>
                    <td className="text-center">
                      <div
                        className={` ${
                          info?.delta > 0
                            ? "text-[#02B059] bg-[#02B05923]"
                            : info?.delta < 0
                            ? "text-[#FA4265] bg-[#FA426523] "
                            : "text-[#22272F]"
                        } px-1.5 py-0.5 w-[42px] font-semibold flex ml-auto justify-center rounded  `}
                      >
                        {info?.delta
                          ? info?.delta > 0
                            ? "+" + Math.round(info.delta)+"%"
                            : Math.round(info.delta)+"%"
                          : "0%"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryTable;
