import React from "react";

const History = ({ playerDetails }) => {
    return (
        <div className="modal-box players-modal md:w-7/12 max-w-5xl p-0">
            <div className="players-modal-header flex justify-between items-center border-b p-5 pb-4">
                <h3 className="font-bold text-lg">Season History</h3>
                <label
                    htmlFor="history"
                    className="h-[25px] w-[25px] mt-[-4px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
                >
                    <div className=" ml-px ">x</div>
                </label>
            </div>
            <div className="p-5 pt-0">
                <>
                    <div className="hidden md:block">
                        <div>
                            <div className="player-profile font-semibold">
                                <table className="table w-full">
                                    {/* head */}
                                    <thead className="text-neutral-content">
                                        <tr>
                                            <th>SEASON</th>
                                            <th>
                                                <div className="ml-[-15px]">
                                                    TEAM
                                                </div>
                                            </th>
                                            <th className="text-center">
                                                LEAGUE
                                            </th>
                                            <th className="text-center">
                                                MINUTES
                                            </th>
                                            <th className="text-center">OFF</th>
                                            <th className="text-center">DEF</th>
                                            <th className="text-center">
                                                TOTAL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {playerDetails?.map(
                                            (history, index) => (
                                                <tr
                                                    key={index}
                                                    className={` ${
                                                        index !==
                                                        playerDetails?.length -
                                                            1
                                                            ? "border-b-[1.5px] border-[#F2F3F6] "
                                                            : ""
                                                    } text-base text-[#9299AA] font-medium `}
                                                >
                                                    <td className="">
                                                        <div className="ml-0.5">
                                                            {history?.season.split(
                                                                "-"
                                                            ).length !== 1
                                                                ? history.season.split(
                                                                      "-"
                                                                  )[0]
                                                                : history.season}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-[#22272F] font-semibold ">
                                                            {
                                                                history?.team_short_name
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div>
                                                            {
                                                                history?.league_code
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="text">
                                                            {history?.minutes}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="text-[#22272F] font-semibold rounded px-2 py-1 bg-[#F7F7F8] ">
                                                            {history?.x_gfar
                                                                ? history?.x_gfar >
                                                                  0
                                                                    ? "+" +
                                                                      history.x_gfar
                                                                    : history.x_gfar
                                                                : "0"}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className=" font-semibold rounded px-2 py-1 text-[#22272F] bg-[#F7F7F8] ">
                                                            {history?.x_gaar
                                                                ? history?.x_gaar >
                                                                  0
                                                                    ? "+" +
                                                                      history.x_gaar
                                                                    : history.x_gaar
                                                                : "0"}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div
                                                            className={` ${
                                                                history?.x_gdar >
                                                                0
                                                                    ? "text-[#02B059] bg-[#02B05923]"
                                                                    : history?.x_gdar <
                                                                      0
                                                                    ? "text-[#FA4265] bg-[#FA426523] "
                                                                    : "text-[#22272F] bg-[#F7F7F8]"
                                                            } px-2 py-1 font-semibold rounded  `}
                                                        >
                                                            {history?.x_gdar
                                                                ? history?.x_gdar >
                                                                  0
                                                                    ? "+" +
                                                                      history.x_gdar
                                                                    : history.x_gdar
                                                                : "0"}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* table for mobile */}

                    <div className="pt-4 md:hidden profile-table-mobile font-semibold">
                        <table className="table w-full">
                            {/* head */}
                            <thead className="text-neutral-content">
                                <tr>
                                    <th className="text-center">
                                        <div className="">S</div>
                                    </th>
                                    <th className="">
                                        <div className="">TEAM</div>
                                    </th>
                                    <th className="text-center">League</th>
                                    <th className="text-center">MIN</th>
                                    <th className="text-center">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playerDetails?.map((history, index) => (
                                    <tr
                                        key={index}
                                        className={` ${
                                            index !== playerDetails?.length - 1
                                                ? "border-b-[1.5px] border-[#F2F3F6] "
                                                : ""
                                        } text-base text-[#9299AA] font-medium `}
                                    >
                                        <td className="text-center">
                                            {history?.season.split("-")
                                                .length !== 1
                                                ? history.season.split("-")[0]
                                                : history.season}
                                        </td>
                                        <td>
                                            <div className="text-[#22272F] w-[130px] whitespace-normal leading-[16px] font-semibold ">
                                                {history?.team_short_name}
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div>{history?.league_code}</div>
                                        </td>
                                        <td className="text-center">
                                            <div className="text">
                                                {history?.minutes}
                                            </div>
                                        </td>
                                        <td className="">
                                            <div
                                                className={` flex ml-auto justify-center ${
                                                    history?.x_gdar > 0
                                                        ? "text-[#02B059] bg-[#02B05923]"
                                                        : history?.x_gdar < 0
                                                        ? "text-[#FA4265] bg-[#FA426523] "
                                                        : "text-[#22272F] bg-[#F7F7F8]"
                                                } px-1.5 py-0.5 font-semibold w-[45px] rounded  `}
                                            >
                                                {history?.x_gdar
                                                    ? history?.x_gdar > 0
                                                        ? "+" + history.x_gdar
                                                        : history.x_gdar
                                                    : "0"}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            </div>
        </div>
    );
};

export default History;
