import React, { useState, useEffect, useCallback, useContext } from "react";
import arrow from "../../Images/arrow.png";
import { DataContext } from "../../Context/DataProvider";

const PremierLeagueRanking = ({ data }) => {
    const {user} = useContext(DataContext);
  const [searchText, setSearchText] = useState("");
  const [highlightedData, setHighLightedData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [hlData, setHlData] = useState([]);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [highligh, setHighLight] = useState(false);
  const [leagueToggle, setLeagueToggle] = useState(false);

  // get all leagues
  const leagues = [];
  const uniquesLeague = {};

  data.forEach((item) => {
    if (!uniquesLeague[item?.league_code] && item?.league_code ) {
      uniquesLeague[item?.league_code] = true;
      leagues.push({ league: item?.league_code, name: item?.league });
    }
  });

  const [selectedLeague, setSelectedLeague] = useState(user?.league);
//   const [selectedLeague, setSelectedLeague] = useState(leagues[0]?.league);

  const premierLeagueData = data?.filter(
    (obj) => obj?.league_code === selectedLeague
  );
  const sortedData = premierLeagueData
    ?.concat(newData)
    .sort((a, b) => b?.spi - a?.spi);

  const handleSearch = useCallback(() => {
    if (searchText.length > 0) {
      setHlData([]);
      setNewData([]);
      setHighLightedData([]);
      // setSearchDropdown(true);
      const fData = [];
      const newData = [];
      sortedData?.filter((obj) => {
        if (
          obj?.team?.toLowerCase()?.includes(searchText.toLocaleLowerCase())
        ) {
          obj = { ...obj, highlighted: true };
          !highlightedData.includes(obj) && highlightedData.push(obj);
        } else {
          data?.filter((ob) => {
            if (
              ob?.team?.toLowerCase()?.includes(searchText.toLocaleLowerCase())
            ) {
              obj = { ...ob, highlighted: true };
              // console.log(ob);
              !newData.includes(ob) &&
                !highlightedData.includes(ob) &&
                newData.push(ob);
            }
          });
        }
      });
      if (newData.length === 0 && searchText) {
        setNotFound(true);
      }
      if (newData.length > 0 && searchText) {
        setNotFound(false);
      }
      setNewData(newData);
      setHighLightedData(fData);
    }
  }, [searchText]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchText);
    }, 400);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchText, handleSearch]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value.trim());
    // setPlayerName("");
    setSearchDropdown(true);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handleResetHighlight = () => {
    setSearchText("");
    setHighLight(false);
    setNotFound(false);
    setHlData([]);
    setNewData([]);
    setHighLightedData([]);
  };

  useEffect(() => {
    if (searchText === "") {
      setSearchDropdown(false);
      setHighLightedData([]);
      setNotFound(false);
      setNewData([]);
      setHlData([]);
      setHighLight(false);
    }
  }, [searchText]);

  const handleAddTeam = (team) => {
    setHlData([team]);
    setHighLight(true);
    setSelectedTeam(team?.team);
    setSearchText(team?.team);
    handleSearch();
    setSearchDropdown(false);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        // !e.target.closest(".league-dropdown") &&
        !e.target.closest(".league-btn")
      ) {
        setLeagueToggle(false);
      }
    });
  }, []);

  let content;
  if (highlightedData?.length > 0 && searchDropdown) {
    content = (
      <ul className=" player-list max-h-[120px]  overflow-y-scroll b w-full z-50 shadow-lg bg-base-100 p-2 rounded-box">
        {highlightedData?.map((player, index) => (
          <li key={index} onClick={() => handleAddTeam(player)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{player.team}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
  let newContent;
  if (newData.length > 0 && searchDropdown) {
    newContent = (
      <ul className=" player-list max-h-[120px]  overflow-y-scroll b w-full z-50 shadow-lg bg-base-100 p-2 rounded-box">
        {newData?.map((player, index) => (
          <li key={index} onClick={() => handleAddTeam(player)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{player.team}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="teams-ranking w-1/3">
      <div className="ranking-header flex justify-between items-center p-5 ">
        <div className="relative">
          <button
            onClick={() => setLeagueToggle(!leagueToggle)}
            className=" outline-none league-btn flex justify-between items-center gap-8"
          >
            <p className="text-[#22272f] text-base font-semibold ">
              {selectedLeague}
            </p>
            <img className="" src={arrow} alt="" />
          </button>

          {leagueToggle && leagues?.length > 0 && (
            <div className="absolute max-h-[250px] overflow-y-scroll league-dropdown min-w-[230px] bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
              {[...leagues]?.sort((a,b)=>a?.league.localeCompare(b?.league))?.map((item, index) => (
                <p
                  key={index}
                  onClick={() => {
                    setSelectedLeague(item?.league);
                  }}
                  className={`cursor-pointer ${
                    item?.league === selectedLeague ? " text-blue-600 " : ""
                  } p-1.5 text-sm min-[1920px]:text-sm hover:bg-[#F7F7F8] duration-300`}
                >
                  {item?.league} ({item?.name})
                </p>
              ))}
            </div>
          )}
        </div>

        <button
          disabled={searchText === "" || searchText === null}
          onClick={handleResetHighlight}
          className="btn-text"
        >
          Reset
        </button>
      </div>
      <div className="ranking-table p-2">
        <div className="">
          {sortedData?.length > 0 ? (
            <>
              <div className="w-full search-box relative text-sm mb-3">
                <input
                  type="text"
                  name=""
                  value={searchText}
                  onChange={handleSearchChange}
                  className="w-full input-border p-3 pl-10 rounded-lg outline-none"
                  placeholder="Search for a team"
                />

                {/* <button
            onClick={handleSearchTeam}
            className="absolute cursor-pointer top-0 bottom-0 right-0 text-base rounded-lg bg-primary text-base-100 text-[14px] px-4"
          >
            Search
          </button> */}
              </div>
              {notFound && (
                <div className="text-red-600 mb-3 ml-2 font-[600] ">
                  {" "}
                  No data found !!!{" "}
                </div>
              )}

              {content}
              {newContent}
              <table className="table w-full">
                <tbody>
                  {[...new Set(sortedData)]?.map((league, index) => (
                    <tr
                      className={`px-3 ${
                        (index + 1) % 2 !== 0 ? "bg-[#f2f3f6]" : ""
                      } ${
                        highlightedData.length > 0 &&
                        highligh &&
                        highlightedData.map(
                          (d) =>
                            d?.rank === league?.rank && " highlighted-border "
                        )
                      } ${
                        newData.length > 0 &&
                        newData.map(
                          (d) =>
                            d?.team === selectedTeam &&
                            league?.team === selectedTeam &&
                            highligh &&
                            " highlighted-border "
                        )
                      } 
                  ${
                    hlData?.length > 0 &&
                    hlData.map(
                      (d) =>
                        d?.rank === league?.rank &&
                        highligh &&
                        " highlighted-border "
                    )
                  }
                   `}
                      key={index}
                    >
                      <td
                        align="center"
                        className="bg-transparent rounded-l-lg team-position"
                      >
                        {index + 1}.
                      </td>
                      <td className="bg-transparent team-name">
                        <div className="whitespace-normal">{league?.team}</div>
                      </td>
                      <td className="bg-transparent league-name">
                        Previous: {league?.prev_rank}
                      </td>
                      <td className="bg-transparent rounded-r-lg team-rating">
                        {parseFloat(league?.spi).toFixed(1)}
                      </td>
                      {/* <td className="bg-transparent rounded-r-lg team-status">
                    <div className="px-2">
                      <h4>..</h4>
                      <img src={levelIcon} alt="icon" />
                    </div>
                  </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p className="font-medium pl-2 mt-3 text-red-600 ">
              No data found for this league!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PremierLeagueRanking;
