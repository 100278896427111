import React, { useEffect, useState } from "react";
import arrow from "../../../Images/arrow.png";

const Team = ({
  position,
  season,
  league,
  teamSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
}) => {
  const [teamToggle, setTeamToggle] = useState(false);
  const [teamItems, setTeamItems] = useState([]);

  useEffect(() => {
    if (finalData.length) {
      const teams = [];
      finalData.forEach((item) => {
        if (
          item.position === position &&
          item.season === season &&
          item.league === league
        ) {
          teams.push(item.team_short_name);
        }
      });
      const objData = new Set(teams);
      setTeamItems(Array.from(objData));

      const arrData = Array.from(new Set(teams));
      const teamFinalItems = arrData.filter((data) => data !== teamSelected);
      setTeamItems(arrData);
    }
  }, [finalData, position, season, league]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".team-dropdown") &&
        !e.target.closest(".team-btn")
      ) {
        setTeamToggle(false);
      }
    });
  }, []);

  const setData = (team) => {
    let filterNeeded;

    filterNeeded = finalData?.filter(
      (data) =>
        data?.position === position &&
        data?.season === season &&
        data?.league === league &&
        data?.team_short_name === team
    );

    if (filterNeeded.length) {
      const newestSeasonObj = filterNeeded?.find((data) =>
        Math.max.apply(data.season)
      );

      const arrByLatestSeason = filterNeeded?.filter(
        (data) => data?.season === newestSeasonObj?.season
      );
      const arrByMaxMins = arrByLatestSeason?.filter((data) =>
        Math.max.apply(data?.minutes)
      );
      const player = arrByMaxMins[0];
      setPlayerInfo(player);
    }
  };
  return (
    <div className="relative mt-3">
      <button
        onClick={() => setTeamToggle(!teamToggle)}
        className="w-full input-border p-3 rounded-lg outline-none text-sm team-btn flex justify-between items-center bg-base-100"
      >
        <p>{teamSelected === undefined ? "team" : teamSelected}</p>
        <img src={arrow} alt="" />
      </button>

      {teamToggle && teamItems.length > 0 && (
        <div className="absolute max-h-[250px] overflow-y-scroll team-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
          {teamItems?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setTeamSelected(item);
                setTeamToggle(false);
                setFirstClick(false);
                setData(item);
              }}
              className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Team;
