import React, { useState } from "react";
import "./CompletedMatchesModal.css";
import SingleList from "./SingleList";
import Pagination from "../../../../Components/Pagination/Pagination";


const CompletedMatchesModal = ({completedMatches}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = completedMatches?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(
    completedMatches?.length > 0 && completedMatches?.length / ITEMS_PER_PAGE
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  
  };
  return (
    <>
      <input type="checkbox" id="bundeshliga-modal" className="modal-toggle" />
      <div className="modal">
        <div className="relative modal-box w-11/12 max-w-[650px] px-0 bundesliga-modal ">
          <div className="flex items-center modal-header">
            <p className=" absolute left-6 top-4 md:top-3">Completed matches</p>
            <label
              htmlFor="bundeshliga-modal"
              className="btn btn-sm absolute text-[#22272F] right-6 top-3 modal-close-btn"
            >
              Close
            </label>
          </div>

          {currentItems.map((item,index) => (
            <SingleList currentItems={currentItems} item={item} key={index} />
          ))}
          {/* <ContentMiddle /> */}
          {/* <BundesligaLIst/> */}
          <div className="flex justify-between pagination mt-5 px-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>

          {/* <div className="flex justify-between pagination px-7  mt-5">
            <div className="polygon-box">
              <img src={polygon} alt="polygon" />
            </div>
            <div className="pagination flex">
              <div className="pagination-box-selected">1</div>
              <div className="pagination-box">2</div>
              <div className="pagination-box">3</div>
              <div className="pagination-box">...</div>
              <div className="pagination-box">8</div>
              <div className="pagination-box">9</div>
              <div className="pagination-box">10</div>
            </div>
            <div className="polygon-box">
              <img src={polygon2} alt="polygon" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CompletedMatchesModal;
