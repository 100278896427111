import React, { useContext, useState } from "react";
import "./Modal.css";
import UEFATable from "./UpcomingMatchesTable";
import polygon from "../../../../Images/polygon.png";
import polygon2 from "../../../../Images/polygon-2.png";
// import Pagination from "../../../../customFunctions/pagination";
import team1 from "../../../../Images/team-logo-1.png";
import team2 from "../../../../Images/team-logo-2.png";
import { formateDate } from "../../../../customFunctions/formateDate";
import Pagination from "../../../../Components/Pagination/Pagination";
import { DataContext } from "../../../../Context/DataProvider";

const UpcomingMatchesModal = ({ upcomingMatches }) => {
  const { base_url } = useContext(DataContext);

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = upcomingMatches?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(
    upcomingMatches?.length > 0 && upcomingMatches?.length / ITEMS_PER_PAGE
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function findHighLowMiddle(value, ...values) {
    values.push(value); // Add the initial value to the array
    values.sort((a, b) => a - b); // Sort the values in ascending order

    if (value === values[0]) {
      return "low";
    } else if (value === values[values.length - 1]) {
      return "high";
    }
  }

  function formateDate(dateStr) {
    const date = new Date(dateStr);

    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();

    const formattedDate = `${month} ${day}`;
    return formattedDate;
  }

  return (
    <>
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <div className="modal ">
        <div className="relative modal-box w-11/12 max-w-2xl px-0 uefa-modal">
          <div className="flex items-center">
            <p className=" absolute left-6 top-4 md:top-3 ">Upcoming matches</p>
            <label
              htmlFor="my-modal"
              className="btn btn-sm absolute text-[#22272F] right-6 top-3 modal-close-btn "
            >
              Close
            </label>
          </div>

          <div className="overflow-x-auto hidden md:block mt-8 uefa-list list">
            <table className="table table-normal w-full data-table">
              {/* head */}

              <tbody className="" >
                {currentItems.map((match,index) => (
                  <tr key={index} className="list">
                    <td className="time mr-2 ">
                      <div className="ml-5 leading-5 ">
                        {match.date ? formateDate(match.date) : "--"} <br />
                        {match.time ? match.time : "--"}
                      </div>
                    </td>
                    <td className="flex mt-1.5 ml-3 w-[260px] border-b-0 ">
                      <div className="flex w-[80%] items-center">
                        <img
                          className="mr-2 w-9"
                          src={`${base_url}/api/v1/team/image/${match.home_team}`}
                          alt="team1"
                        />
                        <span className="team whitespace-pre-wrap">
                          {match?.home_team_short}
                        </span>
                      </div>
                    </td>
                    <td className="w-[150px]">
                      <div className="flex ml-[-40px] justify-between items-center mr-3 ">
                        <span
                          className={`w-[30%] rate ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "high" && "rate-1"
                          } ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "medium" && "rate-2"
                          } ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "low" && "rate-3"
                          } text-center`}
                        >
                          {isNaN(Math.round(match?.prob1 * 100)) ? (
                            <div className="ml-2">--</div>
                          ) : (
                            Math.round(match?.prob1 * 100) + "%"
                          )}
                        </span>
                        <span
                          className={`w-[30%] rate rate-2 text-center`}
                        >
                          {isNaN(Math.round(match?.prob_draw * 100)) ? (
                            <div className="ml-2">--</div>
                          ) : (
                            Math.round(match?.prob_draw * 100) + "%"
                          )}
                        </span>
                        <span
                          className={`w-[30%] rate ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "high" && "rate-1"
                          } ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "medium" && "rate-2"
                          } ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "low" && "rate-3"
                          } text-center`}
                        >
                          {isNaN(Math.round(match?.prob2 * 100)) ? (
                            <div className="ml-2">--</div>
                          ) : (
                            Math.round(match?.prob2 * 100) + "%"
                          )}
                        </span>
                      </div>
                    </td>
                    <td className=" mt-3 w-[260px] border-b-0">
                      <div className="flex justify-end gap-2 items-center ">
                        <span className="team text-end whitespace-pre-wrap">
                          {match?.away_team_short}
                        </span>
                        <img
                          className="mr-2 w-9"
                          src={`${base_url}/api/v1/team/image/${match.away_team}`}
                          alt="team2"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              
            </table>
          </div>

          {/* for mobile */}
          <div className=" block md:hidden mt-8 ">
            <table className="table table-upcoming-matches-mobile w-full data-table">
              {/* head */}

              <tbody className="" >
                {currentItems.map((match,index) => (
                  <tr key={index} className="list">
                    <td className="time ">
                      <div className="leading-3 text-xs text-center ">
                        {match.date ? formateDate(match.date) : "--"} <br />
                        {match.time ? match.time : "--"}
                      </div>
                    </td>
                    <td className=" border-b-0 ">
                      <div className="flex gap-1 items-center">
                        <img
                          className=" w-5"
                          src={`${base_url}/api/v1/team/image/${match.home_team}`}
                          alt="team1"
                        />
                        <span className="team whitespace-pre-wrap">
                          {match?.home_team_code}
                        </span>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex justify-between ml-[2.8%] items-center ">
                        <span
                          className={`w-[30%] rate ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "high" && "rate-1"
                          } ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "medium" && "rate-2"
                          } ${
                            findHighLowMiddle(
                              match?.prob1 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "low" && "rate-3"
                          } text-center`}
                        >
                          {isNaN(Math.round(match?.prob1 * 100)) ? (
                            <div className="ml-2">--</div>
                          ) : (
                            Math.round(match?.prob1 * 100) + "%"
                          )}
                        </span>
                        <span
                          className={`w-[30%] rate rate-2 text-center`}
                        >
                          {isNaN(Math.round(match?.prob_draw * 100)) ? (
                            <div className="ml-2">--</div>
                          ) : (
                            Math.round(match?.prob_draw * 100) + "%"
                          )}
                        </span>
                        <span
                          className={`w-[30%] rate ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "high" && "rate-1"
                          } ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "medium" && "rate-2"
                          } ${
                            findHighLowMiddle(
                              match?.prob2 * 100,
                              match?.prob1 * 100,
                              match?.prob2 * 100
                            ) === "low" && "rate-3"
                          } text-center`}
                        >
                          {isNaN(Math.round(match?.prob2 * 100)) ? (
                            <div className="">--</div>
                          ) : (
                            Math.round(match?.prob2 * 100) + "%"
                          )}
                        </span>
                      </div>
                    </td>
                    <td className=" border-b-0">
                      <div className="flex justify-end pr-2 gap-1 items-center ">
                        <span className="team text-end whitespace-pre-wrap">
                          {match?.away_team_code}
                        </span>
                        <img
                          className=" w-5"
                          src={`${base_url}/api/v1/team/image/${match.away_team}`}
                          alt="team2"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              
            </table>
          </div>
          <div className="flex justify-between pagination mt-5 px-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingMatchesModal;
