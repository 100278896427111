import React, { useContext } from "react";
import { DataContext } from "../../../Context/DataProvider";

const DeleteTeamModal = ({team, index}) => {
  const {
    teams,
    setTeams,
    base_url,
    user,
    token
  } = useContext(DataContext);
console.log(team);
  const handleDeleteTeam=(id)=>{
    fetch(`${base_url}/api/v1/myteam/1`, {
      method: "Delete",
      body: JSON.stringify({
        user_id: user?.id,
        id: id,
               
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json) {
          
          const filteredTeams = teams?.filter(t=>t.id!==id);

          setTeams(filteredTeams);
         
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  }
  return (
    <div>
      <input type="checkbox" id={`delete-team-modal${index}`} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box create-team-modal p-0">
          <div className="create-team-modal-header text-center p-5">
            <h3 className="font-bold text-lg">Are you sure you want to delete your team? {team?.name} </h3>
          </div>
          
          <div className="modal-action flex justify-center pb-5 ">
            <label htmlFor={`delete-team-modal${index}`} className="cancel-btn">
              Cancel
            </label>
            <label onClick={()=>handleDeleteTeam(team?.id)} htmlFor={`delete-team-modal${index}`} className="confirm-btn">
              Confirm
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTeamModal;
