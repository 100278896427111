import React, { useContext, useState } from "react";
import { DataContext } from "../../../Context/DataProvider";

const DeleteModal = ({ modalName, data, setData, player }) => {
  const { base_url, token, coachShortListData, setCoachShortListData, user } = useContext(DataContext);
  
  const handleDelete = (id) => {
    
    fetch(`${base_url}/api/v1/coach/shortlist/${id}`, {
      method: "DELETE",
      body: JSON.stringify({
        user_id: user?.id,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          const remainingPlayers = data?.filter((obj) => obj?.id !== id);
          const targetedPlayer = data?.find((obj) => obj?.id === id);
          const filteredPlayer = coachShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
          setData(remainingPlayers);
          setCoachShortListData(filteredPlayer);
        }
      })
      .catch((error)=>{
        alert("server error")
      })
      
  };
  return (
    <div>
      <input
        type="checkbox"
        id={modalName}
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box create-team-modal p-0">
          <div className="create-team-modal-header text-center p-5">
            <h3 className="font-bold text-lg">
              Are you sure you want to remove this player?
            </h3>
          </div>

          <div className="modal-action flex justify-center pb-5 ">
            <label htmlFor={modalName} className="cancel-btn">
              Cancel
            </label>
            <label
              htmlFor={modalName}
              onClick={() => handleDelete(player?.id)}
              className="confirm-btn"
            >
              Confirm
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
