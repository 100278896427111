import { RouterProvider } from "react-router-dom";
import { router } from "./Router/Router";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  // const client = new QueryClient();
  return (
    <div>
      {/* <QueryClientProvider client={client} > */}
        <RouterProvider router={router}></RouterProvider>
      {/* </QueryClientProvider> */}
    </div>
  );
}

export default App;
