import React, { useContext, useEffect, useState, useCallback } from "react";
import "../Metric/Metric.css";
import loader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";
import Positions from "./Dropdown/Positions";
import { BiSearch } from "react-icons/bi";
import Leagues from "./Dropdown/Leagues";
import Seasons from "./Dropdown/Seasons";
import Teams from "./Dropdown/Teams";
import HistoryTable from "./HistoryTable";
import teamsData from "../../teams.json";

// const positions = [
//   { group: "GOALKEEPERS" },
//   { group: "DEFENDERS", subgroup: ["gruop1"] },
//   { group: "MIDFIELDERS", subgroup: ["group2"] },
//   { group: "ATTACKERS", subgroup: ["group1"] },
// ];

const positions = ["GOALKEEPERS", "DEFENDERS", "MIDFIELDERS", "ATTACKERS"];

const ClubSearch2 = () => {
  const { base_url, token } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [playerHistory, setPlayerHistory] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState("Select League");
  const [selectedSeason, setSelectedSeason] = useState("Select Season");

  const [totalPage, setTotalPage] = useState(null);
  const [page, setPage] = useState(1);
  const [links, setLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [activePositions, setActivePositions] = useState(["LCB"]);

  const [lastPage, setLastPage] = useState(null);

  const [searchedText, setSearchedText] = useState("");
  const [players, setPlayers] = useState([]);
  const [playerName, setPlayerName] = useState("");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [leagues, setLeagues] = useState(null);
  const [seasons, setSeasons] = useState(null);
  const [position, setPosition] = useState("GOALKEEPERS");
  const [finalData, setFinalData] = useState([]);
  const [min1, setMin1] = useState("300");
  const [min2, setMin2] = useState("");


  useEffect(() => {
    if (callApi) {
      setLoading(true);
      fetch(
        `${base_url}/api/v1/player/clubsearch/result?team_short_name=${playerName}&league=${
          selectedLeague === "Select League" ? "" : selectedLeague
        }&season=${
          selectedSeason === "Select Season" ? "" : selectedSeason
        }&min1=${Number(min1)}&min2=${Number(min2)}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setPlayerHistory((prev) => {
              return prev?.concat(data);
            });
            setLinks(data?.links);
            setTotalPage(data?.total);
            setLastPage(data?.last_page);
            setLoading(false);
            setCallApi(false);
          }

          if (data?.length === 0) {
            setLoading(false);
            setNotFound(true);
          }
        });
    }
  }, [selectedLeague, selectedSeason, callApi]);

  const handleFilterApply = () => {
    setPlayerHistory([]);
    setPosition("GOALKEEPERS");
    setCallApi(true);
  };

  const handleResetFilter = () => {
    setPlayerHistory([]);
    setPlayerName("");
    setSearchedText("");
    setLeagues([]);
    setSeasons([]);
    setSelectedLeague("Select League");
    setSelectedSeason("Select Season");
    setMin1("300");
    setMin2("");
    setPlayers(null);
    setNotFound(false);
  };

  const handlePlayerDetail = (player) => {
    setLeagues([]);
    setSeasons([]);
    setMin1("300");
    setMin2("");
    setPlayerName(player.team_short_name);
    setSearchedText(player.team_short_name);
    setSearchDropdown(false);
  };

  useEffect(() => {
    const uniqueSeasons = new Set();

    players?.length > 0 &&
      players.forEach((item) => {
        uniqueSeasons.add(item.season);
      });

    const uniqueSeasonsArray = Array.from(uniqueSeasons);
    setSeasons(uniqueSeasonsArray);
  }, [players]);

  useEffect(() => {
    const uniqueLeagueCodes = new Set();

    players?.length > 0 &&
      players.forEach((item) => {
        if (item?.season === selectedSeason) {
          uniqueLeagueCodes.add(item.league_code);
        }
      });

    const uniqueLeagueCodesArray = Array.from(uniqueLeagueCodes);

    setLeagues(uniqueLeagueCodesArray);
  }, [players, selectedSeason]);

  const handleSearch = useCallback(() => {
    setPlayers(null);
    
    if (searchedText !== "") {
      const filteredData = teamsData?.filter((team) =>
        team?.team_short_name
          ?.toLocaleLowerCase()
          ?.includes(searchedText.trim().toLocaleLowerCase())
      );
      setSearchLoading(true);
      setPlayers(filteredData);
      setSearchLoading(false);
      setNotFound(false);
    }
  }, [searchedText]);

  useEffect(() => {
    if (searchedText !== "" && playerName !== "") {
      fetch(`${base_url}/api/v1/player/team/search?keywords=${searchedText}`, {
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors === "not found") {
            // setNotFound(true);
            setSearchLoading(false);
            setPlayers([]);
          } else {
            setPlayers(data);
            setSearchLoading(false);
            setNotFound(false);
          }
        });
    } else {
      setSearchLoading(false);
    }
  }, [searchedText, playerName]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchedText);
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchedText, handleSearch]);

  useEffect(() => {
    if (searchedText === "") {
      setPlayers(null);
    }
  }, [searchedText]);

  const uniqueTeamShortNames = new Set();
  const uniqueArray =
    players?.length > 0 &&
    players.filter((item) => {
      if (!uniqueTeamShortNames.has(item.team_short_name)) {
        uniqueTeamShortNames.add(item.team_short_name);
        return true;
      }
      return false;
    });

  useEffect(() => {
    let result;
    if (position === "GOALKEEPERS") {
      const res = playerHistory?.filter((player) => player?.position === "GK");
      result = res.sort((a, b) => b.minutes - a.minutes);
    } else if (position === "DEFENDERS") {
      const res = playerHistory?.filter((player) =>
        ["LCB", "RCB", "CCB", "LB", "RB"].includes(player.position)
      );

      const centerBacks = res?.filter((player) =>
        ["LCB", "RCB", "CCB"].includes(player.position)
      );
      centerBacks.sort((a, b) => b.minutes - a.minutes);

      const fullBacks = res?.filter((player) =>
        ["LB", "RB"].includes(player.position)
      );
      fullBacks.sort((a, b) => b.minutes - a.minutes);
      result = centerBacks.concat(fullBacks);
    } else if (position === "MIDFIELDERS") {
      const res = playerHistory?.filter((player) =>
        ["DM", "CM", "LM/LWB", "RM/RWB", "RB"].includes(player.position)
      );

      const group1 = res?.filter((player) => ["DM"].includes(player.position));
      group1.sort((a, b) => b.minutes - a.minutes);

      const group2 = res?.filter((player) => ["CM"].includes(player.position));
      group2.sort((a, b) => b.minutes - a.minutes);

      const group3 = res?.filter((player) =>
        ["LM/LWB", "RM/RWB"].includes(player.position)
      );
      group3.sort((a, b) => b.minutes - a.minutes);

      result = group1.concat(group2).concat(group3);
    } else if (position === "ATTACKERS") {
      const res = playerHistory?.filter((player) =>
        ["CAM/SS", "LW", "RW", "CF/ST"].includes(player.position)
      );

      const group1 = res?.filter((player) =>
        ["CAM/SS"].includes(player.position)
      );
      group1.sort((a, b) => b.minutes - a.minutes);

      const group2 = res?.filter((player) =>
        ["LW", "RW"].includes(player.position)
      );
      group2.sort((a, b) => b.minutes - a.minutes);

      const group3 = res?.filter((player) =>
        ["CF/ST"].includes(player.position)
      );
      group3.sort((a, b) => b.minutes - a.minutes);

      result = group1.concat(group2).concat(group3);
    }

    setFinalData(result);
  }, [position, playerHistory]);

  useEffect(() => {
    if (seasons?.length > 0) {
      setSelectedSeason(seasons[0]);
    }
  }, [seasons]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchedText(value);
    setPlayerName("");
    setSearchDropdown(true);
  };

  let content;
  if (
    searchLoading &&
    playerName === "" &&
    players === null &&
    searchedText.length > 0
  ) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (players?.length === 0 && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }

  if (
    players?.length > 0 &&
    !searchLoading &&
    players !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <ul className=" max-h-[300px] overflow-y-scroll b z-50 shadow-lg bg-base-100 p-2 rounded-box">
        <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">Teams</h6>
        {uniqueArray?.map((player, index) => (
          <li key={index} onClick={() => handlePlayerDetail(player)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{player.team_short_name}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="pt-10 min-h-[90vh] text-neutral">
      <div className="metric flex">
        {/* Sidebar */}
        <div className="metric_sidebar h-[390px] bg-base-100 rounded-lg">
          {/*  Header */}
          <div className="px-6 flex justify-between min-[1920px]:w-[250px] items-center py-4 border-b">
            <h6 className="font-semibold 2xl:text-base">Filters</h6>
            <div className="flex gap-2 ">
              <button
                disabled={playerName === ""}
                onClick={() => handleResetFilter()}
                className={`px-2 py-1 flex ${
                  playerName === ""
                    ? "bg-transparent border"
                    : "border font-[600]"
                } justify-center items-center  text-black rounded  text-xs`}
              >
                Reset
              </button>
              <button
                disabled={playerName === ""}
                onClick={() => handleFilterApply()}
                className={`px-2 py-1 flex ${
                  playerName === ""
                    ? "bg-transparent text-black border"
                    : "bg-primary text-white"
                } justify-center items-center rounded   text-xs`}
              >
                Apply
              </button>
            </div>
          </div>

          <div className="p-2.5 2xl:p-4 ">
            {/* PLAYER DETAILS LEFT SIDEBAR TOP */}
            <div className="mt-2">
              <div className="w-full search-box relative text-sm mb-4 lg:mb-0">
                <input
                  type="text"
                  name=""
                  value={searchedText}
                  onChange={handleSearchChange}
                  className="w-full input-border p-3 pl-10 rounded-lg outline-none"
                  placeholder="Search for a player"
                />

                <div className="absolute top-4 left-4 text-lg">
                  <BiSearch />
                </div>
              </div>

              {content}

              <Seasons
                selectedSeason={selectedSeason}
                setSelectedSeason={setSelectedSeason}
                setPage={setPage}
                allSeasons={seasons}
                playerName={playerName}
              />

              <Leagues
                selectedLeague={selectedLeague}
                setSelectedLeague={setSelectedLeague}
                setSelectedSeason={setSelectedSeason}
                allLeagues={leagues}
                players={players}
                playerName={playerName}
                setSeasons={setSeasons}
              />

              <div className="mt-3">
                <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                  Minutes
                </h6>

                <div className="flex gap-4 justify-between mt-1.5">
                  <div className="w-1/2">
                    <input
                      onChange={(e) => setMin1(e.target.value)}
                      value={min1}
                      type="text"
                      className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                      placeholder="Enter Value"
                    />
                  </div>

                  <div className="flex justify-center items-center text-neutral-content font-medium">
                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                  </div>

                  <div className="w-1/2">
                    <input
                      onChange={(e) => setMin2(e.target.value)}
                      value={min2}
                      type="text"
                      className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                      placeholder="Enter Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="metric_main_content">
          {/* Table */}
          {playerHistory?.length > 0 && (
            <>
              <div className="mb-8">
                <h6 className="font-semibold">Groups</h6>
                <div className="mt-2 flex gap-2 text-[13px] 2xl:text-sm font-medium">
                  {positions.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setPosition(item);
                        setCurrentPage(1);
                      }}
                      className={`${
                        position === item && "active-position"
                      } px-4 py-2.5 rounded bg-base-100 border`}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
              <HistoryTable
                position={position}
                history={finalData}
                setFinalData={setFinalData}
                setPlayerHistory={setPlayerHistory}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
          {loading && page === 1 && (
            <div className={` ${"mt-[20%]"} flex justify-center items-center`}>
              <img width={150} src={loader} alt="loading..." />
            </div>
          )}

          {!loading &&
            notFound &&
            activePositions.length !== 0 &&
            playerHistory.length === 0 && (
              <div className="mt-[10px] ml-3 font-bold text-red-600 ">
                Result not found!
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ClubSearch2;
