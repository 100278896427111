import React, { useContext, useEffect, useState } from "react";
import FilterArea from "./FilterArea";
import { makePosition } from "../../customFunctions/makePosition";
import plus from "../../Images/plus.png";
import minus from "../../Images/minus.png";
import { BiSearch } from "react-icons/bi";
import { debounce } from "lodash";
import playersData from "../../players.json";
import contentLoader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";
import { Link, useNavigate, useParams } from "react-router-dom";
import infoIcon from "../../Images/info-icon.png";
import RobustModel from "./Modals/RobustModel";
import PositionModal from "./Modals/PositionModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import WorldRankingModal from "./Modals/WorldRankingModal";
import History from "./Modals/History";
import InjuryHistory from "./Modals/InjuryHistory";

const PlayerProfile3 = () => {
    const [searchedText, setSearchedText] = useState("");
    const [players, setPlayers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchDropdown, setSearchDropdown] = useState(false);
    const [mainContent, setMainContent] = useState(false);
    const [playerInfo, setPlayerInfo] = useState({});
    const [playerName, setPlayerName] = useState("");
    const [infoLoading, setInfoLoading] = useState(false);
    const [positionSelected, setPositionSelected] = useState("");
    const [leagueSelected, setLeagueSelected] = useState("");
    const [seasonSelected, setSeasonSelected] = useState("");
    const [teamSelected, setTeamSelected] = useState("");
    const [contentLoading, setContentLoading] = useState(false);
    const [playerAllDetails, setPlayerAllDetails] = useState({});
    const [finalData, setFinalData] = useState([]);
    const [latestSeason, setLatestSeason] = useState("");
    const [firstClick, setFirstClick] = useState(false);
    const [playerHistory, setPlayerHistory] = useState([]);

    const [fixHeight, setFixHeight] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [sortedDataViaPosition, setSortedDataViaPosition] = useState([]);
    const [playerApiCall, setPlayerApiCall] = useState(false);

    const [performanceSwitch, setPerformanceSwitch] = useState("performance");
    const [physicalSwitch, setPhysicalSwitch] = useState("physical");


    const { base_url, token } = useContext(DataContext);

    const navigate = useNavigate();

    const { name_dob } = useParams();

    let selectPosition;
    if (positionSelected === "position") {
        selectPosition = "";
    } else {
        selectPosition = positionSelected;
    }

    

    // getting all data when a player is searched and clicked
    useEffect(() => {
        if ((playerName !== "" || name_dob !== "") && playerApiCall) {
            setContentLoading(true);
            fetch(`${base_url}/api/v1/player/details?name_dob=${name_dob}`, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setPlayerAllDetails(data);
                    setPlayerApiCall(false);
                    setLoading(false);
                    setContentLoading(false);
                });
        }
    }, [name_dob, playerApiCall, playerName]);

    useEffect(() => {
        if (name_dob !== "") {
            handlePlayerDetail(name_dob);
        }
    }, [name_dob]);

    // making sorted api data to an array
    useEffect(() => {
        if (playerAllDetails !== undefined && playerName !== "") {
            const result = Object.values(playerAllDetails);

            const newData = [];
            result.length &&
                result.map((data) => data.map((item) => newData.push(item)));

            if (newData.length) {
                setFinalData(newData);
            }
        }
    }, [playerAllDetails, playerName]);

    // getting player info by latest season and then getting latest minute data and setup into selected position,season,league,team
    useEffect(() => {
        setInfoLoading(true);

        if (finalData.length && firstClick) {
            if (finalData.length) {
                const newestSeasonObj = finalData?.find((data) =>
                    Math.max.apply(data.season)
                );

                setLatestSeason(newestSeasonObj?.season);
                if (latestSeason !== "") {
                    const arrByLatestSeason = finalData?.filter(
                        (data) => data?.season === latestSeason
                    );

                    let maxObjectByMInutes = null;
                    let maxValue = Number.NEGATIVE_INFINITY;

                    for (const obj of arrByLatestSeason) {
                        if (Number(obj.minutes) > maxValue) {
                            maxObjectByMInutes = obj;
                            maxValue = Number(obj.minutes);
                        }
                    }
                    const player = maxObjectByMInutes;

                    setPlayerInfo(player);
                    setPositionSelected(player?.position);
                    setSeasonSelected(player?.season);
                    setLeagueSelected(player?.league);
                    setTeamSelected(player?.team_short_name);
                }
            }
        }
        setInfoLoading(false);
    }, [finalData, latestSeason, firstClick]);

    // get data for history
    useEffect(() => {
        if (finalData.length) {
            const arrByPosition = finalData?.filter(
                (data) => data?.position === selectPosition
            );

            setPlayerHistory(arrByPosition);
        }
    }, [finalData, selectPosition]);

    useEffect(() => {
        if (searchedText !== "") {
            setLoading(true);

            const searchWords = searchedText.trim().split(/\s+/);
            const filteredResults = playersData.filter((player) => {
                const { name_team } = player;
                const lowerCaseName = name_team.toLocaleLowerCase();

                return searchWords.every((word) =>
                    lowerCaseName.includes(word.toLowerCase())
                );
            });

            setPlayers(filteredResults.slice(0, 10));
            setLoading(false);
            setNotFound(false);
        } else {
            setLoading(false);
        }
    }, [searchedText]);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (
                !e.target.closest(".player-list") &&
                !e.target.closest(".search-box")
            ) {
                setSearchDropdown(false);
            }
        });
    }, []);

    const handlePlayerDetail = (name) => {
        setPlayerApiCall(true);
        setPlayerName(name);
        setContentLoading(true);
        setSearchedText("");
        setSearchDropdown(false);
        setMainContent(true);
        setPositionSelected("position");
        setLeagueSelected("league");
        setSeasonSelected("season");
        setTeamSelected("team");
        setFirstClick(true);
        setFixHeight(false);

        // navigate();
    };

    useEffect(() => {
        if (finalData.length > 0) {
            const result = [...finalData].sort((a, b) => {
                if (
                    Number(a.career_games_in_position) >
                    Number(b.career_games_in_position)
                ) {
                    return -1;
                }
                if (
                    Number(a.career_games_in_position) <
                    Number(b.career_games_in_position)
                ) {
                    return 1;
                }
                return 0;
            });
            const uniqueArray = [];
            const positions = [];

            result.forEach((obj) => {
                if (!positions.includes(obj.position)) {
                    positions.push(obj.position);
                    uniqueArray.push(obj);
                }
            });
            setSortedDataViaPosition(uniqueArray?.slice(0, 6));
        }
    }, [finalData]);

    useEffect(() => {
        if (searchedText === "") {
            setPlayers(null);
        }
    }, [searchedText]);

    const [robustData, setRobustData] = useState([]);
    useEffect(() => {
        const totals = finalData?.reduce(
            (acc, { season, team, league_code, minutes }) => {
                acc[season] = acc[season] || {};
                acc[season][team] = acc[season][team] || {};
                acc[season][team][league_code] = acc[season][team][
                    league_code
                ] || {
                    totalMinutes: 0,
                };
                acc[season][team][league_code].totalMinutes += minutes;
                return acc;
            },
            {}
        );

        // Convert the totals object into an array of objects
        const totalsArray = Object.entries(totals).map(([season, teams]) => ({
            season,
            teams: Object.entries(teams).map(([team, league_codes]) => ({
                team,
                league_codes: Object.entries(league_codes).map(
                    ([league_code, { totalMinutes }]) => ({
                        league_code,
                        totalMinutes,
                    })
                ),
            })),
        }));

        // Sort the totalsArray by season, from latest to lowest
        const finalprocessdata = totalsArray.sort((a, b) =>
            a.season < b.season ? 1 : -1
        );
        setRobustData(finalprocessdata);
    }, [finalData, seasonSelected]);

    if (contentLoading) {
        return (
            <div
                id="preloader"
                className="d-flex align-items-center justify-content-center"
            >
                <span className="text-center">
                    <img
                        className="mx-auto d-block"
                        width="300"
                        alt="loader"
                        src={contentLoader}
                    />
                </span>
            </div>
        );
    }

    const handleNavigateProfile = () => {
        setContentLoading(true);
        setPlayerAllDetails([]);
    };

    const text = (e) => {
        setSearchedText(e.target.value);
        setSearchDropdown(true);
    };
    const debounceOnChange = debounce(text, 400);

    let content;
    if (loading && players === null && searchedText.length > 0) {
        content = <progress className="progress loading w-56"></progress>;
    }

    if (players?.length === 0 && searchedText.length > 0) {
        content = <p className="not-found">No data found !</p>;
    }

    if (contentLoading) {
        <div id="preloader" className="flex items-center justify-center">
            <div className="flex loader-box justify-center items-center ">
                <span className="text-center">
                    <img
                        className="mx-auto d-block"
                        alt="loader"
                        width="50"
                        src={contentLoader}
                    />
                </span>
            </div>
        </div>;
    }

    if (
        players?.length > 0 &&
        !loading &&
        players !== null &&
        searchedText.length > 0 &&
        searchDropdown === true
    ) {
        content = (
            <ul className=" w-[84%] md:w-[28.5%] min-[1920px]:w-[20.8%] absolute mt-[0px] md:mt-[50px] border rounded-[15px]  max-h-[350px] overflow-y-scroll z-50 shadow-lg bg-base-100 p-2 ">
                <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">
                    PLAYERS
                </h6>
                {players?.map((player, index) => (
                    <li
                        key={index}
                        onClick={() => handlePlayerDetail(player.name_dob)}
                    >
                        <div className="p-3 pt-2">
                            <div
                                onClick={handleNavigateProfile}
                                className="text-sm cursor-pointer"
                            >
                                <Link to={`/profile/${player.name_dob}`}>
                                    <h6 className="text-semibold">
                                        {player.name_dob}
                                    </h6>
                                    <p className="text-neutral-content text-medium">
                                        {player.age} yr • {player.team}
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    // const getPerformanceValue = (value) => {
    //     if (value <= 19 && value >= 0) {
    //         return "Poor";
    //     } else if (value <= 39 && value >= 20) {
    //         return "Fair";
    //     } else if (value <= 59 && value >= 40) {
    //         return "Average";
    //     } else if (value <= 79 && value >= 60) {
    //         return "Good";
    //     } else if (value >= 80) {
    //         return "Elite";
    //     } else {
    //         return "unknown";
    //     }
    // };

    const getPerformanceValue = (value) => {
        if (value <= 20) {
            return "Very Poor";
        } else if (value <= 30 && value >= 21) {
            return "Poor";
        } else if (value <= 40 && value >= 31) {
            return "Fair";
        } else if (value <= 59 && value >= 41) {
            return "Average";
        } else if (value <= 70 && value >= 60) {
            return "Good";
        } else if (value <= 80 && value >= 71) {
            return "Very Good";
        } else if (value >= 81) {
            return "Elite";
        } else {
            return "unknown";
        }
    };

    const GetPerFormanceColor = (value) => {
        if (value <= 30 && value >= 0) {
            return "text-[#fa4265] bg-[#fa426514]";
        } else if (value <= 59 && value >= 31) {
            return "text-[#F29445] bg-[#F2944514]";
        } else if (value <= 79 && value >= 60) {
            return "text-[#02b059] bg-[#02b05914]";
        } else if (value >= 80) {
            return "text-[#02b059] bg-[#02b05914]";
        }else{
            return "bg-gray-100";
        }
    };
    // const GetPerFormanceColor = (value) => {
    //     if (value <= 29 && value >= 15) {
    //         return "text-[#fa4265] bg-[#fa426514]";
    //     }else if (value <= 44 && value >= 30) {
    //         return "text-[#F29445] bg-[#F2944514]";
    //     } else if (value >= 45 && value <= 59) {
    //         return "text-[#F29445] bg-[#F2944514]";
    //     }else if (value <= 74 && value >= 60) {
    //         return "text-[#02b059] bg-[#02b05914]";
    //     } else if (value >= 75) {
    //         return "text-[#02b059] bg-[#02b05914]";
    //     } else if (value <= 14 && value >= 0) {
    //         return "bg-[#f7f7f8] text-[#22272F]";
    //     }
    // };

    const getWorldRank = (world_rank) => {
        if (world_rank <= 64.9 && world_rank >= 0) {
            return "Lower-tier";
        } else if (world_rank <= 69.9 && world_rank >= 65) {
            return "Competitive";
        } else if (world_rank <= 74.9 && world_rank >= 70) {
            return "Strong";
        } else if (world_rank <= 83 && world_rank >= 75) {
            return "Premier ";
        } else if (world_rank >= 84) {
            return "Elite";
        } else {
            return "Unknown";
        }
    };
    const getRobust = (robust) => {
        if (robust < 33 && robust >= 0) {
            return "Low";
        } else if (robust <= 65 && robust >= 33) {
            return "Medium";
        } else if (robust > 65) {
            return "High";
        } else {
            return "Unknown";
        }
    };

    return (
        <div className="h-auto md:h-screen 2xl:px-64">
            <FilterArea
                playerInfo={playerInfo}
                positionData={sortedDataViaPosition}
                content={content}
                debounceOnChange={debounceOnChange}
                positionSelected={positionSelected}
                setPositionSelected={setPositionSelected}
                seasonSelected={seasonSelected}
                latestSeason={latestSeason}
                setSeasonSelected={setSeasonSelected}
                leagueSelected={leagueSelected}
                setLeagueSelected={setLeagueSelected}
                teamSelected={teamSelected}
                setTeamSelected={setTeamSelected}
                finalData={finalData}
                setFirstClick={setFirstClick}
                setPlayerInfo={setPlayerInfo}
                position={positionSelected}
            />
            {/* grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-64 justify-between */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-3 2xl:gap-4 justify-between pb-5 md:py-5 px-5 md:px-10">
                <div className="finishing rounded-lg shadow-lg bg-white mb-5 md:mb-0">
                    <div className="flex justify-between align-center">
                        <div className="text-[#22272F] text-xl font-semibold p-3 md:p-6">
                            Biography
                        </div>
                        <div className="p-3 md:p-6">
                            <label
                                htmlFor="history"
                                className="text-[#22272F] text-sm font-semibold border rounded-lg cursor-pointer flex items-center p-2"
                            >
                                Season history
                            </label>
                        </div>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="history"
                            className="modal-toggle"
                        />
                        <div className="modal">
                            <History playerDetails={playerHistory} />
                        </div>
                    </div>

                    <div className="content">
                        <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                            <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                <label
                                    htmlFor="position-modal"
                                    className="pt-[2px] mr-1 cursor-pointer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                            fill="#9299AA"
                                        />
                                    </svg>
                                </label>
                                Position
                                <div>
                                    <input
                                        type="checkbox"
                                        id="position-modal"
                                        className="modal-toggle"
                                    />
                                    <div className="modal">
                                        <PositionModal
                                            positionData={sortedDataViaPosition}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className="text-[#22272F] text-sm md:text-base font-semibold">
                                    {playerInfo?.position}
                                </span>
                                <span className="text-[#9299AA] text-sm md:text-base font-semibold ml-1">
                                    {playerInfo?.position === "CF/ST" && "(#9)"}
                                    {playerInfo?.position === "LW" && "(#7)"}
                                    {playerInfo?.position === "RW" && "(#7)"}
                                    {playerInfo?.position === "LWB/LM" &&
                                        "(#7)"}
                                    {playerInfo?.position === "LM/LWB" &&
                                        "(#7)"}
                                    {playerInfo?.position === "RM/RWB" &&
                                        "(#7)"}
                                    {playerInfo?.position === "RWB/RM" &&
                                        "(#7)"}
                                    {playerInfo?.position === "CM" && "(#8)"}
                                    {playerInfo?.position === "DM" && "(#6)"}
                                    {playerInfo?.position === "CAM/SS" &&
                                        "(#10)"}
                                    {playerInfo?.position === "LB" && "(#3)"}
                                    {playerInfo?.position === "RB" && "(#3)"}
                                    {playerInfo?.position === "LCB" && "(#5)"}
                                    {playerInfo?.position === "RCB" && "(#5)"}
                                    {playerInfo?.position === "CCB" && "(#5)"}
                                    {playerInfo?.position === "GK" && "(#1)"}
                                </span>
                            </div>
                        </div>

                        <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                            <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                                Age
                            </div>
                            <span className="text-[#22272F] text-sm md:text-base font-semibold">
                                {playerInfo?.age}
                            </span>
                        </div>
                        <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                            <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                                Injury history
                            </div>
                            <label
                                htmlFor="injuryHistory"
                                className="text-[#22272F] text-sm font-semibold border rounded-lg cursor-pointer flex items-center p-2"
                            >
                                See history
                            </label>
                        </div>
                        {/* model of injury history */}
                        <div>
                            <input
                                type="checkbox"
                                id="injuryHistory"
                                className="modal-toggle"
                            />
                            <div className="modal">
                                <InjuryHistory tmId={playerInfo?.tm_id} />
                            </div>
                        </div>
                        <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                            <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                                Minutes
                            </div>
                            <span className="text-[#22272F] text-sm md:text-base font-semibold">
                                {playerInfo?.minutes}
                            </span>
                        </div>
                        <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                            <div className="text-[#22272F] text-sm md:text-base font-[500] flex">
                                <label
                                    htmlFor="robustmodal"
                                    className="pt-[2px] mr-1 cursor-pointer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                            fill="#9299AA"
                                        />
                                    </svg>
                                </label>
                                Robustness
                                <div>
                                    <input
                                        type="checkbox"
                                        id="robustmodal"
                                        className="modal-toggle"
                                    />
                                    <div className="modal">
                                        <RobustModel robustData={robustData} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={` ${
                                    playerInfo?.robustness < 33 &&
                                    playerInfo?.robustness > 0 &&
                                    "text-[#fa4265] bg-[#fa426514] "
                                } ${
                                    playerInfo?.robustness >= 33 &&
                                    playerInfo?.robustness < 66 &&
                                    "text-[#F29445] bg-[#F2944514] "
                                } ${
                                    playerInfo?.robustness > 65 &&
                                    "text-[#02b059] bg-[#02b05914] "
                                } ${
                                    playerInfo?.robustness == 0 &&
                                    " bg-[#f7f7f8] text-[#22272F] "
                                } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]  `}
                            >
                                {playerInfo?.robustness > 0
                                    ? getRobust(playerInfo?.robustness)
                                    : "-"}
                            </div>
                        </div>
                    </div>
                </div>
                {performanceSwitch === "performance" && (
                    <div className="finishing rounded-lg shadow-lg bg-white mb-5 md:mb-0">
                        <div className="flex justify-between p-3 md:p-6">
                            <div className="text-[#22272F] text-xl font-semibold ">
                                Performance
                            </div>
                            <div
                                onClick={() => setPerformanceSwitch("skill")}
                                className="text-primary text-base font-semibold cursor-pointer"
                            >
                                Change to Skills
                            </div>
                        </div>

                        <div className="content">
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <label
                                        htmlFor="worldrank"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </label>
                                    World Club Rank
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="worldrank"
                                            className="modal-toggle"
                                        />
                                        <div className="modal">
                                            <WorldRankingModal />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-[#22272F] text-sm md:text-base font-semibold">
                                        {/* {getWorldRank(playerInfo?.world_rank)} - */}
                                        {playerInfo?.world_rank
                                            ? makePosition(
                                                  playerInfo?.world_rank
                                              )
                                            : "-"}
                                    </span>
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="Attacking-Impact"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Attacking-Impact"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Attacking Impact
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.attack !=
                                                            null &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.attack?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.attack != null
                                                        ? playerInfo?.attack?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Attacking Impact is a measure of
                                                each players contributions to
                                                his team’s xG during a match.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Attacking Impact
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.attack != null &&
                                        GetPerFormanceColor(playerInfo?.attack?.toFixed(0))
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.attack != null
                                        ? getPerformanceValue(
                                              playerInfo?.attack?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="defending-impact"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="defending-impact"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Defending Impact
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.def_qual !=
                                                            null &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.def_qual?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.def_qual !=
                                                    null
                                                        ? playerInfo?.def_qual?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Defending Impact is a measure of
                                                each players ability to prevent
                                                xG in his area of
                                                responsibility.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Defending Impact
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.def_qual != null &&
                                        GetPerFormanceColor(
                                            playerInfo?.def_qual?.toFixed(0)
                                        )
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.def_qual != null
                                        ? getPerformanceValue(
                                              playerInfo?.def_qual?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <div
                                        data-tooltip-id="defending-frequency"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="defending-frequency"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Defending Frequency
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.def_quant !=
                                                            null &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.def_quant?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.def_quant !=
                                                    null
                                                        ? playerInfo?.def_quant?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Defending Frequency is a measure
                                                for the number of defending
                                                opportunities a player has out
                                                of possession.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Defending Frequency
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.def_quant != null &&
                                        GetPerFormanceColor(
                                            playerInfo?.def_quant?.toFixed(0)
                                        )
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.def_quant != null
                                        ? getPerformanceValue(
                                              playerInfo?.def_quant?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-[500] flex">
                                    <div
                                        data-tooltip-id="ball-retention"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="ball-retention"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Ball Retention
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.ball_ret !=
                                                            null &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.ball_ret?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.ball_ret !=
                                                    null
                                                        ? playerInfo?.ball_ret?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                This model measures the
                                                likelihood that a team will keep
                                                possession after a player
                                                touches the ball.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Ball Retention
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.ball_ret != null &&
                                        GetPerFormanceColor(
                                            playerInfo?.ball_ret?.toFixed(0)
                                        )
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.ball_ret != null
                                        ? getPerformanceValue(
                                              playerInfo?.ball_ret?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {performanceSwitch === "skill" && (
                    <div className="finishing rounded-lg shadow-lg bg-white mb-5 md:mb-0">
                        <div className="flex justify-between p-3 md:p-6">
                            <div className="text-[#22272F] text-xl font-semibold ">
                                Skill
                            </div>
                            <div
                                onClick={() =>
                                    setPerformanceSwitch("performance")
                                }
                                className="text-primary text-base font-semibold cursor-pointer"
                            >
                                Change to Performace
                            </div>
                        </div>

                        <div className="content">
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <label
                                        htmlFor="worldrank"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </label>
                                    World Club Rank
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="worldrank"
                                            className="modal-toggle"
                                        />
                                        <div className="modal">
                                            <WorldRankingModal />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-[#22272F] text-sm md:text-base font-semibold">
                                        {/* {getWorldRank(playerInfo?.world_rank)} - */}
                                        {playerInfo?.world_rank
                                            ? makePosition(
                                                  playerInfo?.world_rank
                                              )
                                            : "-"}
                                    </span>
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="aerial"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="aerial"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Aerial
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.open &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.open?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.open
                                                        ? playerInfo?.open?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                This algorithm estimates the
                                                likelihood of a player winning
                                                an aerial duel against an
                                                average player in the league.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Aerial
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.open &&
                                        GetPerFormanceColor(playerInfo?.open?.toFixed(0))
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.open
                                        ? getPerformanceValue(
                                              playerInfo?.open?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="dribbling"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="dribbling"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Dribbling
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.carry &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.carry?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.carry
                                                        ? playerInfo?.carry?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                This algorithm estimates the
                                                likelihood of a player winning a
                                                dribbling duel against an
                                                average player in the league.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Dribbling
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.carry &&
                                        GetPerFormanceColor(playerInfo?.carry?.toFixed(0))
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.carry
                                        ? getPerformanceValue(playerInfo?.carry?.toFixed(0))
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <div
                                        data-tooltip-id="tackling"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="tackling"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Tackling
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.tackle &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.tackle?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.tackle
                                                        ? playerInfo?.tackle?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>  
                                            <p className="text-[#9299AA] w-[300px]">
                                                This algorithm estimates the
                                                likelihood of a player winning a
                                                tackling duel against an average
                                                player in the league.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Tackling
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.tackle &&
                                        GetPerFormanceColor(playerInfo?.tackle?.toFixed(0))
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.tackle
                                        ? getPerformanceValue(
                                              playerInfo?.tackle?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-[500] flex">
                                    <div
                                        data-tooltip-id="finishing"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="finishing"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Finishing
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.open_foot &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.open_foot?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.open_foot
                                                        ? playerInfo?.open_foot?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                This algorithm estimates the
                                                likelihood of a player scoring
                                                and is a measure of
                                                a players career.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Finishing
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.open_foot &&
                                        GetPerFormanceColor(
                                            playerInfo?.open_foot?.toFixed(0)
                                        )
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.open_foot
                                        ? getPerformanceValue(
                                              playerInfo?.open_foot?.toFixed(0)
                                          )
                                        : "-"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {physicalSwitch === "physical" && (
                    <div className="finishing rounded-lg shadow-lg bg-white">
                        <div className="flex justify-between p-3 md:p-6">
                            <div className="text-[#22272F] text-xl font-semibold ">
                                Physical
                            </div>
                            <div
                                onClick={() => setPhysicalSwitch("game intel")}
                                className="text-primary text-base font-semibold cursor-pointer"
                            >
                                Change to Game Intel
                            </div>
                        </div>

                        <div className="content">
                            
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="pace"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="pace"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Pace
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.psv99_scaled ?
                                                        GetPerFormanceColor(
                                                            playerInfo?.psv99_scaled?.toFixed(1)
                                                        ) : 'bg-gray-100'
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.psv99_scaled
                                                        ? getPerformanceValue(
                                                            playerInfo?.psv99_scaled?.toFixed(0)
                                                        )
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Measures a player's top speed and ability to repeatedly reach it. PSV 99 = <span className={`px-1 rounded ${
                                                        playerInfo?.psv99_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.psv99_scaled?.toFixed(1)
                                                        ) }`}>{playerInfo?.psv99?.toFixed(1)}</span> km/h
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Pace
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.psv99_scaled ?
                                        GetPerFormanceColor(
                                            playerInfo?.psv99_scaled?.toFixed(0)
                                        ) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.psv99_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.psv99_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>

                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="Accels_In_Possession"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Accels_In_Possession"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Accels In Possession
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.count_high_acceleration_tip_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.count_high_acceleration_tip_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.count_high_acceleration_tip_scaled
                                                        ? playerInfo?.count_high_acceleration_tip_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Activities over 3 m/s² for at least 0.7 seconds when the team has possession.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Accels In Possession
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.count_high_acceleration_tip_scaled ?
                                        GetPerFormanceColor(
                                            playerInfo?.count_high_acceleration_tip_scaled?.toFixed(0)
                                        ) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.count_high_acceleration_tip_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.count_high_acceleration_tip_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>

                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="Accels_Out_Of_Possession"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Accels_Out_Of_Possession"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Accels Out Of
                                                    Possession
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.count_high_acceleration_otip_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.count_high_acceleration_otip_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.count_high_acceleration_otip_scaled
                                                        ? playerInfo?.count_high_acceleration_otip_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Activities over 3 m/s² for at least 0.7 seconds when the opponent has possession.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Accels Out Of Possession
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.count_high_acceleration_otip_scaled ?
                                        GetPerFormanceColor(playerInfo?.count_high_acceleration_otip_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.count_high_acceleration_otip_scaled
                                        ? getPerformanceValue(playerInfo?.count_high_acceleration_otip_scaled?.toFixed(0))
                                        : "Unavailable"}
                                </div>
                            </div>

                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <div
                                        data-tooltip-id="HI_In_Possession"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="HI_In_Possession"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    HI In Possession
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.hi_count_full_all_tip_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.hi_count_full_all_tip_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.hi_count_full_all_tip_scaled
                                                        ? playerInfo?.hi_count_full_all_tip_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Total of High-Speed Runs (20-25 km/h) and Sprints (over 25 km/h) lasting at least 0.7 seconds when the team has possession.

                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    HI In Possession
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.hi_count_full_all_tip_scaled ?
                                        GetPerFormanceColor(playerInfo?.hi_count_full_all_tip_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.hi_count_full_all_tip_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.hi_count_full_all_tip_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>

                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-[500] flex">
                                    <div
                                        data-tooltip-id="HI_Out_Of_Possession"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="HI_Out_Of_Possession"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    HI Out Of Possession
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.hi_count_full_all_otip_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.hi_count_full_all_otip_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.hi_count_full_all_otip_scaled
                                                        ? playerInfo?.hi_count_full_all_otip_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Total of High-Speed Runs (20-25 km/h) and Sprints (over 25 km/h) lasting at least 0.7 seconds when the opponent has possession.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    HI Out Of Possession
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.hi_count_full_all_otip_scaled ?
                                        GetPerFormanceColor(
                                            playerInfo?.hi_count_full_all_otip_scaled?.toFixed(0)
                                        ) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.hi_count_full_all_otip_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.hi_count_full_all_otip_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {physicalSwitch === "game intel" && (
                    <div className="finishing rounded-lg shadow-lg bg-white">
                        <div className="flex justify-between p-3 md:p-6">
                            <div className="text-[#22272F] text-xl font-semibold ">
                                Game Intel
                            </div>
                            <div
                                onClick={() => setPhysicalSwitch("physical")}
                                className="text-primary text-base font-semibold cursor-pointer"
                            >
                                Change to Physical
                            </div>
                        </div>

                        <div className="content">
                            
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="run"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="run"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                Runs
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.count_runs_per_match_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.count_runs_per_match_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.count_runs_per_match_scaled
                                                        ? playerInfo?.count_runs_per_match_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Number of runs performed by the player.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Runs
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.count_runs_per_match_scaled ?
                                        GetPerFormanceColor(playerInfo?.count_runs_per_match_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.count_runs_per_match_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.count_runs_per_match_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>

                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="Runs_Impact"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Runs_Impact"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Runs Impact
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.runs_threat_per_match_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.runs_threat_per_match_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.runs_threat_per_match_scaled
                                                        ? playerInfo?.runs_threat_per_match_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Sum of run threats from the player's runs, equating to goals an average player would score in the same situations.

                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Runs Impact
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.runs_threat_per_match_scaled ?
                                        GetPerFormanceColor(playerInfo?.runs_threat_per_match_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.runs_threat_per_match_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.runs_threat_per_match_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex ">
                                    <div
                                        data-tooltip-id="Retention_Under_Pressure"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Retention_Under_Pressure"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Retention Under Pressure
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.ball_retention_ratio_under_pressure_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.ball_retention_ratio_under_pressure_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.ball_retention_ratio_under_pressure_scaled
                                                        ? playerInfo?.ball_retention_ratio_under_pressure_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Percentage of times possession is retained under pressure.

                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Retention Under Pressure
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.ball_retention_ratio_under_pressure_scaled ?
                                        GetPerFormanceColor(playerInfo?.ball_retention_ratio_under_pressure_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.ball_retention_ratio_under_pressure_scaled
                                        ? getPerformanceValue(playerInfo?.ball_retention_ratio_under_pressure_scaled?.toFixed(0))
                                        : "Unavailable"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-medium flex">
                                    <div
                                        data-tooltip-id="Passing_Impact"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Passing_Impact"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                    Passing Impact
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled
                                                        ? playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Percentage of completed dangerous passes under pressure. 

                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Passing Impact
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled ?
                                        GetPerFormanceColor(playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled?.toFixed(0)) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.dangerous_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-3 md:p-4 md:px-6 border-t-2 border-[#F8F8FA]">
                                <div className="text-[#22272F] text-sm md:text-base font-[500] flex">
                                    <div
                                        data-tooltip-id="Passing_Under_Pressure"
                                        className="pt-[2px] mr-1 cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M9.2 7.6H10.8V6H9.2M10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM10 2C8.94943 2 7.90914 2.20693 6.93853 2.60896C5.96793 3.011 5.08601 3.60028 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM9.2 14H10.8V9.2H9.2V14Z"
                                                fill="#9299AA"
                                            />
                                        </svg>
                                    </div>
                                    <ReactTooltip
                                        id="Passing_Under_Pressure"
                                        variant="info"
                                        place="top"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="p-3 border rounded-lg bg-white">
                                            <div className="flex justify-between">
                                                <h2 className="font-semibold text-base text-[#22272F]">
                                                Passing Under Pressure
                                                </h2>
                                                <div
                                                    className={` ${
                                                        playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled &&
                                                        GetPerFormanceColor(
                                                            playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                                        )
                                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px]  text-sm md:text-base font-[500]`}
                                                >
                                                    {playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled
                                                        ? playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                                        : "-"}
                                                </div>
                                            </div>
                                            <p className="text-[#9299AA] w-[300px]">
                                                Percentage of completed difficult passes under pressure.
                                            </p>
                                        </div>
                                    </ReactTooltip>
                                    Passing Under Pressure
                                </div>
                                <div
                                    className={` ${
                                        playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled ?
                                        GetPerFormanceColor(
                                            playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                        ) : 'bg-gray-100'
                                    } rounded px-[8px] py-[2px] flex justify-center items-center h-[28px] w-[95px] text-sm md:text-base font-[500]`}
                                >
                                    {playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled
                                        ? getPerformanceValue(
                                              playerInfo?.difficult_pass_completion_ratio_under_pressure_scaled?.toFixed(0)
                                          )
                                        : "Unavailable"}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlayerProfile3;
