import React, { useEffect, useState } from "react";

const Leagues = ({
  
  playerName,
  allLeagues,
  selectedLeague,
  setSelectedLeague,
}) => {
  const [leagueToggle, setLeagueToggle] = useState(false);
  const [leagueFirstClick, setLeagueFirstClick] = useState(false);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".league-btn")) {
        setLeagueToggle(false);
      }
    });
  }, []);

  const handleLeagueSelected = (league) => {
    setLeagueFirstClick(true);
    setSelectedLeague(league);
    // setPage(1);
  };

  useEffect(()=>{
    if(allLeagues?.length>0 && !selectedLeague){
        setSelectedLeague("Select League");
    }
  },[allLeagues])


  let content = selectedLeague!==undefined?selectedLeague:"Select Season";
  if(!selectedLeague && playerName!=="" ){
    content = 
    <svg
    class="animate-spin mx-auto h-5 w-5 text-slate-400"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
  }
  
  if((allLeagues?.length>0) && (selectedLeague!==undefined || selectedLeague==="Select Season") && !leagueFirstClick ){
    setSelectedLeague(allLeagues?.[0]);
    content = selectedLeague;
  }
  return (
    <div className="mt-3 ">
      <h6>League</h6>

      <div className="relative mt-2">
        <button
          onClick={() => setLeagueToggle(!leagueToggle)}
          className="w-full xl:w-full input-border capitalize p-2 rounded-lg outline-none league-btn flex  bg-base-100"
        >
          {content}
        </button>

        {leagueToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {allLeagues.map((item, index) => (
              <p
                key={index}
                onClick={() => handleLeagueSelected(item)}
                className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] duration-300"
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Leagues;
