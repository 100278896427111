import React, { useContext } from "react";
import { DataContext } from "../../Context/DataProvider";

const pointsTable = [
  { Place: 1, Points: 2.06, xGD_avg: 0.96 },
  { Place: 2, Points: 1.82, xGD_avg: 0.64 },
  { Place: 3, Points: 1.57, xGD_avg: 0.58 },
  { Place: 4, Points: 1.53, xGD_avg: 0.58 },
  { Place: 5, Points: 1.49, xGD_avg: 0.35 },
  { Place: 6, Points: 1.4, xGD_avg: 0.24 },
  { Place: 7, Points: 1.27, xGD_avg: 0 },
  { Place: 8, Points: 1.15, xGD_avg: -0.01 },
  { Place: 9, Points: 1.12, xGD_avg: -0.05 },
  { Place: 10, Points: 1.1, xGD_avg: -0.07 },
  { Place: 11, Points: 1.06, xGD_avg: -0.16 },
  { Place: 12, Points: 1, xGD_avg: -0.2 },
  { Place: 13, Points: 0.95, xGD_avg: -0.2 },
  { Place: 14, Points: 0.93, xGD_avg: -0.26 },
  { Place: 15, Points: 0.89, xGD_avg: -0.28 },
  { Place: 16, Points: 0.85, xGD_avg: -0.34 },
  { Place: 17, Points: 0.8, xGD_avg: -0.35 },
  { Place: 18, Points: 0.7, xGD_avg: -0.39 },
  { Place: 19, Points: 0.64, xGD_avg: -0.45 },
  { Place: 20, Points: 0.5, xGD_avg: -0.5 },
];

const TeamRight = ({ team }) => {
  const { assignedPlayers } = useContext(DataContext);

  const dataViaTeam = assignedPlayers?.find((data) => data?.team === team);
  const players = dataViaTeam?.players;

  // value may update start
  const x_gaar_avg = -1.11;
  const x_gfar_avg = 1;
  // value may update end

  // ger sum
  function getSum(array, key) {
    return array?.reduce((acc, item) => {
      return acc + item[key];
    }, 0);
  }

  function getPointsValue(arr, value) {
    let closestObject = arr[0];
    let minDifference = Math.abs(value - arr[0]?.xGD_avg);
    for (const obj of arr) {
      const difference = Math.abs(value - obj.xGD_avg);
      if (difference < minDifference) {
        minDifference = difference;
        closestObject = obj;
      }
    }
    return closestObject;
  }

  const goal_conceded = Math.round(
    (getSum(players, "x_gaar") + x_gaar_avg) * 38
  );
  const goal_scored = Math.round((getSum(players, "x_gfar") + x_gfar_avg) * 38);

  const sumOfX_Gdar = getSum(players, "x_gdar");

  const leagueTable =
    players?.length > 0 ? getPointsValue(pointsTable, sumOfX_Gdar)?.Place : "-";
  const points =
    players?.length > 0
      ? Math.round(getPointsValue(pointsTable, sumOfX_Gdar)?.Points * 38)
      : "-";

  function getAverage(data, field) {
    if (data?.length > 0) {
      let sum = 0;
      data?.forEach((item) => {
        sum += item[field];
      });

      const count = data.length;
      const average = count > 0 ? sum / count : 0;

      return Math.round(average);
    }
  }

  return (
    <div className="team-right w-1/5">
      <div className="team-prediction">
        <div className="team-prediction-title p-5">
          <h3>Prediction</h3>
        </div>
        <div className="team-prediction-top p-5">
          <p>Points</p>
          <h4 className="mt-3">
            {players?.length === 11 ? (
              points ? (
                points
              ) : (
                "-"
              )
            ) : (
              <span className="text-sm">Calculating...</span>
            )}
          </h4>
        </div>
        <div className=" team-prediction-bottom p-5">
          <div className="mb-3 flex justify-between items-center ">
            <p>League Table</p>
            <div className="point">
              <p>
                {players?.length === 11
                  ? leagueTable
                    ? leagueTable
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="mb-3 flex justify-between items-center ">
            <p>Goals scored</p>
            <div className="point">
              <p>
                {players?.length === 11
                  ? goal_scored
                    ? goal_scored
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center ">
            <p>Goals conceded</p>
            <div className="point">
              <p>
                {}{" "}
                {players?.length === 11
                  ? goal_conceded
                    ? goal_conceded
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="team-prediction mt-10">
        <div className="team-prediction-title p-5">
          <h3>Team performance</h3>
        </div>

        <div className=" team-prediction-bottom p-5">
          <div className="mb-3 flex justify-between items-center ">
            <div className="basis-[80%]">
              <p>Ball Progression</p>
            </div>
            <div
              className={` ${
                players?.length === 11 && getAverage(players, "attack_bp") < 34 &&
                getAverage(players, "attack_bp") > 0 &&
                "text-[#fa4265] bg-[#fa426514] "
              }
              ${
                players?.length === 11 && getAverage(players, "attack_bp") > 33 &&
                getAverage(players, "attack_bp") < 66 &&
                "text-[#F29445] bg-[#F2944514] "
              } ${
                players?.length === 11 && getAverage(players, "attack_bp") > 65 &&
                "text-[#02b059] bg-[#02b05914] "
              } ${
                ((getAverage(players, "attack_bp") == 0 || !getAverage(players, "attack_bp")) || players?.length < 11)  &&
                " bg-[#f7f7f8] text-[#22272F] "
              }
              rounded  flex justify-center items-center w-[34px] h-[28px]  `}
            >
              <p>
                {players?.length === 11
                  ? getAverage(players, "attack_bp")
                    ? getAverage(players, "attack_bp")
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="mb-3 flex justify-between items-center ">
            <div className="basis-[80%]">
              <p>Short creation</p>
            </div>
            <div
              className={` ${
                players?.length === 11 && getAverage(players, "attack_sc") < 34 &&
                players?.length === 11 && getAverage(players, "attack_sc") > 0 &&
                "text-[#fa4265] bg-[#fa426514] "
              }
              ${
                players?.length === 11 && getAverage(players, "attack_sc") > 33 &&
                players?.length === 11 && getAverage(players, "attack_sc") < 66 &&
                "text-[#F29445] bg-[#F2944514] "
              } ${
                players?.length === 11 && getAverage(players, "attack_sc") > 65 &&
                "text-[#02b059] bg-[#02b05914] "
              } ${
                (getAverage(players, "attack_sc") == 0 || !getAverage(players, "attack_sc") || players?.length < 11)  &&
                " bg-[#f7f7f8] text-[#22272F] "
              }
              rounded  flex justify-center items-center w-[34px] h-[28px]  `}
            >
              <p>
                {players?.length === 11
                  ? getAverage(players, "attack_sc")
                    ? getAverage(players, "attack_sc")
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="mb-3 flex justify-between items-center ">
            <div className="basis-[80%]">
              <p>Ball Retention</p>
            </div>
            <div
              className={` ${
                players?.length === 11 && getAverage(players, "ball_ret") < 34 &&
                players?.length === 11 && getAverage(players, "ball_ret") > 0 &&
                "text-[#fa4265] bg-[#fa426514] "
              }
              ${
                players?.length === 11 && getAverage(players, "ball_ret") > 33 &&
                players?.length === 11 && getAverage(players, "ball_ret") < 66 &&
                "text-[#F29445] bg-[#F2944514] "
              } ${
                players?.length === 11 && getAverage(players, "ball_ret") > 65 &&
                "text-[#02b059] bg-[#02b05914] "
              } ${
                 (getAverage(players, "ball_ret") == 0 || !getAverage(players, "ball_ret") || players?.length < 11) && 
                " bg-[#f7f7f8] text-[#22272F] "
              }
              rounded  flex justify-center items-center w-[34px] h-[28px]  `}
            >
              <p>
                {players?.length === 11
                  ? getAverage(players, "ball_ret")
                    ? getAverage(players, "ball_ret")
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="mb-3 flex justify-between items-center ">
            <div className="basis-[80%]">
              <p>Defending Impact</p>
            </div>
            <div
              className={` ${
                players?.length === 11 && getAverage(players, "def_qual") < 34 &&
                players?.length === 11 && getAverage(players, "def_qual") > 0 &&
                "text-[#fa4265] bg-[#fa426514] "
              }
              ${
                players?.length === 11 && getAverage(players, "def_qual") > 33 &&
                players?.length === 11 && getAverage(players, "def_qual") < 66 &&
                "text-[#F29445] bg-[#F2944514] "
              } ${
                players?.length === 11 && getAverage(players, "def_qual") > 65 &&
                "text-[#02b059] bg-[#02b05914] "
              } ${
                (getAverage(players, "def_qual") == 0 || !getAverage(players, "def_qual") || players?.length < 11) &&
                " bg-[#f7f7f8] text-[#22272F] "
              }
              rounded  flex justify-center items-center w-[34px] h-[28px]  `}
            >
              <p>
                {players?.length === 11
                  ? getAverage(players, "def_qual")
                    ? getAverage(players, "def_qual")
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center ">
            <div className="basis-[80%]">
              <p>Defending Frequency</p>
            </div>
            <div
              className={` ${
                players?.length === 11 && getAverage(players, "def_quant") < 34 &&
                players?.length === 11 && getAverage(players, "def_quant") > 0 &&
                "text-[#fa4265] bg-[#fa426514] "
              }
              ${
                players?.length === 11 && getAverage(players, "def_quant") > 33 &&
                players?.length === 11 && getAverage(players, "def_quant") < 66 &&
                "text-[#F29445] bg-[#F2944514] "
              } ${
                players?.length === 11 && getAverage(players, "def_quant") > 65 &&
                "text-[#02b059] bg-[#02b05914] "
              } ${
                (getAverage(players, "def_quant") == 0 || !getAverage(players, "def_quant") || players?.length < 11) &&
                " bg-[#f7f7f8] text-[#22272F] "
              }
              rounded  flex justify-center items-center w-[34px] h-[28px]  `}
            >
              <p>
                {players?.length === 11
                  ? getAverage(players, "def_quant")
                    ? getAverage(players, "def_quant")
                    : "-"
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamRight;
