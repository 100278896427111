import React from "react";
import ProgressBar from "../ProgressBar";



const PositionModal = ({ positionData }) => {
    return (
        <div className="modal-box max-w-sm player-position p-0">
            <div className="player-position-header flex justify-between items-center p-5">
                <h3 className="font-bold text-[#7C86A1] text-xs">POSITION</h3>
                <label
                    htmlFor="position-modal"
                    className="h-[25px] w-[25px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
                >
                    <div className="mt-[-2px] ml-px ">x</div>
                </label>
            </div>
            <div className="px-5 flex flex-col pb-2">
                {positionData?.map((data, index) => (
                    <div
                        key={index}
                        className={`flex py-3 ${
                            index !== positionData?.length - 1 && "border-b"
                        }  items-center justify-between`}
                    >
                        <div className="flex items-center gap-4">
                            <div className="w-[80px]">
                                <div className="text-[#22272F] inline rounded p-2 text-xs font-semibold bg-[#F7F7F8] ">
                                    {data?.position}
                                </div>
                            </div>
                            <div className="">
                                <div className="font-semibold text-sm text-[#22272F] ">
                                    {data?.position === "CF/ST" &&
                                        "Centre Forward"}
                                    {data?.position === "LW" && "Left Wing"}
                                    {data?.position === "RW" && "Right Wing"}
                                    {data?.position === "LWB/LM" &&
                                        "Left Midfield"}
                                    {data?.position === "RM/RWB" &&
                                        "Right Midfield"}
                                    {data?.position === "CM" &&
                                        "Centre Midfield"}
                                    {data?.position === "DM" &&
                                        "Defensive Midfield"}
                                    {data?.position === "CAM/SS" &&
                                        "Attacking Midfield"}
                                    {data?.position === "LB" && "Left Back"}
                                    {data?.position === "RB" && "Right Back"}
                                    {data?.position === "LCB" &&
                                        "L. Centre Back"}
                                    {data?.position === "RCB" &&
                                        "R. Centre Back"}
                                    {data?.position === "CCB" &&
                                        "C. Centre Back"}
                                    {data?.position === "GK" && "Goal-Keeper"}
                                </div>
                                <div className="text-[#9299AA] font-medium text-sm ">
                                    {data?.career_games_in_position} out of{" "}
                                    {data?.career_games} games
                                </div>
                            </div>
                        </div>
                        <div className="w-[40px] h-[40px] ">
                            <ProgressBar
                                progress={
                                    data?.career_games_in_position_percentage
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PositionModal;
