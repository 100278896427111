import React, { useEffect, useState } from "react";

const allTeamQualities = [
    "ELT Starter",
    "ELT Rotation",
    "PRM Starter",
    "PRM Rotation",
    "STR Starter",
    "STR Rotation",
    "CMP Starter",
    "CMP Rotation",
    "LWT Starter",
    "LWT Rotation",
];

const subHeadersLevel = [
    {
        sub: "Elite Teams",
        values: ["ELT Starter", "ELT Rotation"],
    },
    {
        sub: "Premier Teams",
        values: ["PRM Starter", "PRM Rotation"],
    },
    {
        sub: "Strong Teams",
        values: ["STR Starter", "STR Rotation"],
    },
    {
        sub: "Competetive Teams",
        values: ["CMP Starter", "CMP Rotation"],
    },
    {
        sub: "Lower-tier Teams",
        values: ["LWT Starter", "LWT Rotation"],
    },
];

const TeamQuality = ({
    teamQualitySelected,
    setTeamQualitySelected,
    setPage,
    selectedTeamQualities,
    setSelectedTeamQualities,
}) => {
    const [currentLevelToggle, setCurrentLevelToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest(".level-btn")) {
                setCurrentLevelToggle(false);
            }
        });
    }, []);

    const handleClick = () => {
        setCurrentLevelToggle(!currentLevelToggle);
    };

    const handleTeamQualitySelected = (teamQuality) => {
        setLoading(false);
        const leagues = [...selectedTeamQualities, teamQuality];
        setSelectedTeamQualities([...new Set(leagues)]);
        setTeamQualitySelected(teamQuality);

        setIsLoad(false);
        setPage(1);
    };

    const handleRemoveLeague = (level) => {
        const filteredLeagues = selectedTeamQualities.filter(
            (item) => item !== level
        );
        setSelectedTeamQualities(filteredLeagues);
        setTeamQualitySelected(null);
    };

    return (
        <div className="mt-3 ">
            <h6 className="text-sm">Current Level</h6>
            <div className=" flex flex-wrap gap-1 ">
                {selectedTeamQualities.length > 0 &&
                    selectedTeamQualities.map((level, index) => (
                        <div
                            key={index}
                            style={{ fontSize: "11px" }}
                            className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
                        >
                            <div>{level}</div>
                            <div
                                onClick={() => handleRemoveLeague(level)}
                                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
                            >
                                x
                            </div>
                        </div>
                    ))}
            </div>
            <div className="relative mt-2">
                {currentLevelToggle && (
                    <div className="absolute bottom-full max-h-[300px] overflow-y-scroll w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
                        
                        {subHeadersLevel?.map((item, index) => (
                            <React.Fragment key={index}>
                                <h2 className="p-1.5 text-neutral-content font-semibold text-md">
                                    {item.sub}
                                </h2>
                                {item?.values?.map((item2, idx) => (
                                    <p
                                        key={idx}
                                        onClick={() =>
                                            handleTeamQualitySelected(item2)
                                        }
                                        className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
                                    >
                                        {item2}
                                    </p>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                )}
                <button
                    onClick={handleClick}
                    className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none level-btn flex  bg-base-100"
                >
                    {loading && isLoad ? (
                        <svg
                            className="animate-spin mx-auto h-5 w-5 text-slate-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : teamQualitySelected?.length > 0 ? (
                        teamQualitySelected
                    ) : (
                        "Select Current Level"
                    )}
                </button>
            </div>
        </div>
    );
};

export default TeamQuality;
