import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";

const Main = () => {
  return (
    <div className="max-w-[1920px] mx-auto">
      <Header />
      <div className="bg-[#F8F8FA]">
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
