import React, { useContext, useState } from "react";
import sidebar from "../../../Images/sidebar.png";
import UpdateTeamModal from "./UpdateTeamModal";
import DeleteTeamModal from "./DeleteTeamModal";
import { Link } from "react-router-dom";

const FinanceTeam = ({team}) => {
  
  
  return (
    <div className="your-team px-3 pt-3 pb-5">
      <div className="dropdown dropdown-bottom flex justify-center items-center flex-col float-right">
        <label tabIndex={0} className=" cursor-pointer ">
          <img src={sidebar} alt="" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content mt-2 menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <label htmlFor="update-team-modal">Edit team detail {team?.name} </label>
          </li>
          <li>
            <label htmlFor="delete-team-modal">Remove Team</label>
          </li>
        </ul>
      </div>

      <div className="flex flex-col justify-center items-center py-5">
        <div className="team-score text-center">
          <p>{team?.formation}</p>
        </div>
        <div className="mt-8 text-center team-title">
          <Link to={`/team/${team?.name}`} ><h4 className="mb-3 ">{team?.name}</h4></Link>
          {/* <p className="time">18 mins ago</p> */}
        </div>
      </div>
      <UpdateTeamModal team={team} />
      <DeleteTeamModal team={team} />
    </div>
  );
};

export default FinanceTeam;
