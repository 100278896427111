import React, { useContext, useState } from "react";
import player1 from "../../../../Images/player-1.png";
import player2 from "../../../../Images/player-2.png";
import sidebar from "../../../../Images/sider-icon-2.png";
import { DataContext } from "../../../../Context/DataProvider";
import DeleteModal from "../../DeleteModal/DeleteModal";

const GoalkeeperTable = ({ players, setPlayers, setPosData }) => {
  const { base_url } = useContext(DataContext);
  const [targetedPlayer, setTargetedPlayer] = useState(null);
  return (
    <div className="p-3 coach-table ">
      <div className="">
        <table className="table w-full">
          <tbody>
            {players.map((player, index) => (
              <tr
                className={` ${(index + 1) % 2 !== 0 ? "bg-[#f2f3f6]  " : ""}`}
                key={index}
              >
                <td width="40%" className="bg-transparent rounded-l-lg coach-name ">
                  <div className="flex items-center px-0.5 gap-1">
                  <img
                    className="w-[40px] h-[40px] border rounded-full"
                    src={`${base_url}/api/v1/player/image/${player?.name_dob}`}
                    alt="player"
                  />
                  <div
                    title={player?.name}
                    style={{ lineHeight: "20px" }}
                    className="whitespace-normal"
                  >
                    {player?.name.split(" ")[0]} {player?.name.split(" ").length>1 && player?.name.split(" ")[player?.name.split(" ").length-1]}
                  </div>
                  </div>
                </td>
                <td width="30%" className="bg-transparent coach-team ">
                  <div className="px-0.5 whitespace-normal">{player?.team.split(" ")[0]} {player?.team?.split(" ").length>1 && player?.team.split(" ")[player?.team?.split(" ").length-1]}</div>
                </td>
                <td width="10%" className="bg-transparent coach-team ">
                  <div className="px-0.5 whitespace-normal">{player?.position}</div>
                </td>
                <td width="10%" className="bg-transparent  coach-rating ">
                  {player?.delta}
                </td>
                <td width="10%" align="center" className="bg-transparent  rounded-r-lg ">
                  <div className="dropdown dropdown-bottom flex justify-center items-center flex-col float-right">
                    <label onClick={()=>setTargetedPlayer(player)} tabIndex={0} className=" cursor-pointer hover:bg-transparent ">
                      <img className="" src={sidebar} alt="icon" />
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content right-0 mt-2 menu shadow bg-base-100 rounded-box "
                    >
                      <li>
                        <label className="hover:bg-transparent" htmlFor="delete-goalkeeper-modal">Remove Player</label>
                      </li>
                    </ul>
                  </div>
                </td>
                <DeleteModal modalName="delete-goalkeeper-modal" data={players} setData={setPlayers} player={targetedPlayer} setPosData={setPosData} />
              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
      
    </div>
  );
};

export default GoalkeeperTable;
