import React, { useContext, useState } from "react";
import searchFilter from "../../Images/search-filter.png";
import searchBlue from "../../Images/search-blue.png";
import sortDown from "../../Images/sorting-down.png";
import sortUp from "../../Images/sorting-up.png";
import { sortBasedOnKey } from "../../customFunctions/sortingFunction";
import arrow from "../../Images/arrow.png";
import Pagination from "../../customFunctions/pagination";
import { DataContext } from "../../Context/DataProvider";

const HistoryTable = ({
  history,
  setFinalData,
  setPlayerHistory,
  position,
  currentPage,
  setCurrentPage,
}) => {
  const [togglePlayerSort, setTogglePlayerSort] = useState(true);
  const [toggleTeamSort, setToggleTeamSort] = useState(true);
  const [toggleAgeSort, setToggleAgeSort] = useState(true);
  const [togglePositionSort, setTogglePositionSort] = useState(true);
  const [toggleMinutesSort, setToggleMinutesSort] = useState(true);
  const [toggleLeagueSort, setToggleLeagueSort] = useState(true);
  const [toggleSeasonSort, setToggleSeasonSort] = useState(true);
  const [toggleGSort, setToggleGSort] = useState(true);
  const [toggleGASort, setToggleGASort] = useState(true);
  const [toggleGDSort, setToggleGDSort] = useState(true);
  const [toggleBPRSort, setToggleBPRSort] = useState(true);
  const [toggleSCRSort, setToggleSCRSort] = useState(true);
  const [toggleBRTSort, setToggleBRTSort] = useState(true);
  const [toggleDIMSort, setToggleDIMSort] = useState(true);
  const [toggleDFRSort, setToggleDFRSort] = useState(true);
  const [savedPlayer, setSavedPlayer] = useState(false);

  const { base_url, token, user, playerShortListData, setPlayerShortListData } =
    useContext(DataContext);

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

  // Get the current page's data
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

  //   data for goalkeeper

  const gkData = currentItems?.filter((player) =>
    ["GK"].includes(player.position)
  );
  const centerbksData = currentItems?.filter((player) =>
    ["LCB", "RCB", "CCB"].includes(player.position)
  );
  const fullbksData = currentItems?.filter((player) =>
    ["LB", "RB"].includes(player.position)
  );
  const defensiveData = currentItems?.filter((player) =>
    ["DM"].includes(player.position)
  );
  const centralMidfielderData = currentItems?.filter((player) =>
    ["CM"].includes(player.position)
  );
  const wideMidfieldersData = currentItems?.filter((player) =>
    ["LM/LWB", "RM/RWB"].includes(player.position)
  );
  const centralAttackingData = currentItems?.filter((player) =>
    ["CAM/SS"].includes(player.position)
  );
  const wingersData = currentItems?.filter((player) =>
    ["LW", "RW"].includes(player.position)
  );
  const forwards = currentItems?.filter((player) =>
    ["CF/ST"].includes(player.position)
  );

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddOrRemove = (player) => {
    if (!checkSavePlayer(playerShortListData, player?.name_dob)) {
      fetch(`${base_url}/api/v1/player/shortlist`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user?.id,
          player_id: player?.id,
          name_dob: player?.name_dob,
          name: player?.name,
          team: player?.team,
          position: player?.position,
          x_gdar: player?.x_gdar ? player.x_gdar : "0",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            setPlayerShortListData([...playerShortListData, json]);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      const targetedPlayer = checkSavePlayer(
        playerShortListData,
        player?.name_dob
      );
      fetch(`${base_url}/api/v1/player/shortlist/${targetedPlayer?.id}`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: user?.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            const remainingPlayers = playerShortListData?.filter(
              (obj) => obj?.id !== targetedPlayer?.id
            );
            // const targetedPlayer = data?.find((obj) => obj?.id === id);
            // const filteredPlayer = playerShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
            // setData(remainingPlayers);
            setPlayerShortListData(remainingPlayers);
          }
        })

        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };

  function checkSavePlayer(arr, searchName) {
    const foundObject = arr.find((item) => item?.name_dob === searchName);
    return foundObject;
  }

  return (
    <div className=" bg-base-100 pb-2 rounded-lg">
      {/*  Header */}
      <div className="px-6 py-3.5 border-b flex justify-between items-center">
        <h6 className="font-semibold 2xl:text-base">{position}</h6>
        <div className="relative">
          <button className="w-full input-border px-3 py-2 rounded-lg outline-none flex gap-[9px] items-center bg-base-100">
            <p className="text-[13px] 2xl:text-sm font-bold ">Performance</p>
            <img src={arrow} alt="" />
          </button>
        </div>
      </div>

      <div className="overflow-x-auto pt-4 font-semibold metric-table">
        <table className="table w-full">
          {/* head */}
          <thead className="text-neutral-content">
            <tr>
              <th>
                <div className="flex  justify-between cursor-pointer items-center ">
                  <p className="pl-6 w-[140px] min-[1920px]:w-[150px] ">
                    PLAYER
                  </p>
                </div>
              </th>

              <th>
                <div className="flex cursor-pointer justify-between items-center">
                  <p className="w-[100px]">Team</p>
                </div>
              </th>

              <th>
                <p className="w-[auto] ml-4 mr-4 text-center">TRAITS</p>
              </th>

              <th>
                <div className="cursor-pointer w-[40px] min-[1920px]:w-[20px] text-center">
                  AGE
                </div>
              </th>
              <th>
                <div className="cursor-pointer w-[45px] min-[1920px]:w-[20px] text-center">
                  POS
                </div>
              </th>

              <th>
                <div className="cursor-pointer w-[65px] min-[1920px]:w-[40px] min-[1920px]:mr-1 text-center">
                  MINUTES
                </div>
              </th>

              <th>
                <div className="cursor-pointer w-[60px] min-[1920px]:w-[44px] min-[1920px]:mr-1 text-center">
                  SEASON
                </div>
              </th>
              <th>
                <div className="cursor-pointer w-[60px] min-[1920px]:w-[30px] min-[1920px]:mr-4 text-center">
                  LEAGUE
                </div>
              </th>

              <th>
                <div className="w-[250px] px-[33px] grid grid-cols-3 gap-3 items-center text-center">
                  <h6 className="cursor-pointer min-[1800px]:ml-4 min-[1900px]:ml-2 min-[1920px]:ml-4 ">
                    G
                  </h6>
                  <h6 className="cursor-pointer min-[1800px]:ml-3 min-[1900px]:ml-2 min-[1920px]:ml-4">
                    GA
                  </h6>
                  <h6 className="cursor-pointer mr-2 min-[1800px]:ml-2 min-[1900px]:ml-2 min-[1920px]:ml-4">
                    GD
                  </h6>
                </div>
              </th>

              <th>
                <div className="pr-3 2xl:pr-0 grid grid-cols-5 gap-2 items-center w-[240px] 2xl:w-[272px] text-center">
                  <div className="flex cursor-pointer ml-[-4px] w-[60px] justify-between items-center">
                    <h6>BPR</h6>
                  </div>
                  <div className="flex cursor-pointer ml-[-4px] justify-between items-center">
                    <h6>SCR</h6>
                  </div>
                  <div className="flex cursor-pointer ml-[-4px] justify-between items-center">
                    <h6>BRT</h6>
                  </div>
                  <div className="flex cursor-pointer ml-[-4px] justify-between items-center">
                    <h6>DIM</h6>
                  </div>
                  <div className="flex cursor-pointer ml-[-4px] justify-between items-center">
                    <h6>DFR</h6>
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {/* code for goalkeeper */}
            
            
            {gkData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[106px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Goalkeepers
                      </div>
                    </div>
                  </td>
                </tr>
                {gkData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {centerbksData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[106px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Centerbacks
                      </div>
                    </div>
                  </td>
                </tr>
                {centerbksData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {fullbksData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[88px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Fullbacks
                      </div>
                    </div>
                  </td>
                </tr>
                {fullbksData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {defensiveData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[152px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Defensive Midfielders

                      </div>
                    </div>
                  </td>
                </tr>
                {defensiveData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {centralMidfielderData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[138px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Central Midfielders

                      </div>
                    </div>
                  </td>
                </tr>
                {centralMidfielderData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {wideMidfieldersData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[128px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Wide Midfielders

                      </div>
                    </div>
                  </td>
                </tr>
                {wideMidfieldersData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {centralAttackingData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[150px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Attacking Midfielders

                      </div>
                    </div>
                  </td>
                </tr>
                {centralAttackingData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {wingersData.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[83px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Wingers
                      </div>
                    </div>
                  </td>
                </tr>
                {wingersData?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
            {forwards.length > 0 && (
              <>
                <tr>
                  <td>
                    <div className="pl-5 w-[87px]">
                      <div className="bg-[#F7F7F8] text-[#7C86A1] text-xs font-semibold py-[4px] rounded-[4px] px-[8px]">
                      Forwards
                      </div>
                    </div>
                  </td>
                </tr>
                {forwards?.map((player, index) => (
                  <tr className="border-b" key={index}>
                    <td>
                      <div className="font-semibold flex gap-2 mr-8 items-center pl-6">
                        {checkSavePlayer(
                          playerShortListData,
                          player?.name_dob
                        ) ? (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchBlue}
                            alt="icon"
                          />
                        ) : (
                          <img
                            className="cursor-pointer"
                            onClick={() => handleAddOrRemove(player)}
                            src={searchFilter}
                            alt="icon"
                          />
                        )}
                        <h4 className=" min-[1920px]:w-[200px] min-[1920px]:whitespace-pre-wrap ">
                          {player?.name}
                        </h4>
                      </div>
                    </td>
                    <td>
                      <p className="font-medium text-neutral-content">
                        {player?.team}
                      </p>
                    </td>

                    <td>
                      <div className="flex justify-center mx-1 gap-1 text-xs font-semibold">
                        {player.hot > 0 && (
                          <div className=" high w-[34px] h-[28px] rounded flex justify-center items-center">
                            HPF
                          </div>
                        )}
                        {player.cold > 0 && (
                          <div className="low w-[34px] h-[28px] rounded flex justify-center items-center">
                            LPF
                          </div>
                        )}

                        {player.prospect > 0 && (
                          <div className="high w-[34px] h-[28px] rounded flex justify-center items-center">
                            YNG
                          </div>
                        )}
                        {player.underused > 0 && (
                          <div className=" medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            UU
                          </div>
                        )}
                        {player.breakout > 0 && (
                          <div className="medium w-[34px] h-[28px] rounded flex justify-center items-center">
                            FSE
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="">
                      <div className="text-center">
                        {player?.age > 0 ? player?.age : "-"}
                      </div>
                    </td>
                    <td className="">
                      <div className="text-center">{player?.position}</div>
                    </td>
                    <td className=" text-neutral-content">
                      <div className="text-center">{player?.minutes}</div>
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      {player?.season}
                    </td>
                    <td className="font-medium text-center text-neutral-content">
                      <div className="text-center">{player?.league_code}</div>
                    </td>

                    <td>
                      <div className="flex justify-around ">
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 border-r"></div>
                        </div>
                        <div className="grid grid-cols-3 gap-2 font-semibold text-sm">
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gfar ? player.x_gfar : "0.00"}
                          </div>
                          <div className="w-14 h-8 border rounded flex justify-center items-center">
                            {player?.x_gaar ? player.x_gaar : "0.00"}
                          </div>
                          <div
                            className={`w-14 h-8 rounded flex justify-center my-border items-center ${
                              player?.x_gdar < 0 && "box-border-red"
                            } ${player?.x_gdar > 0 && "box-border-green"} ${
                              player?.x_gdar == 0 && "box-border-light"
                            }`}
                          >
                            {player?.x_gdar ? +player.x_gdar : "0.00"}
                          </div>
                        </div>
                        <div className="w-[33px] flex justify-center items-center">
                          <div className="w-px h-6 mr-[3px] border-r"></div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_bp}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.attack_sc}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.ball_ret}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_qual ? player.def_qual : "-"}</p>
                        </div>

                        <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                          <p>{player?.def_quant}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}

          </tbody>
        </table>
      </div>

      {/* Footer */}
      <div className="mt-4 mb-2 text-center">
        <div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              className={`w-8 mx-1 h-8 text-neutral-content  border rounded-md ${
                currentPage === index + 1 && "active-page"
              } `}
              key={index}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {/* <Pagination data={history} /> */}
      {/* {links?.length > 3 && (
        <div className="px-6 py-4 border-t  text-sm">
          <div>
            <div className="flex justify-center text-neutral-content">
              {links?.map((link, index) => (
                <>
                  {link?.label === "&laquo; Previous" ? (
                    <div>
                      <button
                        disabled={Number(page) === 1}
                        onClick={() => {
                          setPage(page > 1 && Number(page) - 1);
                          setPageLoad(true);
                        }}
                        className="w-8  mx-1 h-8 flex justify-center items-center border rounded-md"
                      >
                        <img src={arrow} alt="" className="w-2 rotate-90" />
                      </button>
                    </div>
                  ) : link?.label === "Next &raquo;" ? (
                    <div>
                      <button
                        // disabled={page > page - 1}
                        onClick={() => {
                          setPage(page < totalPage && Number(page) + 1);
                          setPageLoad(true);
                        }}
                        className="w-8 h-8  mx-1 ml-4 flex justify-center items-center border rounded-md"
                      >
                        <img src={arrow} alt="" className="w-2 -rotate-90" />
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handlePageClick(link?.label)}
                      className={`w-8 h-8  mx-4 flex justify-center items-center rounded-md ${
                        page === Number(link?.label) && "active-page"
                      } `}
                    >
                      {link?.label}
                    </button>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default HistoryTable;
