import React, { useContext, useEffect, useState } from "react";
import Pitch from "./Pitch";

const TeamFormation = ({ pitchData, setSelected, team, formations }) => {
  return (
    <div className="team-formation w-3/5  ">
      <div className="formation-view py-[10px] px-1.5 ">
        <Pitch team={team} pitchData={pitchData} />
      </div>
      <div className="bg-base-100 p-3 rounded-lg mt-3">
        <div className=" border-b flex justify-between gap-1 pb-3">
          <div className="flex flex-col gap-4 ">
            {/* first row */}
            <div className="flex justify-between items-center">
              <div className="text-[#9299AA] text-base font-medium ">
                Starting 11
              </div>
              <div className="text-[#22272F] text-base font-semibold ">
                $20,000
              </div>
            </div>
            {/* second row */}
            <div className="flex gap-1 2xl:gap-8 items-center ">
              <div className="text-[#9299AA] text-base font-medium ">Split</div>
              <div className="flex items-center gap-1 ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="6"
                    viewBox="0 0 80 6"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_883_3067)">
                      <rect width="80" height="6" rx="3" fill="#F4F4F6" />
                      <rect width="45" height="6" rx="3" fill="#2C56EB" />
                    </g>
                    <defs>
                      <clipPath id="clip0_883_3067">
                        <rect width="80" height="6" rx="3" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                  45%
                </div>
              </div>
            </div>
            {/* third row */}
            <div className="flex justify-between items-center ">
              <div className="text-[#9299AA] text-base font-medium ">
                Ideal
                <span className="ml-2 text-[#FA4265] font-semibold">
                  (-23%)
                </span>
              </div>

              <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                45%
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 ">
            {/* first row */}
            <div className="flex justify-between items-center">
              <div className="text-[#9299AA] text-base font-medium ">Bench</div>
              <div className="text-[#22272F] text-base font-semibold ">
                $20,000
              </div>
            </div>
            {/* second row */}
            <div className="flex gap-1 2xl:gap-8 items-center ">
              <div className="text-[#9299AA] text-base font-medium ">Split</div>
              <div className="flex items-center gap-1 ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="6"
                    viewBox="0 0 80 6"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_883_3067)">
                      <rect width="80" height="6" rx="3" fill="#F4F4F6" />
                      <rect width="45" height="6" rx="3" fill="#2C56EB" />
                    </g>
                    <defs>
                      <clipPath id="clip0_883_3067">
                        <rect width="80" height="6" rx="3" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                  45%
                </div>
              </div>
            </div>
            {/* third row */}
            <div className="flex justify-between items-center ">
              <div className="text-[#9299AA] text-base font-medium ">
                Ideal
                <span className="ml-2 text-[#FA4265] font-semibold">
                  (-23%)
                </span>
              </div>

              <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                45%
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 ">
            {/* first row */}
            <div className="flex justify-between items-center">
              <div className="text-[#9299AA] text-base font-medium ">Squad</div>
              <div className="text-[#22272F] text-base font-semibold ">
                $20,000
              </div>
            </div>
            {/* second row */}
            <div className="flex gap-1 2xl:gap-8 items-center ">
              <div className="text-[#9299AA] text-base font-medium ">Split</div>
              <div className="flex items-center gap-1 ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="6"
                    viewBox="0 0 80 6"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_883_3067)">
                      <rect width="80" height="6" rx="3" fill="#F4F4F6" />
                      <rect width="45" height="6" rx="3" fill="#2C56EB" />
                    </g>
                    <defs>
                      <clipPath id="clip0_883_3067">
                        <rect width="80" height="6" rx="3" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                  45%
                </div>
              </div>
            </div>
            {/* third row */}
            <div className="flex justify-between items-center ">
              <div className="text-[#9299AA] text-base font-medium ">
                Ideal
                <span className="ml-2 text-[#02B059] font-semibold">
                  (-23%)
                </span>
              </div>

              <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                45%
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 ">
            {/* first row */}
            <div className="flex justify-between items-center">
              <div className="text-[#9299AA] text-base font-medium ">
                Additional
              </div>
              <div className="text-[#22272F] text-base font-semibold ">
                $20,000
              </div>
            </div>
            {/* second row */}
            <div className="flex gap-1 2xl:gap-8 items-center ">
              <div className="text-[#9299AA] text-base font-medium ">Split</div>
              <div className="flex items-center gap-1 ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="6"
                    viewBox="0 0 80 6"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_883_3067)">
                      <rect width="80" height="6" rx="3" fill="#F4F4F6" />
                      <rect width="45" height="6" rx="3" fill="#2C56EB" />
                    </g>
                    <defs>
                      <clipPath id="clip0_883_3067">
                        <rect width="80" height="6" rx="3" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                  45%
                </div>
              </div>
            </div>
            {/* third row */}
            <div className="flex justify-between items-center ">
              <div className="text-[#9299AA] text-base font-medium ">
                Ideal
                <span className="ml-2 text-[#FA4265] font-semibold">
                  (-23%)
                </span>
              </div>

              <div className="text-[#22272F] py-0.5 px-2 rounded-[4px] bg-[#F7F7F8] text-base font-semibold ">
                45%
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[70px] 2xl:gap-[100px] mt-3 items-center">
          <div className="text-[#9299AA] text-base font-medium ">
            Total
          </div>
          <div className="text-[#22272F] text-base font-semibold ">$220,000</div>
        </div>
      </div>
    </div>
  );
};

export default TeamFormation;
