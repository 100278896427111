import React, { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import roundCircle from "../../Images/round-circle.png";
import { DataContext } from "../../Context/DataProvider";
import { formateDate } from "../../customFunctions/formateDate";

const HistoryGraph = ({ player }) => {
  const { base_url, token } = useContext(DataContext);
  const [coachData, setCoachData] = useState(null);
  const [teamData, setTeamData] = useState(null);

  function getDate(dateStr) {
    const dateObject = new Date(dateStr);

    // Get the day and month
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("en-us", { month: "short" });

    // Format the result
    const result = `${day} ${month}`;
    return result;
  }

  //   {
  //     name: ,
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   }
  // ];

  useEffect(() => {
    fetch(`${base_url}/api/v1/coach/chart?coach=${player}`)
      .then((res) => res.json())
      .then((data) => {
        const text = [];
        data?.slice(0, 30)?.forEach((item) => {
          text.push({
            name: getDate(formateDate(item?.date)),
            amt: item?.elo,
            elo: item?.elo,
          });
        });
        setCoachData(text);
        setTeamData(text);
        console.log("text", text);
      });
  }, [player]);

  console.log(coachData);

  return (
    <>
      <div className="bg-base-100 h-[450px] rounded-lg shadow-lg">
        <div className="text-[#22272F] border-b-[1.5px] mb-7 border-[#F2F3F6] px-6 py-4 text-base font-semibold  ">
          Team performance graph
        </div>
        <ResponsiveContainer width="100%" height="77%">
          <LineChart
            width={500}
            height={300}
            data={teamData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="elo" />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="elo"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="bg-base-100 mt-5 rounded-lg shadow-lg">
        <div className="text-[#22272F] border-b-[1.5px] mb-7 border-[#F2F3F6] px-6 py-4 text-base font-semibold  ">
          Coach performance
        </div>

        <div className=" flex  h-[420px] w-full">
          <ResponsiveContainer width="70%" height="95%">
            <LineChart
              width={500}
              height={300}
              data={coachData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis dataKey="elo" />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="elo"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
            </LineChart>
          </ResponsiveContainer>

          <div className=" mt-[-28px] pt-[30px] px-4 border-[#F2F3F6] border-l-[1.5px] basis-[30%]">
            <h4 className="text-[#9299AA] text-xs font-medium ">Key Points</h4>
            <div className="mt-3 flex flex-col gap-3 ">
              <div className="flex gap-2 ">
                <img
                  className="w-[10px] h-[10px] "
                  src={roundCircle}
                  alt="circle"
                />
                <span className="text-[#22272f] mt-[-5px] text-sm font-medium leading-[20px] ">
                  Uses his substitutions late in the game
                </span>
              </div>
              <div className="flex gap-2 ">
                <img
                  className="w-[10px] h-[10px] "
                  src={roundCircle}
                  alt="circle"
                />
                <span className="text-[#22272f] mt-[-5px] text-sm font-medium leading-[20px] ">
                  Distributes progresion evenly among the players
                </span>
              </div>
              <div className="flex gap-2 ">
                <img
                  className="w-[10px] h-[10px] "
                  src={roundCircle}
                  alt="circle"
                />
                <span className="text-[#22272f] mt-[-5px] text-sm font-medium leading-[20px] ">
                  Allows the opposition to dominate once ahead
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryGraph;
