import React, { useContext, useEffect, useState } from "react";
import "./Player.css";
import Sidebar from "./Sidebar";
import summary from "../../Images/summary.png";
import plus from "../../Images/plus.png";
import minus from "../../Images/minus.png";
import query from "../../Images/query.png";
import HistoryTable from "./HistoryTable";
import { debounce } from "lodash";
import { BiSearch } from "react-icons/bi";
import player from "../../Images/player.png";
import loader from "../../Images/loader.gif";
import playersData from "../../players.json";
import contentLoader from "../../Images/loader.gif";
import { makePosition } from "../../customFunctions/makePosition";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { sortBasedOnKey } from "../../customFunctions/sortingFunction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../Context/DataProvider";

const Player = () => {
  const [searchedText, setSearchedText] = useState("");
  const [players, setPlayers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [mainContent, setMainContent] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({});
  const [playerName, setPlayerName] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const [positionSelected, setPositionSelected] = useState("");
  const [leagueSelected, setLeagueSelected] = useState("");
  const [seasonSelected, setSeasonSelected] = useState("");
  const [teamSelected, setTeamSelected] = useState("");
  const [contentLoading, setContentLoading] = useState(false);
  const [playerAllDetails, setPlayerAllDetails] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [latestSeason, setLatestSeason] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [playerHistory, setPlayerHistory] = useState([]);
  const [nameLength, setNameLength] = useState(null);
  const [fixHeight, setFixHeight] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [sortedDataViaPosition, setSortedDataViaPosition] = useState([]);
  const [playerApiCall, setPlayerApiCall] = useState(false);

  const { base_url, token } = useContext(DataContext);

  const navigate = useNavigate();

  const { name_dob } = useParams();
  const data = [
    {
      name: "Defending Impact",
      number: playerInfo?.def_qual ? playerInfo.def_qual : 0,
    },
    {
      name: "Defending Frequency",
      number: playerInfo?.def_quant ? playerInfo.def_quant : 0,
    },
    {
      name: "Ball Retention",
      number: playerInfo?.ball_ret ? playerInfo.ball_ret : 0,
    },
    {
      name: "Shot Creation",
      number: playerInfo?.attack_sc ? playerInfo.attack_sc : 0,
    },
    {
      name: "Ball Progression",
      number: playerInfo?.attack_bp ? playerInfo.attack_bp : 0,
    },
  ];

  const frequency = [
    {
      name: "Aerial",
      value: playerInfo?.aerial,
    },
    {
      name: "Dribbling",
      value: playerInfo?.carry,
    },
    {
      name: "Box Receptions",
      value: playerInfo?.receive,
    },
    {
      name: "Shooting",
      value: playerInfo?.shoot,
    },
    {
      name: "Recovering",
      value: playerInfo?.recover,
    },
    {
      name: "Disrupting",
      value: playerInfo?.disrupt,
    },
    {
      name: "Crossing",
      value: playerInfo?.cross,
    },
    {
      name: "Link-up passing",
      value: playerInfo?.link,
    },
    {
      name: "Forward passing",
      value: playerInfo?.forward,
    },
    {
      name: "Short Passes",
      value: playerInfo?.pass1,
    },
    {
      name: "Medium Passes",
      value: playerInfo?.pass2,
    },
    {
      name: "Long Passes",
      value: playerInfo?.pass3,
    },
  ];

  let selectPosition;
  if (positionSelected === "position") {
    selectPosition = "";
  } else {
    selectPosition = positionSelected;
  }

  // getting all data when a player is searched and clicked
  useEffect(() => {
    if ((playerName !== "" || name_dob !== "") && playerApiCall) {
      setContentLoading(true);
      fetch(`${base_url}/api/v1/player/details?name_dob=${name_dob}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setPlayerAllDetails(data);
          setPlayerApiCall(false);
          setLoading(false);
          setContentLoading(false);
        });
    }
  }, [name_dob, playerApiCall, playerName]);

  useEffect(() => {
    if (name_dob !== "") {
      handlePlayerDetail(name_dob);
    }
  }, [name_dob]);

  // making sorted api data to an array
  useEffect(() => {
    if (playerAllDetails !== undefined && playerName !== "") {
      const result = Object.values(playerAllDetails);

      const newData = [];
      result.length &&
        result.map((data) => data.map((item) => newData.push(item)));

      if (newData.length) {
        setFinalData(newData);
      }
    }
  }, [playerAllDetails, playerName]);

  // getting player info by latest season and then getting latest minute data and setup into selected position,season,league,team
  useEffect(() => {
    setInfoLoading(true);

    if (finalData.length && firstClick) {
      if (finalData.length) {
        const newestSeasonObj = finalData?.find((data) =>
          Math.max.apply(data.season)
        );

        setLatestSeason(newestSeasonObj?.season);
        if (latestSeason !== "") {
          const arrByLatestSeason = finalData?.filter(
            (data) => data?.season === latestSeason
          );

          let maxObjectByMInutes = null;
          let maxValue = Number.NEGATIVE_INFINITY;

          for (const obj of arrByLatestSeason) {
            if (Number(obj.minutes) > maxValue) {
              maxObjectByMInutes = obj;
              maxValue = Number(obj.minutes);
            }
          }
          const player = maxObjectByMInutes;

          setPlayerInfo(player);
          setPositionSelected(player?.position);
          setSeasonSelected(player?.season);
          setLeagueSelected(player?.league);
          setTeamSelected(player?.team_short_name);
        }
      }
    }
    setInfoLoading(false);
  }, [finalData, latestSeason, firstClick]);

  // get data for history
  useEffect(() => {
    if (finalData.length) {
      const arrByPosition = finalData?.filter(
        (data) => data?.position === selectPosition
      );

      setPlayerHistory(arrByPosition);
    }
  }, [finalData, selectPosition]);

  useEffect(() => {
    if (searchedText !== "") {
      setLoading(true);

      const searchWords = searchedText.trim().split(/\s+/);
      const filteredResults = playersData.filter((player) => {
        const { name_team } = player;
        const lowerCaseName = name_team.toLocaleLowerCase();

        return searchWords.every((word) =>
          lowerCaseName.includes(word.toLowerCase())
        );
      });

      setPlayers(filteredResults.slice(0, 10));
      setLoading(false);
      setNotFound(false);
    } else {
      setLoading(false);
    }
  }, [searchedText]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handlePlayerDetail = (name) => {
    setPlayerApiCall(true);
    setPlayerName(name);
    setContentLoading(true);
    setSearchedText("");
    setSearchDropdown(false);
    setMainContent(true);
    setPositionSelected("position");
    setLeagueSelected("league");
    setSeasonSelected("season");
    setTeamSelected("team");
    setFirstClick(true);
    setFixHeight(false);

    // navigate();
  };

  useEffect(() => {
    if (finalData.length > 0) {
      const result = [...finalData].sort((a, b) => {
        if (
          Number(a.career_games_in_position) >
          Number(b.career_games_in_position)
        ) {
          return -1;
        }
        if (
          Number(a.career_games_in_position) <
          Number(b.career_games_in_position)
        ) {
          return 1;
        }
        return 0;
      });
      const uniqueArray = [];
      const positions = [];

      result.forEach((obj) => {
        if (!positions.includes(obj.position)) {
          positions.push(obj.position);
          uniqueArray.push(obj);
        }
      });
      setSortedDataViaPosition(uniqueArray?.slice(0, 3));
    }
  }, [finalData]);

  console.log('pos', sortedDataViaPosition);

  useEffect(() => {
    if (searchedText === "") {
      setPlayers(null);
    }
  }, [searchedText]);

  if (contentLoading) {
    return (
      <div
        id="preloader"
        className="d-flex align-items-center justify-content-center"
      >
        <span class="text-center">
          <img
            className="mx-auto d-block"
            width="300"
            alt="loader"
            src={contentLoader}
          />
        </span>
      </div>
    );
  }

  const handleNavigateProfile = () => {
    setContentLoading(true);
    setPlayerAllDetails([]);
    console.log(contentLoading);
  };
  // console.log(playerAllDetails);

  const text = (e) => {
    setSearchedText(e.target.value);
    setSearchDropdown(true);
  };
  const debounceOnChange = debounce(text, 400);

  let content;
  if (loading && players === null && searchedText.length > 0) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (players?.length === 0 && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }

  if (contentLoading) {
    <div id="preloader" className="flex items-center justify-center">
      <div className="flex loader-box justify-center items-center ">
        <span className="text-center">
          <img
            className="mx-auto d-block"
            alt="loader"
            width="50"
            src={contentLoader}
          />
        </span>
      </div>
    </div>;
  }

  if (
    players?.length > 0 &&
    !loading &&
    players !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <ul className=" player-list max-h-[350px] overflow-y-scroll b w-full z-50 shadow-lg bg-base-100 p-2 rounded-box">
        <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">PLAYERS</h6>
        {players?.map((player, index) => (
          <li key={index} onClick={() => handlePlayerDetail(player.name_dob)}>
            <div className="p-3 pt-2">
              <div
                onClick={handleNavigateProfile}
                className="text-sm cursor-pointer"
              >
                <Link to={`/profile/${player.name_dob}`}>
                  <h6 className="text-semibold">{player.name_dob}</h6>
                  <p className="text-neutral-content text-medium">
                    {player.age} yr • {player.team}
                  </p>
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  const halfCircleStyles = buildStyles({
    rotation: 3 / 4,
    strokeLinecap: "round",
    trailColor: "#F7F7F8",
    pathColor: "#02B059",
    pathTransition: "none", // Disable animation
    transform: "rotate(180deg)", // Rotate the circle by 180 degrees
    borderRadius: "50%", // Make the circle shape half-circle
    height: "50%", // Set the desired height of the half circle
    marginTop: "25%", // Adjust the positioning to center the half circle vertically
  });

  let finalRangeValue;
  if (playerInfo?.position_rank === null) {
    finalRangeValue = 0;
  } else {
    finalRangeValue =
      100 -
      (Number(playerInfo?.position_rank) /
        Number(playerInfo?.position_rank_max)) *
        100;
  }

  return (
    <div className="pt-5 md:pt-10 pb-5 px-5 md-px-10 min-h-screen">
      <div className="player-container flex flex-col md:flex-row gap-3 ">
        <Sidebar
          playerInfo={playerInfo}
          positionData={sortedDataViaPosition}
          content={content}
          debounceOnChange={debounceOnChange}
          positionSelected={positionSelected}
          setPositionSelected={setPositionSelected}
          seasonSelected={seasonSelected}
          setSeasonSelected={setSeasonSelected}
          leagueSelected={leagueSelected}
          setLeagueSelected={setLeagueSelected}
          teamSelected={teamSelected}
          setTeamSelected={setTeamSelected}
          finalData={finalData}
          setFirstClick={setFirstClick}
          setPlayerInfo={setPlayerInfo}
          position={positionSelected}
        />
        {playerHistory.length > 0 && (
          <div className="main flex gap-3 md:gap-0 flex-col md:flex-row ">
            <div className="left w-[100%] md:w-[75%] ">
              <div className="top flex flex-col md:flex-row gap-3">
                <div className="summary rounded-lg shadow-lg w-[100%] md:w-[33%] bg-white">
                  <div className="header flex items-center">
                    <div className="title">Player Summary</div>
                  </div>

                  <div className="content">
                    <div className="w-[250px] mx-auto md:w-full h-[160px]">
                      <CircularProgressbarWithChildren
                        value={finalRangeValue}
                        circleRatio={0.5}
                        styles={halfCircleStyles}
                      >
                        <div className=" mt-[-25%] ">
                          <h4 className=" text-[#22272F] font-[600] text-[32px] text-center">
                            {playerInfo?.position_rank
                              ? makePosition(playerInfo?.position_rank)
                              : "-"}
                          </h4>
                          <h5 className="text-[#9299AA] font-[500] max-[1400px]:text-[14px] text-[16px] text-center ">
                            out of
                            {playerInfo?.position_rank_max
                              ? " " + playerInfo?.position_rank_max + " "
                              : " - "}
                            in {playerInfo?.league_code}
                          </h5>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>

                    <div className="box px-3 py-[10px] bg-[#F7F7F8] mt-6 flex justify-between items-center ">
                      <div className="text-sm font-[500] text-[#9299AA] ">
                        Attacking
                      </div>
                      <div className="text-sm font-[600] text-[#22272F]">
                        {playerInfo?.gd_rank_in_team_off
                          ? makePosition(
                              Number(playerInfo?.gd_rank_in_team_off)
                            )
                          : "-"}{" "}
                        <span className="text-[#9299AA]">out of </span>
                        {playerInfo?.position_rank_max
                          ? playerInfo.position_rank_max
                          : "-"}
                      </div>
                    </div>
                    <div className="box px-3 py-[10px] bg-[#F7F7F8] mt-3 flex justify-between items-center ">
                      <div className="text-sm font-[500] text-[#9299AA] ">
                        Defending
                      </div>
                      <div className="text-sm font-[600] text-[#22272F]">
                        {playerInfo?.gd_rank_in_team_def
                          ? makePosition(
                              Number(playerInfo?.gd_rank_in_team_def)
                            )
                          : "-"}
                        <span className="text-[#9299AA]"> out of </span>{" "}
                        {playerInfo?.position_rank_max
                          ? playerInfo.position_rank_max
                          : "-"}
                      </div>
                    </div>
                    <div className="box px-3 py-[10px] bg-[#F7F7F8] mt-3 flex justify-between items-center ">
                      <div className="text-sm font-[500] text-[#9299AA] ">
                        Availability
                      </div>
                      <div className="text-sm font-[600] text-[#22272F]">
                        {playerInfo?.availability_rank
                          ? makePosition(Number(playerInfo?.availability_rank))
                          : "-"}
                        <span className="text-[#9299AA]"> out of </span>{" "}
                        {playerInfo?.available_players
                          ? playerInfo.available_players
                          : "-"}
                      </div>
                    </div>
                    <div className="mt-8">
                      <div className="flex font-[600] justify-between items-center text-[#7C86A1] text-xs ">
                        <div>PROS</div>
                        <div>CONS</div>
                      </div>
                      <div className="flex justify-between ">
                        <div className=" text-[#22272F] text-sm font-[500] mt-3  ">
                          {playerInfo?.pro?.split(",").map((value) => (
                            <div className="flex pros mb-3 gap-1 items-center ">
                              {playerInfo?.pro !== "" ? (
                                <>
                                  <div className="pro-icon">
                                    <img src={plus} alt="plus-icon" />
                                  </div>
                                  <div className="pro-text leading-[14px] ">
                                    {value}
                                  </div>
                                </>
                              ) : (
                                <div className=""></div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className=" text-[#22272F] text-sm font-[500] mt-3  ">
                          {playerInfo?.con?.split(",").map((value) => (
                            <div className="cons-box ">
                              <div className="leading-[14px]">{value}</div>
                              <div className=" con-icon ">
                                <img src={minus} alt="minus-icon" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="player-performance rounded-lg shadow-lg w-[100%] md:w-[37%] bg-white ">
                  <div className="header flex items-center">
                    <div className="title">Performance</div>
                  </div>
                  <div className="content">
                    {playerInfo?.position_rank ? (
                      <div className="text-[#22272F] text-sm md:text-base font-[500]">
                        <span className="font-[600]">
                          {playerInfo?.name + " "}
                        </span>
                        <span className="text-[#9299AA]">
                          has a Goal Impact Rating of
                        </span>{" "}
                        <span className="font-[600]">
                          {playerInfo?.x_gdar > 0
                            ? `+${playerInfo?.x_gdar}`
                            : playerInfo?.x_gdar}
                        </span>{" "}
                        <span className="text-[#9299AA]">and ranks</span>{" "}
                        <span className="font-[600]">
                          {playerInfo?.position_rank
                            ? makePosition(playerInfo?.position_rank)
                            : "-"}
                        </span>{" "}
                        <span className="text-[#9299AA]">best</span>{" "}
                        <span className="font-[600]">
                          <span className="font-[600]">
                            {playerInfo?.position + " "}
                          </span>
                        </span>{" "}
                        <span className="text-[#9299AA]">in the</span>{" "}
                        <span className="font-[600]">
                          {playerInfo?.league + " "}
                        </span>
                        <span className="text-[#9299AA]"> based on</span>
                        <span className="font-[600]">
                          {" " + playerInfo?.minutes}
                        </span>{" "}
                        <span className="text-[#9299AA]">minutes</span>
                      </div>
                    ) : (
                      <div>
                        <div className="text-[#22272F] text-sm md:text-base font-[500]">
                          <span className="font-[600]">
                            {playerInfo?.name + " "}
                          </span>
                          <span className="text-[#9299AA]">
                            has a Goal Impact Rating of
                          </span>{" "}
                          <span className="font-[600]">
                            {playerInfo?.x_gdar > 0
                              ? `+${playerInfo?.x_gdar}`
                              : playerInfo?.x_gdar}
                          </span>{" "}
                          <span className="text-[#9299AA]">
                            but due to his limited minutes at
                          </span>{" "}
                          <span className="font-[600]">
                            {playerInfo?.position + " "}
                          </span>
                          <span className="text-[#9299AA]">
                            {" "}
                            is not ranked yet
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="performance-contain-container">
                      <div className="px-3 rounded py-[10px] bg-[#F7F7F8] mt-5 lg:mt-7 xl:mt-9 2xl:mt-10 flex justify-between items-center ">
                        <div className="text-base font-[500] text-[#9299AA] ">
                          Goal Difference Impact
                        </div>
                        <div className="text-base font-[600] text-[#22272F]">
                          {playerInfo?.x_gdar > 0
                            ? `+${playerInfo?.x_gdar}`
                            : playerInfo?.x_gdar}
                        </div>
                      </div>
                      <div className="overall-performance mt-10 lg:mt-7 xl:mt-9 2xl:mt-10">
                        <div>
                          {data?.map((item) => (
                            <div className="mb-3">
                              <div className="flex justify-between items-center">
                                <div className="text-[#9299AA] text-sm font-[500] ">
                                  {item?.name}
                                </div>
                                <div className="text-[#22272F] text-sm font-[600] ">
                                  {item?.number}
                                </div>
                              </div>
                              <div
                                className={` mt-1 ${
                                  Number(item?.number) < 34 && "prog-low"
                                } ${
                                  item.number > 33 &&
                                  item.number < 66 &&
                                  "prog-middle"
                                } ${item.number > 65 && "prog-high"}`}
                              >
                                <progress
                                  className={`progress w-full`}
                                  value={item.number}
                                  max="100"
                                ></progress>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-[100%] md:w-[30%] ">
                  <div className="skill h-[40%] rounded-lg shadow-lg bg-white ">
                    <div className="header flex items-center">
                      <div className="title">Skill Rating</div>
                    </div>
                    <div className="content">
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Dribbling
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dribble < 34 &&
                            playerInfo?.dribble > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dribble > 33 &&
                            playerInfo?.dribble < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dribble > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dribble == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.dribble > 0 ? playerInfo?.dribble : "-"}
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Aerial
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open < 34 &&
                            playerInfo?.open > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open > 33 &&
                            playerInfo?.open < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.open > 0 ? playerInfo?.open : "-"}
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Tackling
                        </div>
                        <div
                          className={` ${
                            playerInfo?.tackle < 33 &&
                            playerInfo?.tackle > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.tackle > 33 &&
                            playerInfo?.tackle < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.tackle > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.tackle == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.tackle > 0 ? playerInfo?.tackle : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="finishing rounded-lg shadow-lg h-[58%] mt-3 bg-white ">
                    <div className="header flex items-center">
                      <div className="title">Finishing</div>
                    </div>
                    <div className="content">
                      <div className=" text-[#9299AA] text-sm md:text-base font-[500] ">
                        Outperforms by{" "}
                        <span className=" text-[#22272F] ">-</span> goals /
                        season
                      </div>
                      <div className="flex mt-3 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Foot (Open Play)
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open_foot < 33 &&
                            playerInfo?.open_foot > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open_foot > 33 &&
                            playerInfo?.open_foot < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open_foot > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open_foot == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.open_foot > 0
                            ? playerInfo.open_foot
                            : "-"}
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Head (Open Play)
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open_head < 33 &&
                            playerInfo?.open_head > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open_head > 33 &&
                            playerInfo?.open_head < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open_head > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open_head == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.open_head > 0
                            ? playerInfo.open_head
                            : "-"}
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Foot (Set Play)
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dead_foot < 33 &&
                            playerInfo?.dead_foot > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dead_foot > 33 &&
                            playerInfo?.dead_foot < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dead_foot > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dead_foot == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[500]`}
                        >
                          {playerInfo?.dead_foot > 0
                            ? playerInfo.dead_foot
                            : "-"}
                        </div>
                      </div>
                      <div className="flex mt-2 justify-between items-center ">
                        <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                          Head (Set Play)
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dead_head < 33 &&
                            playerInfo?.dead_head > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dead_head > 33 &&
                            playerInfo?.dead_head < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dead_head > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dead_head == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-sm md:text-base font-[500]`}
                        >
                          {playerInfo?.dead_head > 0
                            ? playerInfo.dead_head
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <HistoryTable playerDetails={playerHistory} />
              </div>
            </div>
            <div className="right pl-0 md:pl-3  w-[100%] md:w-[25%]">
              <div className="frequency rounded-lg shadow-lg bg-white ">
                <div className="header flex justify-between items-center">
                  <div className="title ">Frequency</div>
                  <div>
                    <img src={query} alt="query" />
                  </div>
                </div>
                <div className="content mt-2">
                  {frequency?.map((item) => (
                    <div className="flex mt-3 justify-between items-center ">
                      <div className="text-[#22272F] text-sm md:text-base font-[500] ">
                        {item.name}
                      </div>
                      <div>
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="6"
                            width="4"
                            height="16"
                            rx="1"
                            fill={item.value > 0 ? "#2C56EB" : "#9299AA"}
                            fill-opacity={item.value > 0 ? 1 : 0.32}
                          />
                          <rect
                            x="6"
                            y="6"
                            width="4"
                            height="16"
                            rx="1"
                            fill={item.value > 20 ? "#2C56EB" : "#9299AA"}
                            fill-opacity={item.value > 20 ? 1 : 0.32}
                          />
                          <rect
                            x="12"
                            y="6"
                            width="4"
                            height="16"
                            rx="1"
                            fill={item.value > 40 ? "#2C56EB" : "#9299AA"}
                            fill-opacity={item.value > 40 ? 1 : 0.32}
                          />
                          <rect
                            x="18"
                            y="6"
                            width="4"
                            height="16"
                            rx="1"
                            fill={item.value > 60 ? "#2C56EB" : "#9299AA"}
                            fill-opacity={item.value > 60 ? 1 : 0.32}
                          />
                          <rect
                            x="24"
                            y="6"
                            width="4"
                            height="16"
                            rx="1"
                            fill={item.value > 80 ? "#2C56EB" : "#9299AA"}
                            fill-opacity={item.value > 80 ? 1 : 0.32}
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* for mobile */}
            <div className="mt-[-12px] hidden">
              <HistoryTable playerDetails={playerHistory} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Player;
