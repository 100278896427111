import React, { useState } from "react";
import "./PlayerInfoTable.css";

const players = [
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_C: '99',
    Cost_D: '99',
  },
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_D: '99',
    Cost_C: '99',
  },
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_C: '99',
    Cost_D: '99',
  },
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_D: '99',
    Cost_C: '99',
  },
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_C: '99',
    Cost_D: '99',
  },
  {
    Player: "Taylor Mark",
    Position: "LCB",
    Status: "New Signing",
    Birth_Date: "11/12/1998",
    Contract: "11/12/2023",
    Agent: "USA",
    AFCON: "99",
    HG: '99',
    U21: '99',
    Basic_PW: '99',
    Basic_PA: '99',
    Loyality_Fees: '99',
    Cost_A: '99',
    Cost_B: '99',
    Cost_D: '99',
    Cost_C: '99',
  },
]

const FinancePlayerInfo = () => {
  const [startIndex, setStartIndex] = useState(6);
  const allColumns = Object.keys(players[0]);
  const columns = allColumns?.slice(startIndex,startIndex+6);
  const handleNext = () => {
    
      setStartIndex(startIndex + 6);
    
  };
  const handlePrevious = () => {
    
      setStartIndex(startIndex - 6);
    
  };
  console.log(columns);
  return (
    <div className="your-teams finance pt-10 min-h-screen px-10 py-12">
      <div className="bg-base-100 rounded-lg">
        <div className="px-6 py-3.5 border-b flex justify-between items-center">
          <h6 className="font-semibold 2xl:text-base">Fenida FC finances</h6>
          <div className="relative">
            <button className="w-full input-border px-3 py-2 rounded-lg outline-none flex gap-[9px] items-center bg-base-100">
              <p className="text-[13px] text-[#22272F] 2xl:text-sm font-semibold ">
                Manage
              </p>
            </button>
          </div>
        </div>
        <div className="px-6 py-8">
          <div className="flex gap-2 ">
            <div className="bg-[#F7F7F8] py-1 px-2 text-center rounded-[4px] basis-[40%] text-[#7C86A1] text-xs font-semibold">
              PLAYER
            </div>
            <div className="bg-[#F7F7F8] py-1 px-2 text-center rounded-[4px] basis-[27%] text-[#7C86A1] text-xs font-semibold">
              NATIONAL
            </div>
            <div className="bg-[#F7F7F8] py-1 px-2 text-center rounded-[4px] basis-[27%] text-[#7C86A1] text-xs font-semibold">
              CONTRACT
            </div>
            <div onClick={handlePrevious} className={`bg-[#F7F7F8] cursor-pointer py-1 px-2 text-center rounded-[4px] basis-[3%] text-[#7C86A1] text-xs font-semibold`}>
              <div className="flex justify-center mt-1 items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="8"
                viewBox="0 0 6 8"
                fill="none"
              >
                <path
                  d="M5.2735 7.06L2.22016 4L5.2735 0.94L4.3335 4.76995e-08L0.333496 4L4.3335 8L5.2735 7.06Z"
                  fill="#7C86A1"
                />
              </svg>
              </div>
            </div>
            <div onClick={handleNext} className={`bg-[#F7F7F8] py-1 px-2 text-center rounded-[4px] basis-[3%] text-[#7C86A1] text-xs font-semibold`}>
              <div className="flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.7265 4.94L8.77984 8L5.7265 11.06L6.6665 12L10.6665 8L6.6665 4L5.7265 4.94Z"
                  fill="#7C86A1"
                />
              </svg>
              </div>
            </div>
          </div>

          <table className="mt-4 finance-table">
            <thead>
              <tr>
                <th colSpan="2">Player</th>
                <th>Status</th>
                <th>Birth Date</th>
                <th>Contract</th>
                <th>Agent</th>
                {
                  columns?.map((item,index)=><th key={index} >{item}</th>)
                }
                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-[#FBFBFC]" colSpan="6">
                  <div className="pl-3 text-[#9299AA] text-left ">Total</div>
                </td>
                
                {
                  Array.from({ length: columns?.length }).map((_,index)=><td key={index} >99</td>)
                }
              </tr>
                             
              {
                players?.map((player,index)=><tr key={index} >
                  <td>{player?.Player}</td>
                  <td>{player?.Position}</td>
                  <td>{player?.Status}</td>
                  <td>{player?.Birth_Date}</td>
                  <td>{player?.Contract}</td>
                  <td>{player?.Agent}</td>
                  {
                    columns?.map((field,index)=><td key={index} >{player[field]}</td>)
                  }
                </tr>)
              }
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FinancePlayerInfo;
