import React, { useContext, useState } from "react";
import searchFilter from "../../Images/search-filter.png";
import searchBlue from "../../Images/search-blue.png";
import { DataContext } from "../../Context/DataProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { sortBasedOnKey } from "../../customFunctions/sortingFunction";
import "./Survey.css";

const Table = ({
    history,
    selectedTeamQualities,
    setPlayerHistory,
    filteredabbr,
    allRoles,
}) => {
    const {
        base_url,
        token,
        user,
        playerShortListData,
        setPlayerShortListData,
    } = useContext(DataContext);

    const [togglePlayerSort, setTogglePlayerSort] = useState(true);
    const [toggleTeamSort, setToggleTeamSort] = useState(true);
    const [toggleAgeSort, setToggleAgeSort] = useState(true);
    const [togglePosSort, setTogglePosSort] = useState(true);
    const [toggleRoleSort, setToggleRoleSort] = useState(true);
    const [toggleMinsSort, setToggleMinsSort] = useState(true);
    const [toggleLeagueSort, setToggleLeagueSort] = useState(true);
    const [toggleCtrSort, setToggleCtrSort] = useState(true);
    const [toggleLvlSort, setToggleLvlSort] = useState(true);
    const [togglePotSort, setTogglePotSort] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

    // Get the current page's data
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getColorForPot = (value) => {
        // ELT, PRM, STR, CMP, LWT
        if(value === 'ELT'){
            return 'text-[#02b059] bg-[#02b05914]';
        }else if(value === 'PRM'){
            return 'text-[#02b059] bg-[#02b05914]';
        }else if(value === 'STR'){
            return 'text-[#F29445] bg-[#F2944514]';
        }else if(value === 'CMP'){
            return 'text-[#F29445] bg-[#F2944514]';
        }else if(value === 'LWT'){
            return 'text-[#fa4265] bg-[#fa426514]';
        }
    };

    function getDynamicValue(value) {
        const intValue = parseInt(value);
        if (intValue === -3) {
            return "Very Poor";
        }
        if (intValue === -2) {
            return "Poor";
        }
        if (intValue === -1) {
            return "Below Average";
        }
        if (intValue === 0) {
            return "Average";
        }
        if (intValue === -0) {
            return "Average";
        }
        if (intValue === 1) {
            return "Above Average";
        }
        if (intValue === 2) {
            return "Good";
        }
        if (intValue === 3) {
            return "Excellent";
        }
    }

    const handleAddOrRemove = (player) => {
        console.log(!checkSavePlayer(playerShortListData, player?.player_dob));
        // console.log(player?.player_dob);
        if (!checkSavePlayer(playerShortListData, player?.player_dob)) {
            fetch(`${base_url}/api/v1/player/shortlist`, {
                method: "POST",
                body: JSON.stringify({
                    user_id: user?.id,
                    player_id: user?.id,
                    name_dob: player?.player_dob,
                    name: player?.player_dob,
                    team: player?.team,
                    position: player?.pos,
                    x_gdar: player?.x_gdar ? player.x_gdar : "0",
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json) {
                        setPlayerShortListData([...playerShortListData, json]);
                    }
                })
                .catch((error) => {
                    if (error) {
                        // console.log(error);
                    }
                });
        } else {
            const targetedPlayer = checkSavePlayer(
                playerShortListData,
                player?.player_dob
            );
            fetch(`${base_url}/api/v1/player/shortlist/${targetedPlayer?.id}`, {
                method: "DELETE",
                body: JSON.stringify({
                    user_id: user?.id,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result) {
                        const remainingPlayers = playerShortListData?.filter(
                            (obj) => obj?.id !== targetedPlayer?.id
                        );
                        // const targetedPlayer = data?.find((obj) => obj?.id === id);
                        // const filteredPlayer = playerShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
                        // setData(remainingPlayers);
                        setPlayerShortListData(remainingPlayers);
                    }
                })

                .catch((error) => {
                    if (error) {
                        // console.log(error);
                    }
                });
        }
    };

    const GetRoleAbbr = (role) => {
        const roleAbbr = allRoles.find((item) => item.role === role)?.short;

        return roleAbbr;
    };

    function checkSavePlayer(arr, searchName) {
        const foundObject = arr.find((item) => item?.name_dob === searchName);
        return foundObject;
    }

    return (
        <div className="mt-6 bg-white rounded-lg px-6">
            <div class="flex justify-between items-center py-4 border-b">
                <h6 class="font-semibold 2xl:text-base">
                    {history?.length} Results
                </h6>
                <div class="flex gap-2">
                    <button class="px-2 py-1 flex border border-white font-[600] justify-center items-center  text-white rounded cursor-default text-xs">
                        Reset
                    </button>
                    <button class="px-2 py-1 flex  text-white justify-center items-center rounded cursor-default  text-xs">
                        Apply
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="table table-xs w-full">
                    <thead>
                        <tr className="text-neutral-content font-semibold text-xs">
                            <th
                                className="px-0 cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "player_dob",
                                        togglePlayerSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setTogglePlayerSort(!togglePlayerSort);
                                    setCurrentPage(1);
                                }}
                            >
                                PLAYER
                            </th>
                            <th
                                width="120px"
                                className="px-0 cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "team",
                                        toggleTeamSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleTeamSort(!toggleTeamSort);
                                    setCurrentPage(1);
                                }}
                            >
                                TEAM
                            </th>
                            <th
                                width="57px"
                                className="px-2 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "age",
                                        toggleAgeSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleAgeSort(!toggleAgeSort);
                                    setCurrentPage(1);
                                }}
                            >
                                AGE
                            </th>
                            <th
                                width="57px"
                                className="px-2 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "pos",
                                        togglePosSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setTogglePosSort(!togglePosSort);
                                    setCurrentPage(1);
                                }}
                            >
                                POS
                            </th>
                            <th
                                width="57px"
                                className="px-2 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "roles",
                                        toggleRoleSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleRoleSort(!toggleRoleSort);
                                    setCurrentPage(1);
                                }}
                            >
                                ROLE
                            </th>
                            <th
                                width="57px"
                                className="px-2 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "season_minutes",
                                        toggleMinsSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleMinsSort(!toggleMinsSort);
                                    setCurrentPage(1);
                                }}
                            >
                                MINS
                            </th>
                            <th
                                width="57px"
                                className="px-2 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "league_code",
                                        toggleLeagueSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleLeagueSort(!toggleLeagueSort);
                                    setCurrentPage(1);
                                }}
                            >
                                Leag
                            </th>
                            <th width="56px" className="px-2 text-center">
                                CTR END
                            </th>
                            <th
                                width="55px"
                                className="px-0 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "current",
                                        toggleLvlSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setToggleLvlSort(!toggleLvlSort);
                                    setCurrentPage(1);
                                }}
                            >
                                <ReactTooltip
                                    id="lvl"
                                    // variant="info"
                                    place="bottom"
                                    opacity={1}
                                    style={{
                                        backgroundColor: "transparent",
                                        padding: 0,
                                    }}
                                >
                                    <span className="p-3 border rounded-lg bg-white text-[#9299AA]">
                                        Current Level
                                    </span>
                                </ReactTooltip>
                                <span data-tooltip-id="lvl">LVL</span>
                            </th>
                            <th
                                width="55px"
                                className="px-0 text-center cursor-pointer"
                                onClick={() => {
                                    sortBasedOnKey(
                                        "pot",
                                        togglePotSort,
                                        history,
                                        setPlayerHistory
                                    );
                                    setTogglePotSort(!togglePotSort);
                                    setCurrentPage(1);
                                }}
                            >
                                <ReactTooltip
                                    id="pot"
                                    // variant="info"
                                    place="bottom"
                                    opacity={1}
                                    style={{
                                        backgroundColor: "transparent",
                                        padding: 0,
                                    }}
                                >
                                    <span className="p-3 border rounded-lg bg-white text-[#9299AA]">
                                        Potential Level
                                    </span>
                                </ReactTooltip>

                                <span data-tooltip-id="pot">POT</span>
                            </th>

                            {filteredabbr?.map((item, index) => (
                                <th
                                    width="55px"
                                    className="px-0 text-center cursor-pointer"
                                    key={index}
                                    onClick={() => {
                                        sortBasedOnKey(
                                            item.apikey,
                                            togglePlayerSort,
                                            history,
                                            setPlayerHistory
                                        );
                                        setTogglePlayerSort(!togglePlayerSort);
                                        setCurrentPage(1);
                                    }}
                                >
                                    <ReactTooltip
                                        id={item.shortName}
                                        // variant="info"
                                        place="bottom"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: 0,
                                        }}
                                    >
                                        <span className="p-3 border rounded-lg bg-white text-[#9299AA]">
                                            {item.fullname}
                                        </span>
                                    </ReactTooltip>
                                    <span data-tooltip-id={item.shortName}>
                                        {item.shortName}
                                    </span>
                                </th>
                            ))}
                            <th width="55px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((player, index) => (
                            <tr
                                className="border-b border-[#F2F3F6]"
                                key={index}
                            >
                                <td className="py-4 font-semibold flex gap-1 ">
                                    {checkSavePlayer(
                                        playerShortListData,
                                        player?.player_dob
                                    ) ? (
                                        <img
                                            className="cursor-pointer"
                                            onClick={() =>
                                                handleAddOrRemove(player)
                                            }
                                            src={searchBlue}
                                            alt="icon"
                                        />
                                    ) : (
                                        <img
                                            className="cursor-pointer"
                                            onClick={() =>
                                                handleAddOrRemove(player)
                                            }
                                            src={searchFilter}
                                            alt="icon"
                                        />
                                    )}
                                    {player.player_dob.split(' ').slice(0, -1).join(' ')}
                                    {/* {player.player_dob.split(" ")[0][0]}
                                    {". "}
                                    {
                                        player.player_dob.split(" ")[
                                            player.player_dob.split(" ")
                                                .length - 2
                                        ]
                                    } */}
                                </td>
                                <td className="py-4 text-neutral-content">
                                    {player.team}
                                </td>
                                <td className="py-4 text-center font-semibold">
                                    {player.age}
                                </td>
                                <td className="py-4 text-center font-semibold">
                                    {player.pos === "Forward (CF)" ? "FWD" : ""}
                                    {player.pos === "Forward (Wing)" ? "W" : ""}
                                    {player.pos ===
                                    "Midfield (Center, Offensive)"
                                        ? "CM/AM"
                                        : ""}
                                    {player.pos === "Midfield (Defensive)"
                                        ? "DM"
                                        : ""}
                                    {player.pos === "Defenders (Central)"
                                        ? " CB"
                                        : ""}
                                    {player.pos === "Defender (Wide)"
                                        ? "FB"
                                        : ""}
                                    {player.pos === "Goalkeepers" ? "GK" : ""}
                                </td>
                                <td className="py-4 text-center text-neutral-content">
                                    {/* {player.roles.split(' ')[0][0]}-{player.roles.split(' ')[1][0]} */}
                                    <ReactTooltip
                                        id={"role" + index}
                                        // variant="info"
                                        place="bottom"
                                        opacity={1}
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: 0,
                                        }}
                                    >
                                        <span className="p-3 border rounded-lg bg-white text-[#9299AA]">
                                            {player.roles}
                                        </span>
                                    </ReactTooltip>
                                    <span data-tooltip-id={"role" + index}>
                                        {GetRoleAbbr(player.roles)}
                                    </span>
                                </td>
                                <td className="py-4 text-center text-neutral-content">
                                    {player.season_minutes}
                                </td>
                                <td className="py-4 text-center text-neutral-content">
                                    {player.league_code}
                                </td>
                                <td className="py-4 text-center text-neutral-content">
                                    {player.expiration_date?.split("-")[0]}
                                </td>
                                <td className="py-4 text-center font-semibold">
                                    <div className={`w-10 2xl:w-[48px] h-[32px] flex justify-center items-center ${getColorForPot(player.pot_label)} rounded mx-auto`}>
                                        {player.pot_label}
                                    </div>

                                </td>
                                <td className="py-4 font-semibold flex items-center justify-center">
                                    <div className="w-[1px] h-[24px] bg-[#F2F3F6]"></div>
                                    <div className={`w-10 2xl:w-[48px] h-[32px] flex justify-center items-center ${getColorForPot(player.pot_label)} rounded mx-auto`}>
                                        {player.pot}
                                    </div>
                                </td>
                                {filteredabbr?.map((item, itx) => (
                                    <td
                                        className="font-semibold px-1"
                                        key={itx}
                                    >
                                        <div
                                            data-tooltip-id={
                                                "dynamic" + itx + "data" + index
                                            }
                                            className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded mx-auto"
                                        >
                                            <ReactTooltip
                                                id={
                                                    "dynamic" +
                                                    itx +
                                                    "data" +
                                                    index
                                                }
                                                variant="info"
                                                place="bottom"
                                                opacity={1}
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    padding: 0,
                                                }}
                                            >
                                                <span className="p-3 border rounded-lg bg-white text-[#9299AA]">
                                                    {getDynamicValue(player?.[item.apikey] > 0
                                                        ? "+" +
                                                          player?.[
                                                              item.apikey
                                                          ]?.toFixed(0)
                                                        : player?.[
                                                              item.apikey
                                                          ]?.toFixed(0) === "-0"
                                                        ? 0
                                                        : player?.[
                                                              item.apikey
                                                          ]?.toFixed(0))}
                                                </span>
                                            </ReactTooltip>
                                            <span>
                                                {player?.[item.apikey] > 0
                                                    ? "+" +
                                                      player?.[
                                                          item.apikey
                                                      ]?.toFixed(0)
                                                    : player?.[
                                                          item.apikey
                                                      ]?.toFixed(0) === "-0"
                                                    ? 0
                                                    : player?.[
                                                          item.apikey
                                                      ]?.toFixed(0)} 
                                            </span>
                                        </div>
                                    </td>
                                ))}
                                <td className="py-4 font-semibold flex items-center justify-center">
                                    <div className="w-[1px] h-[24px] bg-[#F2F3F6]"></div>
                                    <span className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center text-primary evaluations_count rounded mx-auto">
                                        {player.evaluations_count}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 mb-2 text-center">
                <div>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            className={`w-8 mx-1 h-8 text-neutral-content  border rounded-md ${
                                currentPage === index + 1 && "active-page"
                            } `}
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            disabled={currentPage === index + 1}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Table;
