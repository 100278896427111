import React, { useContext } from "react";
import "./Coach-shortlist.css";
import Goalkeeper from "./List/Goalkeeper/Goalkeeper";
import Midfielders from "./List/Midfielders/Midfielders";
import CentreBacks from "./List/CentreBacks/CentreBacks";
import CF from "./List/CF/CF";
import Fullbacks from "./List/Fullbacks/Fullbacks";
import OffensiveForwards from "./List/OffensiveForwards/OffensiveForwards";
import { DataContext } from "../../Context/DataProvider";

const CoachShortlist = () => {
  const {
    base_url,
    user,
    token,
    coachShortListData,
    setCoachShortListData,
    coachSlLoading,
  } = useContext(DataContext);

  if (coachSlLoading) {
    return (
      <div className="coach-shortlist pt-10 min-h-screen px-10 py-5 ">
        Please wait, data it is loading....
      </div>
    );
  }

  if (coachShortListData?.length === 0) {
    return (
      <div className="coach-shortlist font-[700] text-red-600 text-base pt-10 min-h-screen px-10 py-5 ">
        Sorry!! You have not added any coach yet.
      </div>
    );
  }

  return (
    <div className="coach-shortlist pt-10 min-h-screen px-10 py-5 ">
      <h2>
        Shortlists{" "}
        {coachShortListData?.length > 0 ? coachShortListData.length : 0}
      </h2>
      <div className="grid grid-cols-3 gap-5  mt-5">
        <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        />
        {/* <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        />
        <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        />
        <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        />
        <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        />
        <Goalkeeper
          players={coachShortListData}
          list={coachShortListData?.length > 0 ? true : false}
        /> */}
      </div>
    </div>
  );
};

export default CoachShortlist;
