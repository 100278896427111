import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../Context/DataProvider";
import { useNavigate } from "react-router-dom";

const CreateTeamModal = () => {
  const { teams, setTeams, user, base_url, token, formationData } = useContext(DataContext);
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const formations = [];
  formationData?.forEach((data) => {
    formations.push(data?.formation);
  });

  // useEffect(() => {
  //   setSelected(formations[0]);
  // }, [formationData]);

  const handleCreateTeam = () => {
    if (name === "" || selected === "") {
      alert("input all field");
    } else {
      const alreadyAdded = teams?.find(
        (team) => team?.name.toLowerCase() === name.trim().toLowerCase()
      );
      if (alreadyAdded) {
        alert("A team is already created by this name");
      } else {
        const obj = {
          formation: selected,
          name: name.trim().toLowerCase(),
        };
        // add team in db
        fetch(`${base_url}/api/v1/myteam`, {
          method: "POST",
          body: JSON.stringify({
            user_id: user?.id,
            name: obj.name,
            formation: obj.formation,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            if (json) {
              console.log(json);
            }
            
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
        //end add to db
        setTeams([...teams, obj]);
        navigate(`/team/${name.toLocaleLowerCase()}`);
      }
    }
  };
  return (
    <div>
      <input type="checkbox" id="create-team-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box create-team-modal p-0">
          <div className="create-team-modal-header p-5">
            <h3 className="font-bold text-lg">Create new team</h3>
          </div>
          <div className="p-5">
            <div>
              <div>
                <label htmlFor="">Team Name</label>
              </div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Enter your team name"
                className="input input-bordered w-full mt-3"
              />
            </div>
            <div className="mt-3">
              <div>
                <label htmlFor="">Formation</label>
              </div>
              <select
                onChange={(e) => setSelected(e.target.value)}
                className=" create-team-modal-select mt-3 select select-bordered w-full "
              >
                <option value="">Select Formation</option>
                {[...new Set(formations)]?.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-action modal-footer flex justify-between p-5 ">
            <label htmlFor="create-team-modal" className="cancel-btn">
              Cancel
            </label>
            {name !== "" &&
              selected !== "Select Formation" &&
              selected !== "" && (
                <label
                  onClick={handleCreateTeam}
                  htmlFor="create-team-modal"
                  className="confirm-btn"
                >
                  Confirm
                </label>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeamModal;
